import React from 'react'
import Team from '../components/About/teamComp'
import WelcomeText from '../components/About/welcomeText'
import ContactForm from '../components/About/contactForm'
import Contact from'../components/contact/Contact'

const Contact_us = () => {
  return (
    <div>
       <Contact/>
        {/* <ContactForm/> */}
    </div>
  )
}

export default Contact_us