import Password from 'antd/es/input/Password'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'




i18n
.use(LanguageDetector)
.use(initReactI18next).init({
    debug:true,
    lng:'en',
    resources:{
        en:{
            translation:{
                service_1: "Commercial Properties",
                service_1_1:"At Emitence Realty, we understand the dynamic nature of commercial real estate in Mumbai, India's financial capital. Our team of experts specializes in identifying prime commercial properties, including office spaces, retail outlets, and industrial units. Whether you're seeking a prestigious corporate headquarters or a strategic location for your retail venture, we've got you covered.",
                
                service_2: "Residential Properties",
                service_2_2:"Discover the perfect balance between luxury and comfort with our residential property offerings in Mumbai. From stylish apartments to spacious villas, we curate a diverse portfolio to cater to every lifestyle. Our deep understanding of the city's vibrant neighborhoods ensures that you find not just a home but a sanctuary amid the hustle and bustle of this metropolis As part of our future vision, we are actively exploring opportunities in the emerging smart city of Dholera, Gujarat.",

                service_3: "Property Investments",
                service_3_3:"Unlock the potential of real estate investments with Emitence Realty. Our team of experts provides comprehensive guidance on lucrative investment opportunities, both in Mumbai and the emerging smart city of Dholera. With our finger on the pulse of the market, we identify properties that offer promising returns, enabling you to build a robust investment portfolio. Trust our expertise to navigate the complexities of real estate investments and maximize your returns.",

                service_4: "Consultations",
                service_4_4:"At Emitence Realty, we believe in empowering our clients with knowledge and personalized solutions. Our consultancy services encompass a wide range of areas, including property valuations, market analyses, legal advisory, and strategic planning. Whether you're a seasoned investor or a first-time homebuyer, our team of seasoned professionals is dedicated to providing tailored guidance to help you make informed decisions and achieve your real estate goals.",
                
                for_more_info_click_here: "To know more about SOIL HEALTH ",
                click_here:"Click Here",
                greeting : 'Easiest Way to Find Your Dream Home',
                sub_greeting : 'Maximize Your Yield with Data-Driven Insights',
                sub_greeting_btn1 : 'Get Started',
                sub_greeting_btn2 : 'Learn more',
                features:'FEATURES',
                features_heading : "Choose your preferred location : Mumbai or Dholera?",
                features_heading_sub : "Make informed decisions for a successful property investment.",
                home : "Home",
                about:"About us",
                guide:"Guide",
                login:"Login",
                logout:"Logout",
                en:"English",
                hi:"Hindi",
                ta:"Tamil",
                pridiction:"Prediction",
                soil_analysis:"Home Loans",
                soil_analysis_guidance:"Find your home ownership now through our flexible and competitive home loan options, all thoughtfully structured to perfectly fit your unique needs",
                crop_recommendation:"Consultations",
                crop_recommendation_guidance:"At Emitence Realty. Our consultancy services encompass a wide range of areas, and strategic planning.",
                seasonal_crop_recommendation:"Real Estate Agent",
                seasonal_crop_recommendation_guidance:"Experience the expertise and dedication of our professional real estate agents, committed to guiding you through every step of your real estate journey and helping you achieve your property goals.",
                optimal_crops:"House for Sale",
                optimal_crops_guidance:"Explore outstanding houses for sale that align with your preferences and budget, presenting a golden opportunity to discover your dream home and make a wise investment for the future.",
                home_page_empower: "Transforming your real estate dreams into reality with dedication and precision.",
                home_page_empower_sub: "Our platform analyzes soil conditions to provide personalized crop suggestions, helping farmers make informed decisions for a successful harvest. With our expertise and technology, you can enhance productivity and profitability in your fields.",
                common_question:"Common Questions",
                comm_here_Are:"Here are some common questions about our platform",
                q1:"How does the website determine which crops to suggest",
                a1:"Our website uses advanced algorithms that analyze the soil condition and recommend the most suitable crops based on various factors",
                q2:"How accurate are the crop recommendations",
                a2:"Our recommendations are based on extensive research and data analysis, making them highly accurate and reliable",
                q3:"Is website free to use ?",
                a3:"Yes, our website is completely free to use and accessible to all farmers",
                welcome_back:"Welcome back",
                please_sign_in_to_continue:"Please sign in to continue",
                email:"Email",
                password:"Password",
                Dont_have_an_account:"Don't have an account?",
                signup:"Sign Up",
                Something_went_wrong:"Something went wrong",
                create_account:"Create Account",
                please_sign_in_to_continue:"Please sign in to continue",
                already_have_an_account:"Already have an account?",
                full_name:"Full Name",
                confirm_password:"Confirm Password",
                our_project_about:"Our project tackles climate change's impact on agriculture by creating a Crop Recommendation System. Using machine learning, we optimize crop selection based on weather and soil conditions, aiming to boost yields.",
                thrive:"Thriving For A Better Tomorrow",
                greeting_guide:"Welcome to the guide section",
                greeting_guide_sub:"Here you can find detailed information on how to use our platform and make the most of its features",
                weather_required:"Weather Required",
                soil_details:"Soil Details",
                crop_variety:"Crop Variety",
                land_preparation:"Land Preparation",
                seed_information:"Seed Information",
                planting_material:"Planting Material",
                sowing_info:"Sowing Information",
                planting_info:"Planting Information",
                fertilizer_usage:"Fertilizer Usage",
                irrigation_details:"Irrigation Details",
                pest_disease_management:"Pest & Disease Management",
                harvest_methods:"Harvest Methods",

                nitrogen:"Nitrogen (N)",
                nitrogen_des:"Nitrogen is a key nutrient required for plant growth and development. It plays a crucial role in photosynthesis, protein synthesis, and overall plant metabolism. Nitrogen deficiency can lead to stunted growth, yellowing of leaves, and reduced yield.",
                phosphorus:"Phosphorus (P)",
                phosphorus_des:"Phosphorus is essential for energy transfer, root development, and fruit formation in plants. It is a component of DNA, RNA, and ATP, playing a vital role in various metabolic processes. Phosphorus deficiency can result in poor root growth and delayed maturity.",
                potassium:"Potassium (K)",
                potassium_des:"Potassium is involved in regulating water uptake, enzyme activation, and photosynthesis in plants. It plays a key role in maintaining turgor pressure, nutrient transport, and overall plant health. Potassium deficiency can lead to wilting, reduced fruit quality, and increased susceptibility to diseases.",
                temperature:"Temperature in Celsius",
                temperature_des:"Temperature is a critical factor that influences plant growth and development. Each crop has an optimal temperature range for germination, vegetative growth, flowering, and fruit set. Extreme temperatures can stress plants, affecting their physiological processes and yield potential.",
                humidity:"Relative Humidity (%)",
                humidity_des:"Humidity refers to the amount of moisture present in the air. It affects plant transpiration, nutrient uptake, and overall growth. High humidity levels can promote fungal diseases, while low humidity can lead to water stress in plants.",
                soil_ph:"Soil pH",
                soil_ph_des:"Soil pH is a measure of the acidity or alkalinity of the soil. It influences nutrient availability, microbial activity, and plant growth. Most crops prefer a slightly acidic to neutral pH range for optimal nutrient uptake and root development.",
                rainfall:"Rainfall (mm)",
                rainfall_des:"Rainfall is a crucial environmental factor that affects crop growth and yield. Insufficient rainfall can lead to drought stress, affecting plant growth and productivity.",
                ques_parameter:"How to find these parameters for your farmland?",
                
                step1:"Step 1 : Get your soil health card ready.",
                sq1:"What is a soil health card ?",
                sa1:"A Soil Health Card is used to assess the current status of soil health and, when used over time, to determine changes in soil health that are affected by land management. A Soil Health Card displays soil health indicators and associated descriptive terms. The indicators are typically based on farmers' practical experience and knowledge of local natural resources. The card lists soil health indicators that can be assessed without the aid of technical or laboratory equipment.",
                sa1_1:"Soil Health Card (SHC) is a Government of India's scheme promoted by the Department of Agriculture & Co-operation under the Ministry of Agriculture and Farmers' Welfare. It is being implemented through the Department of Agriculture of all the State and Union Territory Governments.",
                sq2:"What soil heath card contains ?",
                sa2:"It will contain the status of his soil with respect to 12 parameters, namely N,P,K (Macro-nutrients); S (Secondary- nutrient); Zn, Fe, Cu, Mn, Bo (Micro - nutrients); and pH, EC, OC (Physical parameters). Based on this, our model pridicts the crop which need to be grown.",
                step2:"Step 2 : Collection of samples",
                step2_des:"Soil samples will be drawn in a grid of 2.5 ha in irrigated area",
                sq3:"Who will draw the soil sample ?",
                sa3:"The State Government will collect samples through the staff of their Department of Agriculture or through the staff of an outsourced agency. The State Government may also involve the students of local Agriculture / Science Colleges.",
                sq4:"What is the ideal time for soil sampling ?",
                sa4:"Soil Samples are taken generally two times in a year, after harvesting of Rabi and Kharif Crop respectively or when there is no standing crop in the field.",
                sq5:"How will soil samples be collected from a farmer's field?",
                sa5:"Soil Samples will be collected by a trained person from a depth of 15-20 cm by cutting the soil in a V shape. It will be collected from four corners and the centre of the field and mixed thoroughly and a part of this picked up as a sample. Areas with shade will be avoided. The sample chosen will be bagged and coded. It will then be transferred to soil test laboratory for analysis.",
                step3:"Step 3 : Sending sample to laboratory",
                s3_1:"The soil sample will be tested as per the approved standards for all the agreed 12 parameters in the following way:",
                s3_2:"At the Soil Testing Laboratory owned by the Department of Agriculture and by their own staff.",
                s3_3:"At ICAR Institutions including KVKs and SAUs.",
                s3_4:"At the laboratories of the Science Colleges/Universities by the students under supervision of a Professor/ Scientist.",
                s3_5:"At the Soil Testing Laboratory owned by the outsourced agency and by their staff.",
                sq6:"What is the cost of soil testing ?",
                sa6:" A sum of Rs. 190 per soil sample is provided to State Governments. This covers the cost of collection of soil sample, its test, generation and distribution of soil health card to the farmer.",
                logo:"CROPHECY",
                nitrogen_p:"Nitrogen - ratio of Nitrogen content in soil",
                phosphorus_p:"Phosphorus - ratio of Phosphorous content in soil",
                potassium_p:"Potassium - ratio of Potassium content in soil",
                temperature_p:"Temperature - temperature in degree Celsius",
                humidity_p:"Humidity - relative humidity in %",
                ph_p:"pH - ph value of the soil",
                rainfall_p:"Rainfall - rainfall in mm",
                predict:"Predict",
                Temperature:"Temperature",
                Rainfall:"Rainfall",
                STemp:"Sowing Temperature",
                HTemp:"Harvest Temperature",

                rice:"Rice",
                rice_wr:"Rice is a warm-season crop that thrives in tropical and subtropical regions. The ideal temperature range for rice cultivation is between 20°C and 35°C. Rice requires abundant sunshine during the growing season, with optimal sunlight exposure ranging from 6 to 8 hours per day.",
                rice_sd:"Rice cultivation requires well-drained, fertile soil with good water-holding capacity. The ideal soil pH for rice ranges from 6.0 to 7.0, with a slightly acidic soil being preferred. Rice can be grown in a variety of soil types, including clay loams, sandy loams, and alluvial soils.",
                rice_cv:"India is home to a diverse range of rice varieties, each with its unique characteristics and suitability for different regions and growing conditions. Some popular rice varieties cultivated in India include Basmati, Samba Masuri, IR-8, Swarna, Sharbati, and Jaya.",
                rice_lp:"Proper land preparation is crucial for successful rice cultivation. It involves activities such as plowing, puddling (creating a water-logged condition), leveling, and bund (raised embankment) construction. These steps ensure efficient water management, weed control, and proper seed germination.",
                rice_si:"Seed selection is a critical aspect of rice cultivation. Farmers should choose high-quality, certified seeds from reputable sources. Seed treatment with appropriate fungicides and insecticides can help protect the seeds from diseases and pests.",
                rice_soi:"Rice can be sown through various methods, including direct seeding (dry or wet seeding) or transplanting seedlings from nursery beds. The choice of sowing method depends on factors such as soil conditions, water availability, and labor availability.",
                rice_fu:"Rice is a nutrient-demanding crop, and proper fertilizer application is essential for optimal growth and yield. The primary nutrients required for rice cultivation include nitrogen, phosphorus, and potassium. Farmers should conduct soil testing and follow recommended fertilizer application rates based on soil analysis results.",
                rice_id:"Rice is a semi-aquatic crop that requires a consistent supply of water during its growing season. Efficient water management is crucial for rice cultivation. Techniques such as alternate wetting and drying (AWD) can help optimize water use while maintaining yields. Proper drainage systems should also be in place to prevent waterlogging and potential crop damage.",
                rice_pd:"Rice is susceptible to various pests and diseases, which can significantly impact yields if not managed effectively. Integrated Pest Management (IPM) strategies, including the judicious use of pesticides, biological control methods, and cultural practices, should be implemented to protect the crop.",
                rice_hm:"Timely harvesting is essential to ensure optimal grain quality and minimize losses. Rice can be harvested manually or using specialized harvesting machinery, depending on the scale of cultivation. Proper drying, threshing, and storage techniques are crucial to maintain the quality of the harvested grain.",
                rice_temp:"16-30° C",
                rice_rain:"100-200 cm",
                rice_stemp:"20-30° C",
                rice_htemp:"16-27° C",

                kindneybeans:"Kidney Beans",
                kidneybeans_wr:"Kidney beans are a warm-season crop that thrives in temperate to subtropical climates. The ideal temperature range for their cultivation is between 20°C and 30°C. They require a well-distributed rainfall of around 500-700 mm during the growing season, with dry conditions during the maturation and harvesting stages.",
                kidneybeans_sd:"Kidney beans can be grown in a variety of soil types, but they prefer well-drained, fertile soils with a slightly acidic to neutral pH range of 6.0 to 7.5. Loamy soils with good water-holding capacity and adequate drainage are ideal for their cultivation.",
                kidneybeans_cv:"India has a range of kidney bean varieties suitable for different regions and growing conditions. Some popular varieties include Rajma Chitra, Rajma Shvet, Rajma Kasturi, Rajma Pahadi, and Rajma Panchmukhi.",
                kidneybeans_lp:"Proper land preparation is essential for successful kidney bean cultivation. It involves deep plowing or tillage to break up the soil and incorporate organic matter. Raised beds or ridges can be prepared to improve drainage and aeration.",
                kidneybeans_si:"Selecting high-quality, disease-free seeds from reliable sources is crucial for a good kidney bean crop. Seed treatment with appropriate fungicides or insecticides can help protect the seeds from pests and diseases during germination and early growth stages.",
                kidneybeans_soi:"Kidney beans can be sown directly in the field or raised in nursery beds and then transplanted. The recommended sowing depth is 2-3 cm, with a spacing of 45-60 cm between rows and 10-15 cm between plants within a row.",
                kidneybeans_fu:"Kidney beans are leguminous crops that can fix atmospheric nitrogen through root nodules. However, they still require adequate levels of phosphorus, potassium, and other essential nutrients for optimal growth and yield. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                kidneybeans_id:"Kidney beans require regular irrigation, especially during the flowering and pod formation stages. Drip irrigation or furrow irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                kidneybeans_pd:"Kidney beans are susceptible to various pests and diseases, including bean aphids, pod borers, rust, and bacterial blights. Integrated Pest Management (IPM) strategies, such as crop rotation, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                kidneybeans_hm:"Kidney beans are typically harvested when the pods turn yellow or dry and the seeds rattle inside. Harvesting can be done manually or mechanically, depending on the scale of cultivation. Proper drying, threshing, and storage techniques are crucial to maintain the quality and longevity of the harvested beans.",
                kidneybeans_temp:"15-25° C",
                kidneybeans_rain:"60-150 mm",
                kidneybeans_stemp:"22-25° C",
                kidneybeans_htemp:"28-30° C",

                wheat:"Wheat",
                wheat_wr:"Wheat is a cool-season crop that thrives in temperate climates. The ideal temperature range for wheat cultivation is between 15°C and 25°C during the growing season. Wheat requires adequate sunlight exposure, with optimal sunlight ranging from 6 to 8 hours per day.",
                wheat_sd:"Wheat can be grown in a variety of soil types, including loams, clay loams, and well-drained sandy loams. The ideal soil pH for wheat ranges from 6.0 to 7.5, with a slightly alkaline soil being preferred. Well-drained soils with good water-holding capacity are ideal for wheat cultivation.",
                wheat_cv:"India has a diverse range of wheat varieties suitable for different regions and growing conditions. Some popular wheat varieties cultivated in India include HD 2967, PBW 343, DBW 17, HI 1544, and Lok 1.",
                wheat_lp:"Proper land preparation is crucial for successful wheat cultivation. It involves activities such as deep plowing, harrowing, and leveling. These steps ensure proper seed germination, root development, and weed control.",
                wheat_si:"Seed selection is a critical aspect of wheat cultivation. Farmers should choose high-quality, certified seeds from reputable sources. Seed treatment with appropriate fungicides and insecticides can help protect the seeds from diseases and pests.",
                wheat_soi:"Wheat can be sown through various methods, including broadcasting, drilling, or dibbling. The recommended sowing depth is 3-5 cm, with a spacing of 20-25 cm between rows. The ideal sowing time varies depending on the region and climate.",
                wheat_fu:"Wheat is a nutrient-demanding crop, and proper fertilizer application is essential for optimal growth and yield. The primary nutrients required for wheat cultivation include nitrogen, phosphorus, and potassium. Farmers should conduct soil testing and follow recommended fertilizer application rates based on soil analysis results.",
                wheat_id:"Wheat requires a consistent supply of water during its growing season, especially during the critical growth stages of tillering, heading, and grain filling. Efficient irrigation techniques, such as sprinkler or drip irrigation, can help optimize water use while maintaining yields.",
                wheat_pd:"Wheat is susceptible to various pests and diseases, such as aphids, rust, and powdery mildew, which can significantly impact yields if not managed effectively. Integrated Pest Management (IPM) strategies, including the judicious use of pesticides, biological control methods, and cultural practices, should be implemented to protect the crop.",
                wheat_hm:"Timely harvesting is essential to ensure optimal grain quality and minimize losses. Wheat can be harvested manually or using specialized harvesting machinery, depending on the scale of cultivation. Proper drying, threshing, and storage techniques are crucial to maintain the quality of the harvested grain.",
                wheat_temp:"14-18° C",
                wheat_rain:"75-100 mm",
                wheat_stemp:"22-25° C",
                wheat_htemp:"28-30° C",

                chickpeas:"Chickpeas",
                chickpeas_wr:"Chickpeas are a cool-season crop that thrives in semi-arid to subtropical climates. The ideal temperature range for their cultivation is between 15°C and 25°C. They require a cool, dry climate during the vegetative growth stage and relatively warm conditions during the reproductive and maturation stages.",
                chickpeas_sd:"Chickpeas can be grown in a variety of soil types, but they prefer well-drained, fertile soils with a slightly alkaline pH range of 7.0 to 8.5. Sandy loam or clay loam soils with good water-holding capacity and adequate drainage are ideal for their cultivation.",
                chickpeas_cv:"India has a range of chickpea varieties suitable for different regions and growing conditions. Some popular varieties include Desi (small, dark-colored seeds), Kabuli (large, light-colored seeds), JG 16, KPG 59, and Vihar.",
                chickpeas_lp:"Proper land preparation is essential for successful chickpea cultivation. It involves deep plowing or tillage to break up the soil and incorporate organic matter. Raised beds or ridges can be prepared to improve drainage and aeration.",
                chickpeas_si:"Selecting high-quality, disease-free seeds from reliable sources is crucial for a good chickpea crop. Seed treatment with appropriate fungicides or insecticides can help protect the seeds from pests and diseases during germination and early growth stages.",
                chickpeas_soi:"Chickpeas can be sown directly in the field or raised in nursery beds and then transplanted. The recommended sowing depth is 5-10 cm, with a spacing of 30-45 cm between rows and 10-15 cm between plants within a row.",
                chickpeas_fu:"Chickpeas are leguminous crops that can fix atmospheric nitrogen through root nodules. However, they still require adequate levels of phosphorus, potassium, and other essential nutrients for optimal growth and yield. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                chickpeas_id:"Chickpeas require moderate irrigation, especially during the flowering and pod formation stages. Drip irrigation or furrow irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                chickpeas_pd:"Chickpeas are susceptible to various pests and diseases, including pod borers, cutworms, wilt, and ascochyta blight. Integrated Pest Management (IPM) strategies, such as crop rotation, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                chickpeas_hm:"Chickpeas are typically harvested when the pods turn brown and dry, and the seeds rattle inside. Harvesting can be done manually or mechanically, depending on the scale of cultivation. Proper drying, threshing, and storage techniques are crucial to maintain the quality and longevity of the harvested chickpeas.",
                chickpeas_temp:"24°C - 30°C",
                chickpeas_rain:"60-90 cm",
                chickpeas_stemp:"24°C - 28°C",
                chickpeas_htemp:"30°C - 32°C",

                banana:"Banana",
                banana_wr:"Bananas are a tropical crop that thrives in warm and humid climates. The ideal temperature range for their cultivation is between 20°C and 35°C. They require an annual rainfall of around 1,000-2,000 mm, well-distributed throughout the year.",
                banana_sd:"Bananas can be grown in a variety of soil types, but they prefer well-drained, fertile soils with a pH range of 6.0 to 7.5. Sandy loam or clay loam soils with good water-holding capacity and adequate drainage are ideal for their cultivation. ",
                banana_cv:"India has a range of banana varieties suitable for different regions and growing conditions. Some popular varieties include Robusta, Grand Naine, Rasthali, Nendran, Ney Poovan, and Karpura Chakkarakeli.",
                banana_lp:"Proper land preparation is essential for successful banana cultivation. It involves deep plowing or tillage to break up the soil and incorporate organic matter. Raised beds or mounds can be prepared to improve drainage and aeration.",
                banana_si:"Bananas are propagated through suckers or tissue-cultured plantlets. Selecting high-quality, disease-free planting material from reliable sources is crucial for a good banana crop.",
                banana_soi:"Banana suckers or plantlets are typically planted in pits or trenches at a depth of 30-45 cm. The recommended spacing between plants is 1.5-2.5 meters in rows, with 2-3 meters between rows.",
                banana_fu:"Bananas are heavy feeders and require adequate nutrients for optimal growth and yield. The primary nutrients required for banana cultivation include nitrogen, phosphorus, potassium, and organic matter. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                banana_id:"Bananas require regular irrigation, especially during the dry season and fruit development stages. Drip irrigation or furrow irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                banana_pd:"Bananas are susceptible to various pests and diseases, including nematodes, banana weevils, sigatoka leaf spot, and Panama disease. Integrated Pest Management (IPM) strategies, such as crop rotation, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                banana_hm:"Bananas are typically harvested when the fingers (individual fruits) are well-developed, and the fruit bunch has reached the desired maturity stage. Harvesting is usually done manually by cutting the stem with a sharp knife or sickle. Proper handling and storage techniques are crucial to maintain the quality and shelf-life of the harvested bananas.",
                banana_temp:"15-35° C",
                banana_rain:"50-75 mm",
                banana_stemp:"22-25° C",
                banana_htemp:"28-30° C",

                blackgram:"Blackgram",
                blackgram_wr:"Blackgram is a warm-season crop that thrives in tropical and subtropical climates. The ideal temperature range for its cultivation is between 25°C and 35°C. It requires a warm and humid climate during the vegetative growth stage and relatively dry conditions during the maturation stage.",
                blackgram_sd:"Blackgram can be grown in a variety of soil types, but it prefers well-drained, fertile soils with a pH range of 6.0 to 7.5. Sandy loam or clay loam soils with good water-holding capacity and adequate drainage are ideal for its cultivation.",
                blackgram_cv:"India has a range of blackgram varieties suitable for different regions and growing conditions. Some popular varieties include T 9, Pant U 19, Pant U 31, Pant U 35, and Krishnanath.",
                blackgram_lp:"Proper land preparation is essential for successful blackgram cultivation. It involves deep plowing or tillage to break up the soil and incorporate organic matter. Raised beds or ridges can be prepared to improve drainage and aeration.",
                blackgram_si:"Selecting high-quality, disease-free seeds from reliable sources is crucial for a good blackgram crop. Seed treatment with appropriate fungicides or insecticides can help protect the seeds from pests and diseases during germination and early growth stages.",
                blackgram_soi:"Blackgram can be sown directly in the field or raised in nursery beds and then transplanted. The recommended sowing depth is 3-5 cm, with a spacing of 30-45 cm between rows and 10-15 cm between plants within a row.",
                blackgram_fu:"Blackgram is a leguminous crop that can fix atmospheric nitrogen through root nodules. However, it still requires adequate levels of phosphorus, potassium, and other essential nutrients for optimal growth and yield. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                blackgram_id:"Blackgram requires moderate irrigation, especially during the flowering and pod formation stages. Drip irrigation or furrow irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                blackgram_pd:"Blackgram is susceptible to various pests and diseases, including pod borers, aphids, yellow mosaic virus, and powdery mildew. Integrated Pest Management (IPM) strategies, such as crop rotation, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                blackgram_hm:"Blackgram is typically harvested when the pods turn brown and dry, and the seeds rattle inside. Harvesting can be done manually or mechanically, depending on the scale of cultivation. Proper drying, threshing, and storage techniques are crucial to maintain the quality and longevity of the harvested blackgram.",
                blackgram_temp:"15-25° C",
                blackgram_rain:"60-150 mm",
                blackgram_stemp:"22-25° C",
                blackgram_htemp:"28-30° C",

                coconut:"Coconut",
                coconut_wr:"Coconut is a tropical crop that thrives in warm and humid climates. The ideal temperature range for its cultivation is between 20°C and 35°C. It requires an annual rainfall of around 1,000-2,000 mm, well-distributed throughout the year.",
                coconut_sd:"Coconut can be grown in a variety of soil types, but it prefers well-drained, sandy loam or lateritic soils with a pH range of 5.5 to 8.0. Soils with good water-holding capacity and adequate drainage are ideal for coconut cultivation.",
                coconut_cv:"India has a range of coconut varieties suitable for different regions and growing conditions. Some popular varieties include Tali, West Coast Tall, East Coast Tall, Laccadive Micro, and Laccadive Ordinary.",
                coconut_lp:"Proper land preparation is essential for successful coconut cultivation. It involves deep plowing or digging pits of appropriate size, incorporating organic matter, and ensuring adequate drainage.",
                coconut_si:"Coconut is propagated through seeds or seedlings. Selecting high-quality, disease-free planting material from reliable sources is crucial for a good coconut crop.",
                coconut_soi:"Coconut seeds or seedlings are typically planted in pits or trenches at a depth of 45-60 cm. The recommended spacing between plants is 7-9 meters in a triangular or square pattern.",
                coconut_fu:"Coconut is a heavy feeder and requires adequate nutrients for optimal growth and yield. The primary nutrients required for coconut cultivation include nitrogen, phosphorus, potassium, and organic matter. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                coconut_id:"Coconut requires regular irrigation, especially during the dry season and fruit development stages. Drip irrigation or basin irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                coconut_pd:"Coconut is susceptible to various pests and diseases, including mites, rhinoceros beetles, bud rot, and wilt diseases. Integrated Pest Management (IPM) strategies, such as crop rotation, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                coconut_hm:"Coconuts are typically harvested when the fruits are fully mature, and the husk turns brown. Harvesting is usually done manually by climbing the trees or using specialized harvesting tools. Proper handling and storage techniques are crucial to maintain the quality and shelf-life of the harvested coconuts.",
                coconut_temp:"15-25° C",
                coconut_rain:"60-150 mm",
                coconut_stemp:"22-25° C",
                coconut_htemp:"28-30° C",

                apple:"Apple",
                apple_wr:"Apples are a temperate crop that requires a well-defined chilling period during the winter months. The ideal temperature range for apple cultivation is between 15°C and 25°C during the growing season. Sufficient winter chill (below 7°C) is crucial for proper dormancy and subsequent flowering and fruiting.",
                apple_sd:"Apples can be grown in a variety of soil types, but they thrive best in deep, well-drained, and fertile soils with a pH range of 5.5 to 6.5. Loamy or sandy loam soils with good water-holding capacity and adequate drainage are ideal for apple cultivation.",
                apple_cv:"India has a range of apple varieties suitable for different regions and growing conditions. Some popular varieties include Royal Delicious, Red Delicious, Golden Delicious, Ambri, Lal Ambri, and Starking Delicious.",
                apple_lp:"Proper land preparation is essential for successful apple cultivation. It involves deep plowing or digging pits of appropriate size, incorporating organic matter, and ensuring adequate drainage.",
                apple_si:"Apples are typically propagated through grafted saplings or rootstocks. Selecting high-quality, disease-free planting material from reliable sources is crucial for a good apple crop.",
                apple_soi:"Apple saplings are typically planted in pits or trenches at a depth of 30-45 cm. The recommended spacing between plants varies depending on the rootstock and training system, ranging from 3-6 meters between rows and 2-4 meters between plants within a row.",
                apple_fu:"Apples require adequate nutrients for optimal growth, flowering, and fruit production. The primary nutrients required for apple cultivation include nitrogen, phosphorus, potassium, and organic matter. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                apple_id:"Apples require regular irrigation, especially during the fruit development and growing stages. Drip irrigation or micro-sprinkler irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                apple_pd:"Apples are susceptible to various pests and diseases, including codling moth, apple scab, powdery mildew, and fire blight. Integrated Pest Management (IPM) strategies, such as pruning, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                apple_hm:"Apples are typically harvested when they reach the desired size, color, and maturity stage. Harvesting is usually done manually by carefully picking the fruits from the trees. Proper handling, grading, and storage techniques are crucial to maintain the quality and shelf-life of the harvested apples.",
                apple_temp:"15-25° C",
                apple_rain:"60-150 mm",
                apple_stemp:"22-25° C",
                apple_htemp:"28-30° C",

                coffee:"Coffee",
                coffee_wr:"Coffee is a tropical crop that thrives in warm and humid climates. The ideal temperature range for coffee cultivation is between 15°C and 28°C. It requires an annual rainfall of around 1,500-2,500 mm, well-distributed throughout the year.",
                coffee_sd:"Coffee can be grown in a variety of soil types, but it prefers well-drained, fertile soils with a pH range of 5.5 to 6.5. Loamy or sandy loam soils with good water-holding capacity and adequate drainage are ideal for coffee cultivation.",
                coffee_cv:"India has two main varieties of coffee suitable for different regions and growing conditions: Arabica and Robusta. Arabica varieties like Kent, S.795, and Cauvery are grown in the hills, while Robusta varieties like S.274 and CxR are grown in the plains.",
                coffee_lp:"Proper land preparation is essential for successful coffee cultivation. It involves deep plowing or digging pits of appropriate size, incorporating organic matter, and ensuring adequate drainage. Shade trees may also be planted to provide partial shade for the coffee plants.",
                coffee_si:"Coffee is propagated through seeds or seedlings. Selecting high-quality, disease-free planting material from reliable sources is crucial for a good coffee crop.",
                coffee_soi:"Coffee seeds or seedlings are typically planted in pits or trenches at a depth of 15-20 cm. The recommended spacing between plants varies depending on the variety, ranging from 1.5-2.5 meters between rows and 1-1.5 meters between plants within a row.",
                coffee_fu:"Coffee plants require adequate nutrients for optimal growth, flowering, and bean production. The primary nutrients required for coffee cultivation include nitrogen, phosphorus, potassium, and organic matter. Farmers should conduct soil testing and apply appropriate fertilizers based on the recommendations.",
                coffee_id:"Coffee requires regular irrigation, especially during the dry season and fruit development stages. Drip irrigation or micro-sprinkler irrigation methods can be employed to ensure efficient water use and minimize water wastage.",
                coffee_pd:"Coffee is susceptible to various pests and diseases, including coffee berry borer, coffee leaf rust, and root-knot nematodes. Integrated Pest Management (IPM) strategies, such as pruning, use of resistant varieties, biological control agents, and judicious use of pesticides, should be implemented to protect the crop.",
                coffee_hm:"Coffee beans are typically harvested when the berries turn a deep red color, indicating ripeness. Harvesting can be done manually by hand-picking the ripe berries or using mechanical harvesters for large-scale plantations. Proper drying, processing, and storage techniques are crucial to maintain the quality and flavor of the harvested coffee beans.",
                coffee_temp:"15-28° C",
                coffee_rain:"150-250 mm",
                coffee_stemp:"22-25° C",
                coffee_htemp:"28-30° C",

                cotton:"Cotton",
                cotton_wr:"Cotton is a warm-season crop that requires a long growing period with plenty of sunshine and warm temperatures. The ideal temperature range for cotton growth is between 20°C to 30°C (68°F to 86°F). Cotton is sensitive to frost and can be damaged or killed by freezing temperatures. It thrives in areas with low humidity and minimal rainfall during the fruiting and boll-opening stages.",
                cotton_sd:"Cotton grows best in well-drained, fertile soils with a pH range of 6.0 to 7.5. Sandy loam or loamy soils with good water-holding capacity are ideal for cotton cultivation. Soil compaction should be avoided as it can hinder root growth and water penetration.",
                cotton_cv:"Cotton varieties can be broadly classified into two main types: upland cotton (Gossypium hirsutum) and extra-long staple cotton (Gossypium barbadense). Some popular upland cotton varieties include Deltapine, Stoneville, and FiberMax, while Pima and Giza are popular extra-long staple varieties.",
                cotton_lp:"Proper land preparation is crucial for cotton cultivation. The field should be plowed deeply to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed. Beds or ridges may be formed to improve drainage and facilitate furrow irrigation.",
                cotton_si:"High-quality, disease-resistant, and certified cotton seeds should be used for sowing. The recommended seeding rate varies depending on the variety and planting method but typically ranges from 10 to 15 kg/hectare (9 to 13 lbs/acre).",
                cotton_soi:"Cotton is typically sown in late spring or early summer when the soil temperature reaches 18°C (64°F) or higher. Sowing can be done by broadcasting, drilling, or precision planting methods. The recommended row spacing is usually between 75 to 100 cm (30 to 40 inches), and the seed depth should be around 2.5 to 5 cm (1 to 2 inches).",
                cotton_fu:"Cotton is a heavy feeder and requires adequate fertilization for optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing. Split applications of fertilizers are often recommended.",
                cotton_id:"Cotton requires consistent moisture during the growing season, especially during the boll development stage. Furrow, sprinkler, or drip irrigation systems can be used for cotton irrigation. The frequency and amount of irrigation depend on factors like soil type, climate, and crop growth stage.",
                cotton_pd:"Cotton is susceptible to various pests and diseases, including bollworms, aphids, whiteflies, cotton leaf curl virus, and fusarium wilt. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                cotton_hm:"Cotton is typically harvested mechanically using cotton pickers or strippers. Cotton pickers remove the entire open boll from the plant, while strippers remove the lint from the burrs, leaving the burrs on the plant. Proper timing of harvest is crucial to ensure maximum yield and fiber quality.",
                cotton_temp:"21-30° C",
                cotton_rain:"50-100 mm",
                cotton_stemp:"22-25° C",
                cotton_htemp:"28-30° C",

                grapes:"Grapes",
                grapes_wr:"Grapes thrive in warm, dry climates with long, sunny days and cool nights during the growing season. The ideal temperature range for grape cultivation is between 20°C to 30°C (68°F to 86°F) during the day and 15°C to 20°C (59°F to 68°F) at night. Grapes require a minimum of 1,500 to 2,000 hours of sunshine during the growing season and an annual rainfall of 500 to 800 mm (20 to 31 inches).",
                grapes_sd:"Grapes can be grown in various soil types, but they prefer well-drained, deep, and fertile soils with a pH range of 5.5 to 7.0. Sandy loam, loam, or clay loam soils with good water-holding capacity are ideal for grape cultivation. Soils with poor drainage or high salinity should be avoided.",
                grapes_cv:"There are numerous grape varieties suitable for different wine styles, table grapes, and raisins. Some popular wine grape varieties include Cabernet Sauvignon, Merlot, Chardonnay, and Riesling. Table grape varieties like Thompson Seedless, Crimson Seedless, and Red Globe are widely cultivated for fresh consumption.",
                grapes_lp:"Land preparation for grape vineyards involves clearing the area of any existing vegetation, removing stumps and rocks, and leveling the land if necessary. Proper drainage systems, such as ditches or tile drains, may be installed to prevent waterlogging. Trellising or support systems should be set up to train the vines.",
                grapes_si:"Grapes can be propagated through cuttings or grafted onto rootstocks. Rootstocks are often chosen for their resistance to pests, diseases, or soil conditions. High-quality, certified planting material should be used to ensure disease-free and true-to-type vines.",
                grapes_soi:"Grapes can be propagated through cuttings or grafted onto rootstocks. Rootstocks are often chosen for their resistance to pests, diseases, or soil conditions. Planting is typically done in early spring, with spacing ranging from 1.5 to 3 meters (5 to 10 feet) between vines and 2 to 3.5 meters (6.5 to 11.5 feet) between rows, depending on the variety and training system.",
                grapes_fu:"Grape vines require balanced fertilization to maintain optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like zinc and boron. Soil testing should be conducted to determine the specific fertilizer requirements.",
                grapes_id:"Grapes require consistent moisture during the growing season, especially during fruit development and ripening stages. Drip irrigation or micro-sprinkler systems are commonly used for grape irrigation, as they provide precise water delivery and minimize water wastage.",
                grapes_pd:"Grape vines are susceptible to various pests and diseases, including powdery mildew, downy mildew, grape phylloxera, and grape berry moth. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                grapes_hm:"Grapes are typically harvested by hand or with the aid of mechanical harvesters, depending on the scale of production and the intended use of the grapes (wine, table grapes, or raisins). Proper timing of harvest is crucial to ensure optimal fruit quality and flavor.",
                grapes_temp:"15-25° C",
                grapes_rain:"60-150 mm",
                grapes_stemp:"22-25° C",
                grapes_htemp:"28-30° C",

                jute:"Jute",
                jute_wr:"Jute is a warm-season crop that thrives in hot and humid climatic conditions. The ideal temperature range for jute cultivation is between 24°C to 35°C (75°F to 95°F). Jute requires a well-distributed annual rainfall of 1,200 to 1,500 mm (47 to 59 inches) during the growing season.",
                jute_sd:"Jute grows best in well-drained, fertile, and loamy soils with a pH range of 5.5 to 7.5. The soil should be rich in organic matter and have good water-holding capacity. Heavy clay soils or sandy soils with poor fertility should be avoided.",
                jute_cv:"The two main varieties of jute cultivated are White Jute (Corchorus capsularis) and Tossa Jute (Corchorus olitorius). Some popular varieties of White Jute are JRC-212, JRC-321, and JRO-524, while popular Tossa Jute varieties include JRO-878, JRO-632, and JRO-8432.",
                jute_lp:"Proper land preparation is crucial for jute cultivation. The field should be plowed deeply to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed.",
                jute_si:"High-quality, certified, and disease-free jute seeds should be used for sowing. The recommended seeding rate for White Jute is 6-8 kg/ha (5.4-7.1 lbs/acre), and for Tossa Jute, it is 5-7 kg/ha (4.5-6.2 lbs/acre).",
                jute_soi:"Jute is typically sown in mid-March to mid-May, depending on the region. The seeds should be sown at a depth of 2-3 cm (0.8-1.2 inches) and spaced at 20-25 cm (8-10 inches) between rows and 5-7 cm (2-3 inches) between plants.",
                jute_fu:"Jute is a nutrient-demanding crop and requires balanced fertilization. Nitrogen, phosphorus, and potassium are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing. Split applications of fertilizers are often recommended.",
                jute_id:"Jute requires adequate moisture throughout the growing season, especially during the early stages of growth. Irrigation may be necessary in areas with insufficient or erratic rainfall. Furrow or basin irrigation methods are commonly used for jute cultivation.",
                jute_pd:"Jute is susceptible to various pests and diseases, including stem rot, anthracnose, and stem weevil. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                jute_hm:"Jute is typically harvested by cutting the plants close to the ground level when the plants are in full bloom and the leaves start dropping. The harvested plants are then bundled and retted (soaked in water) to separate the fiber from the woody stem.",
                jute_temp:"15-25° C",
                jute_rain:"60-150 mm",
                jute_stemp:"22-25° C",
                jute_htemp:"28-30° C",

                lentil:"Lentil",
                lentil_wr:"Lentils are a cool-season crop that thrives in moderate temperatures. The ideal temperature range for lentil cultivation is between 15°C to 25°C (59°F to 77°F). Lentils can tolerate frost during the vegetative stage but are sensitive to high temperatures and drought during the flowering and pod-filling stages.",
                lentil_sd:"Lentils grow best in well-drained, fertile soils with a pH range of 6.0 to 8.0. Loamy or sandy loam soils with good water-holding capacity are preferred for lentil cultivation. Lentils are sensitive to saline and waterlogged conditions.",
                lentil_cv:"Some popular lentil varieties include Masoor (Red Lentil), Malka (Brown Lentil), Morad (Green Lentil), and Pusa Vaibhav (Large-seeded variety). The choice of variety depends on factors such as climate, maturity period, and intended use (whole or split lentils).",
                lentil_lp:"Land preparation for lentil cultivation involves deep plowing to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed.",
                lentil_si:"High-quality, certified, and disease-free lentil seeds should be used for sowing. The recommended seeding rate varies between 30-50 kg/ha (27-45 lbs/acre), depending on the variety and planting method.",
                lentil_soi:"Lentils can be sown in late autumn or early spring, depending on the climate. The seeds should be planted at a depth of 2-4 cm (0.8-1.6 inches) and spaced at 20-30 cm (8-12 inches) between rows.",
                lentil_fu:"Lentils have a moderate nutrient requirement. Nitrogen, phosphorus, and potassium are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                lentil_id:"Lentils require moderate moisture during the growing season, especially during the flowering and pod-filling stages. Irrigation may be necessary in areas with insufficient or erratic rainfall. Furrow or sprinkler irrigation methods can be used for lentil cultivation.",
                lentil_pd:"Lentils are susceptible to various pests and diseases, including aphids, pod borers, rust, and wilt. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                lentil_hm:"Lentils are typically harvested when the pods turn brown and the leaves start to dry. The plants are either cut manually or mechanically using a combine harvester. Proper drying and threshing of the harvested crop are crucial to maintain the quality of the lentils.",
                lentil_temp:"18-20°C",
                lentil_rain:"100cm",
                lentil_stemp:"18-20°C",
                lentil_htemp:"22-24°C",

                maize:"Maize",
                maize_wr:"Maize is a warm-season crop that requires warm temperatures and adequate moisture throughout the growing season. The ideal temperature range for maize cultivation is between 20°C to 30°C (68°F to 86°F). Maize requires an annual rainfall of 500 to 800 mm (20 to 31 inches), well-distributed during the growing season.",
                maize_sd:"Maize can be grown in a variety of soil types, but it prefers well-drained, fertile soils with a pH range of 6.0 to 7.5. Loamy or clay loam soils with good water-holding capacity are ideal for maize cultivation. Soils with poor drainage or high salinity should be avoided.",
                maize_cv:"There are numerous maize varieties available, including hybrid and open-pollinated varieties. Some popular hybrid maize varieties include Dekalb, Pioneer, and Monsanto, while open-pollinated varieties like Hickory King and Reid's Yellow Dent are also widely cultivated.",
                maize_lp:"Land preparation for maize involves deep plowing to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed. Raised beds or ridges may be formed to improve drainage and facilitate furrow irrigation.",
                maize_si:"High-quality, certified, and disease-resistant maize seeds should be used for sowing. The recommended seeding rate varies depending on the variety and planting method but typically ranges from 15 to 25 kg/ha (13 to 22 lbs/acre).",
                maize_soi:"Maize is typically sown in late spring or early summer when the soil temperature reaches 15°C (59°F) or higher. Sowing can be done by broadcasting, drilling, or precision planting methods. The recommended row spacing is usually between 60 to 90 cm (24 to 36 inches), and the seed depth should be around 5 cm (2 inches).",
                maize_fu:"Maize is a heavy feeder and requires adequate fertilization for optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing. Split applications of fertilizers are often recommended.",
                maize_id:"Maize requires consistent moisture throughout the growing season, especially during the silking and grain-filling stages. Furrow, sprinkler, or drip irrigation systems can be used for maize irrigation. The frequency and amount of irrigation depend on factors like soil type, climate, and crop growth stage.",
                maize_pd:"Maize is susceptible to various pests and diseases, including stem borers, armyworms, leaf blight, and ear rots. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                maize_hm:"Maize is typically harvested mechanically using a combine harvester when the kernels are fully mature and dry. Proper timing of harvest is crucial to ensure maximum yield and quality. In some regions, maize may be harvested manually for fresh consumption or fodder.",
                maize_temp:"25°C - 30°C",
                maize_rain:"50-100cm",
                maize_stemp:"25°C - 30°C",
                maize_htemp:"30-35°C",

                mango:"Mango",
                mango_wr:"Mangoes thrive in tropical and subtropical climates with warm temperatures throughout the year. The ideal temperature range for mango cultivation is between 20°C to 35°C (68°F to 95°F). Mangoes require a dry period of 3-4 months for proper flowering and fruit development.",
                mango_sd:"Mangoes can be grown in a variety of soil types, but they prefer well-drained, deep, and fertile soils with a pH range of 5.5 to 7.5. Sandy loam or loamy soils with good organic matter content are ideal for mango cultivation. Soils with poor drainage or high salinity should be avoided.",
                mango_cv:"Some popular mango varieties include Alphonso, Kesar, Dasheri, Langra, and Himsagar. The choice of variety depends on factors such as climate, soil type, and intended use (fresh consumption or processing).",
                mango_lp:"Land preparation for mango orchards involves clearing the area of any existing vegetation, removing stumps and rocks, and leveling the land if necessary. Proper drainage systems, such as ditches or tile drains, may be installed to prevent waterlogging.",
                mango_si:"Mangoes can be propagated through seeds (polyembryonic varieties) or vegetative methods like grafting or air-layering. High-quality, disease-free, and true-to-type planting material should be used.",
                mango_soi:"Planting is typically done in the rainy season or early spring. The spacing between mango trees varies depending on the variety and soil type but is typically between 8 to 12 meters (26 to 39 feet) in square or rectangular patterns.",
                mango_fu:"Mango trees require balanced fertilization to maintain optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like zinc and boron. Soil testing should be conducted to determine the specific fertilizer requirements.",
                mango_id:"Mango trees require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Drip irrigation or micro-sprinkler systems are commonly used for mango orchards, as they provide precise water delivery and minimize water wastage.",
                mango_pd:"Mango trees are susceptible to various pests and diseases, including mango hopper, mango mealy bug, powdery mildew, and anthracnose. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                mango_hm:"Mangoes are typically harvested by hand, using specialized harvesting tools or by shaking the tree branches. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor. Harvested mangoes are often treated with hot water or fungicides to extend their shelf life and prevent post-harvest diseases.",
                mango_temp:"22°C - 27°C",
                mango_rain:"50-80 mm",
                mango_stemp:"20°C - 22°C",
                mango_htemp:"28°C - 30°C",

                mongbean:"Moong Bean",
                moongbeans_wr:"Moong beans are a warm-season crop that thrives in hot and humid climatic conditions. The ideal temperature range for moong bean cultivation is between 25°C to 35°C (77°F to 95°F). Moong beans require an annual rainfall of 600 to 800 mm (24 to 31 inches), well-distributed during the growing season.",
                moongbeans_sd:"Moong beans can be grown in a variety of soil types, but they prefer well-drained, fertile soils with a pH range of 6.0 to 7.5. Sandy loam or loamy soils with good water-holding capacity are ideal for moong bean cultivation. Soils with poor drainage or high salinity should be avoided.",
                moongbeans_cv:"Some popular moong bean varieties include Pusa Vishal, Pusa Ratna, Pant Moong 2, and PDM 139. The choice of variety depends on factors such as climate, maturity period, and disease resistance.",
                moongbeans_lp:"Land preparation for moong bean cultivation involves deep plowing or cultivating to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed.",
                moongbeans_si:"High-quality, certified, and disease-free moong bean seeds should be used for sowing. The recommended seeding rate varies between 15 to 20 kg/ha (13 to 18 lbs/acre), depending on the variety and planting method.",
                moongbeans_soi:"Moong beans can be sown in late spring or early summer when the soil temperature reaches 20°C (68°F) or higher. The seeds should be planted at a depth of 3-5 cm (1.2-2 inches) and spaced at 30-45 cm (12-18 inches) between rows.",
                moongbeans_fu:"Moong beans have a moderate nutrient requirement. Nitrogen, phosphorus, and potassium are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                moongbeans_id:"Moong beans require moderate moisture during the growing season, especially during the flowering and pod-filling stages. Irrigation may be necessary in areas with insufficient or erratic rainfall. Furrow or sprinkler irrigation methods can be used for moong bean cultivation.",
                moongbeans_pd:"Moong beans are susceptible to various pests and diseases, including aphids, pod borers, yellow mosaic virus, and powdery mildew. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                moongbeans_hm:"Moong beans are typically harvested when the pods turn brown and the leaves start to dry. The plants are either cut manually or mechanically using a combine harvester. Proper drying and threshing of the harvested crop are crucial to maintain the quality of the moong beans.",
                moongbeans_temp:"25°C - 35°C",
                moongbeans_rain:"60-90 cm",
                moongbeans_stemp:"25°C - 30°C ",
                moongbeans_htemp:"30°C - 35°C",

                mothbeans:"Moth Bean",
                mothbeans_wr:"Mothbeans are a drought-tolerant crop that can be grown in arid and semi-arid regions. They thrive in hot and dry climatic conditions with temperatures ranging from 25°C to 40°C (77°F to 104°F). Mothbeans require an annual rainfall of 300 to 500 mm (12 to 20 inches).",
                mothbeans_sd:"Mothbeans can be grown in a wide range of soil types, including sandy, loamy, and even moderately saline soils. They prefer well-drained soils with a pH range of 7.0 to 8.5. Mothbeans can tolerate low soil fertility but perform better in soils with good organic matter content.",
                mothbeans_cv:"Some popular mothbean varieties include RMO 40, RMO 225, RMO 257, and RMO 435. These varieties are known for their drought tolerance, resistance to insect pests and diseases, and high yield potential.",
                mothbeans_lp:"Proper land preparation is essential for mothbean cultivation. The field should be plowed deeply to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed.",
                mothbeans_si:"High-quality, certified, and disease-free mothbean seeds should be used for sowing. The recommended seeding rate is around 15 to 20 kg/ha (13 to 18 lbs/acre), depending on the variety and planting method.",
                mothbeans_soi:"Mothbeans are typically sown in late spring or early summer when the soil temperature reaches 25°C (77°F) or higher. The seeds should be planted at a depth of 3-5 cm (1.2-2 inches) and spaced at 30-45 cm (12-18 inches) between rows.",
                mothbeans_fu:"Mothbeans have a moderate nutrient requirement. Nitrogen and phosphorus are the primary nutrients required. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                mothbeans_id:"Mothbeans are drought-tolerant and can be grown under rainfed conditions in areas with limited rainfall. However, supplemental irrigation may be necessary during prolonged dry spells, especially during the flowering and pod-filling stages.",
                mothbeans_pd:"Mothbeans are relatively resistant to pests and diseases compared to other legume crops. However, they can be affected by pests such as aphids, pod borers, and diseases like powdery mildew and rust. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop.",
                mothbeans_hm:"Mothbeans are typically harvested when the pods turn brown and the leaves start to dry. The plants are either cut manually or mechanically using a combine harvester. Proper drying and threshing of the harvested crop are crucial to maintain the quality of the mothbeans.",
                mothbeans_temp:"25-37° C",
                mothbeans_rain:"250-500 mm",
                mothbeans_stemp:"22-25° C",
                mothbeans_htemp:"28-30° C",

                muskmelon:"Muskmelon",
                muskmelon_wr:"Muskmelons are typically harvested when the pods turn brown and the leaves start to dry. The plants are either cut manually or mechanically using a combine harvester. Proper drying and threshing of the harvested crop are crucial to maintain the quality of the muskmelons.",
                muskmelon_sd:"Muskmelons grow best in well-drained, fertile soils with a pH range of 6.0 to 7.5. Sandy loam or loamy soils with good organic matter content are ideal for muskmelon cultivation. Soils with poor drainage or high salinity should be avoided.",
                muskmelon_cv:"Some popular muskmelon varieties include Cantalupe, Hami Melon, Galia, Charentais, and Casaba. The choice of variety depends on factors such as climate, disease resistance, and intended use (fresh consumption or processing).",
                muskmelon_lp:"Land preparation for muskmelon cultivation involves deep plowing or cultivating to break up any hardpan and ensure good soil aeration. Raised beds or ridges may be formed to improve drainage and facilitate furrow irrigation.",
                muskmelon_si:"High-quality, certified, and disease-free muskmelon seeds should be used for sowing. The recommended seeding rate varies between 1.5 to 2.5 kg/ha (1.3 to 2.2 lbs/acre), depending on the variety and planting method.",
                muskmelon_soi:"Muskmelons are typically sown in late spring or early summer when the soil temperature reaches 20°C (68°F) or higher. The seeds should be planted at a depth of 2-3 cm (0.8-1.2 inches) and spaced at 60-90 cm (24-36 inches) between rows and 30-45 cm (12-18 inches) between plants.",
                muskmelon_fu:"Muskmelons have a moderate nutrient requirement. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like boron and zinc. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                muskmelon_id:"Muskmelons require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Drip irrigation or furrow irrigation methods are commonly used for muskmelon cultivation, as they provide precise water delivery and minimize water wastage.",
                muskmelon_pd:"Muskmelons are susceptible to various pests and diseases, including aphids, spider mites, powdery mildew, and downy mildew. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                muskmelon_hm:"Muskmelons are typically harvested by hand when the fruit slips easily from the vine and the stem detaches cleanly from the fruit. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor. Harvested muskmelons should be handled carefully to avoid bruising and damage.",
                muskmelon_temp:"15-25° C",
                muskmelon_rain:"60-150 mm",
                muskmelon_stemp:"22-25° C",
                muskmelon_htemp:"28-30° C",

                orange:"Orange",
                orange_wr:"Oranges thrive in subtropical to tropical climates with warm temperatures and plenty of sunshine throughout the year. The ideal temperature range for orange cultivation is between 20°C to 30°C (68°F to 86°F). Oranges require a mild winter with temperatures above freezing and a warm, dry spring and summer for optimal fruit development.",
                orange_sd:"Oranges can be grown in a variety of soil types, but they prefer well-drained, deep, and fertile soils with a pH range of 6.0 to 7.5. Sandy loam or loamy soils with good organic matter content are ideal for orange cultivation. Soils with poor drainage or high salinity should be avoided.",
                orange_cv:"Some popular orange varieties include Valencia, Navel, Hamlin, Pera, and Jaffa. The choice of variety depends on factors such as climate, disease resistance, and intended use (fresh consumption or processing).",
                orange_lp:"Land preparation for orange orchards involves clearing the area of any existing vegetation, removing stumps and rocks, and leveling the land if necessary. Proper drainage systems, such as ditches or tile drains, may be installed to prevent waterlogging.",
                orange_si:"Oranges are typically propagated through grafting or budding onto rootstocks. The rootstock is chosen for its resistance to pests, diseases, or soil conditions. High-quality, certified, and disease-free planting material should be used.",
                orange_soi:"Planting is typically done in early spring or after the last frost. The spacing between orange trees varies depending on the variety and rootstock but is typically between 6 to 8 meters (20 to 26 feet) in square or rectangular patterns.",
                orange_fu:"Orange trees require balanced fertilization to maintain optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like zinc and boron. Soil testing should be conducted to determine the specific fertilizer requirements.",
                orange_id:"Orange trees require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Drip irrigation or micro-sprinkler systems are commonly used for orange orchards, as they provide precise water delivery and minimize water wastage.",
                orange_pd:"Orange trees are susceptible to various pests and diseases, including citrus leafminer, citrus tristeza virus, citrus greening disease, and citrus canker. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                orange_hm:"Oranges are typically harvested by hand, using specialized harvesting tools or by shaking the tree branches. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor. Harvested oranges are often treated with fungicides or waxes to extend their shelf life and enhance appearance.",
                orange_temp:"10-30°C",
                orange_rain:"500-600mm",
                orange_stemp:"10-25°C",
                orange_htemp:"30-34°C",
                
                papaya:"Papaya",
                papaya_wr:"Papaya is a tropical fruit crop that requires warm temperatures and high humidity throughout the year. The ideal temperature range for papaya cultivation is between 20°C to 35°C (68°F to 95°F). Papaya plants are sensitive to frost and cannot tolerate temperatures below 10°C (50°F).",
                papaya_sd:"Papaya can be grown in a variety of soil types, but it prefers well-drained, fertile soils with a pH range of 5.5 to 6.5. Sandy loam or loamy soils with good organic matter content are ideal for papaya cultivation. Papaya plants do not tolerate waterlogged or saline soils.",
                papaya_cv:"Some popular papaya varieties include Hawaiian, Coorg Honey Dew, Red Lady, Pusa Delicious, and Pusa Nanha. The choice of variety depends on factors such as climate, disease resistance, fruit size, and intended use (fresh consumption or processing).",
                papaya_lp:"Land preparation for papaya cultivation involves clearing the area of any existing vegetation, removing stumps and rocks, and leveling the land if necessary. Proper drainage systems, such as ditches or raised beds, may be installed to prevent waterlogging.",
                papaya_si:"Papaya can be propagated through seeds or vegetative methods like grafting. High-quality, certified, and disease-free seeds should be used for sowing. The recommended seeding rate varies depending on the propagation method and planting density.",
                papaya_soi:"Papaya seeds are typically sown in nursery beds or containers, and the seedlings are transplanted to the main field after 2-3 months. The spacing between papaya plants varies depending on the variety but is typically between 2 to 3 meters (6.5 to 10 feet) in square or rectangular patterns.",
                papaya_fu:"Papaya plants require balanced fertilization to maintain optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like zinc and boron. Soil testing should be conducted to determine the specific fertilizer requirements.",
                papaya_id:"Papaya plants require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Drip irrigation or micro-sprinkler systems are commonly used for papaya cultivation, as they provide precise water delivery and minimize water wastage.",
                papaya_pd:"Papaya plants are susceptible to various pests and diseases, including papaya ringspot virus, papaya mealybug, papaya whitefly, and anthracnose. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                papaya_hm:"Papaya fruits are typically harvested by hand when they are fully mature but still firm. The fruit should be harvested with a short stem attached to prolong its shelf life. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor.",
                papaya_temp:"15-25° C",
                papaya_rain:"60-150 mm",
                papaya_stemp:"22-25° C",
                papaya_htemp:"28-30° C",

                pigeonpeas:"Pigeonpeas",
                pigeonpeas_wr:"Pigeonpeas are a drought-tolerant crop that can be grown in both tropical and subtropical regions. The ideal temperature range for pigeonpea cultivation is between 20°C to 35°C (68°F to 95°F). Pigeonpeas require a warm, humid climate with an annual rainfall of 600 to 1000 mm (24 to 39 inches), well-distributed during the growing season.",
                pigeonpeas_sd:"Pigeonpeas can be grown in a variety of soil types, including sandy, loamy, and even moderately saline soils. They prefer well-drained soils with a pH range of 5.0 to 7.5. Pigeonpeas can tolerate low soil fertility but perform better in soils with good organic matter content.",
                pigeonpeas_cv:"Some popular pigeonpea varieties include Asha, Maruti, Sharad, UPAS 120, and GT 101. The choice of variety depends on factors such as maturity duration, disease resistance, and intended use (fresh consumption, dhal, or fodder).",
                pigeonpeas_lp:"Land preparation for pigeonpea cultivation involves deep plowing or cultivating to break up any hardpan and ensure good soil aeration. Disking and harrowing should be done to create a smooth seedbed.",
                pigeonpeas_si:"High-quality, certified, and disease-free pigeonpea seeds should be used for sowing. The recommended seeding rate varies between 10 to 15 kg/ha (9 to 13 lbs/acre), depending on the variety and planting method.",
                pigeonpeas_soi:"Pigeonpeas are typically sown in late spring or early summer when the soil temperature reaches 25°C (77°F) or higher. The seeds should be planted at a depth of 5-8 cm (2-3 inches) and spaced at 60-90 cm (24-36 inches) between rows and 20-30 cm (8-12 inches) between plants.",
                pigeonpeas_fu:"Pigeonpeas have a moderate nutrient requirement. Phosphorus and potassium are the primary nutrients required, along with a small amount of nitrogen. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                pigeonpeas_id:"Pigeonpeas are drought-tolerant and can be grown under rainfed conditions in areas with moderate rainfall. However, supplemental irrigation may be necessary during prolonged dry spells, especially during the flowering and pod-filling stages.",
                pigeonpeas_pd:"Pigeonpeas are susceptible to various pests and diseases, including pod borers, pod fly, wilt, and sterility mosaic disease. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                pigeonpeas_hm:"Pigeonpeas are typically harvested by hand when the pods turn brown and dry. The plants may be cut at the base and allowed to dry further before threshing. Proper drying and threshing of the harvested crop are crucial to maintain the quality of the pigeonpeas.",
                pigeonpeas_temp:"25°C - 30°C",
                pigeonpeas_rain:"600-650 mm",
                pigeonpeas_stemp:"25°-33°C ",
                pigeonpeas_htemp:"35°C - 40°C",

                pomegranate:"Pomegranate",
                pomegranate_wr:"Pomegranates thrive in semi-arid to subtropical climates with hot, dry summers and mild winters. The ideal temperature range for pomegranate cultivation is between 20°C to 35°C (68°F to 95°F) during the growing season. Pomegranates require a chilling period of 100 to 200 hours below 7°C (45°F) during winter for proper flowering and fruit set.",
                pomegranate_sd:"Pomegranates can be grown in a variety of soil types, but they prefer well-drained, slightly acidic to neutral soils with a pH range of 5.5 to 7.5. Sandy loam or loamy soils with good organic matter content are ideal for pomegranate cultivation. Pomegranates are moderately tolerant to saline soils.",
                pomegranate_cv:"Some popular pomegranate varieties include Wonderful, Bhagwa, Mridula, Ganesh, and Ruby. The choice of variety depends on factors such as climate, fruit size, color, and intended use (fresh consumption or processing).",
                pomegranate_lp:"Land preparation for pomegranate orchards involves clearing the area of any existing vegetation, removing stumps and rocks, and leveling the land if necessary. Proper drainage systems, such as ditches or raised beds, may be installed to prevent waterlogging.",
                pomegranate_si:"Pomegranates are typically propagated through cuttings or grafting onto rootstocks. High-quality, certified, and disease-free planting material should be used to ensure true-to-type plants and disease-free stock.",
                pomegranate_soi:"Planting is typically done in early spring or after the last frost. The spacing between pomegranate trees varies depending on the variety and rootstock but is typically between 4 to 6 meters (13 to 20 feet) in square or rectangular patterns.",
                pomegranate_fu:"Pomegranate trees require balanced fertilization to maintain optimal growth and yield. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like zinc and boron. Soil testing should be conducted to determine the specific fertilizer requirements.",
                pomegranate_id:"Pomegranate trees require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Drip irrigation or micro-sprinkler systems are commonly used for pomegranate orchards, as they provide precise water delivery and minimize water wastage.",
                pomegranate_pd:"Pomegranate trees are susceptible to various pests and diseases, including fruit borers, leaf and fruit spot diseases, wilt, and bacterial blight. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                pomegranate_hm:"Pomegranates are typically harvested by hand, using specialized harvesting tools or by shaking the tree branches. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor. Harvested pomegranates should be handled carefully to avoid bruising and damage.",
                pomegranate_temp:"15-25° C",
                pomegranate_rain:"60-150 mm",
                pomegranate_stemp:"22-25° C",
                pomegranate_htemp:"28-30° C",

                watermelon:"Watermelon",
                watermelon_wr:"Watermelons are warm-season crops that require warm temperatures and plenty of sunshine during the growing season. The ideal temperature range for watermelon cultivation is between 25°C to 35°C (77°F to 95°F). Watermelons require a long, warm growing season of at least 90 to 120 days.",
                watermelon_sd:"Watermelons grow best in well-drained, fertile soils with a pH range of 6.0 to 7.0. Sandy loam or loamy soils with good organic matter content are ideal for watermelon cultivation. Watermelons do not tolerate heavy clay or saline soils.",
                watermelon_cv:"Some popular watermelon varieties include Crimson Sweet, Sugar Baby, Charleston Gray, Sangria, and Yellow Doll. The choice of variety depends on factors such as climate, disease resistance, fruit size, and intended use (fresh consumption or processing).",
                watermelon_lp:"Land preparation for watermelon cultivation involves deep plowing or cultivating to break up any hardpan and ensure good soil aeration. Raised beds or ridges may be formed to improve drainage and facilitate furrow irrigation.",
                watermelon_si:"High-quality, certified, and disease-free watermelon seeds should be used for sowing. The recommended seeding rate varies between 1.5 to 2.5 kg/ha (1.3 to 2.2 lbs/acre), depending on the variety and planting method.",
                watermelon_soi:"Watermelons are typically sown in late spring or early summer when the soil temperature reaches 20°C (68°F) or higher. The seeds should be planted at a depth of 2-3 cm (0.8-1.2 inches) and spaced at 1.5-2 meters (5-6.5 feet) between rows and 60-90 cm (24-36 inches) between hills or plants.",
                watermelon_fu:"Watermelons have a moderate nutrient requirement. Nitrogen, phosphorus, and potassium are the primary nutrients required, along with micronutrients like boron and zinc. The specific fertilizer requirements depend on soil fertility levels and should be determined through soil testing.",
                watermelon_id:"Watermelons require consistent moisture throughout the growing season, especially during the flowering and fruit development stages. Furrow or drip irrigation methods are commonly used for watermelon cultivation, as they provide precise water delivery and minimize water wastage.",
                watermelon_pd:"Watermelons are susceptible to various pests and diseases, including aphids, cucumber beetles, fusarium wilt, and anthracnose. Integrated pest management strategies, involving cultural, biological, and chemical control methods, should be implemented to protect the crop and ensure sustainable production.",
                watermelon_hm:"Watermelons are typically harvested by hand when the fruit's underside (belly) turns yellowish-white and the tendril closest to the fruit stem turns brown and dry. Proper timing of harvest is crucial to ensure optimal fruit quality and flavor. Harvested watermelons should be handled carefully to avoid bruising and damage.",
                watermelon_temp:"20-30°C",
                watermelon_rain:"50-75mm",
                watermelon_stemp:"25-30°C",
                watermelon_htemp:"20-25°C",

                crop_info:"Crops",

                welcome_crops_guide:"Welcome to the Crops Guide",
                description_crops_guide:"Select a crop to view detailed information about its cultivation, management, and best practices.",
           
rd : "🌾 The humble grain that feeds nations. Lush green paddies give way to golden treasures of nutrition.",
md : "🌽 The golden crop. Tall green stalks bear iconic cobs brimming with nutritious, sweet kernels.",
wd : "🌾 An ancient staff of life. Fields of waving amber lead to loaves that grace tables around the globe.",
kbd : "🫘 Vigorous vines yielding nature's rubies. Each pod shelters a trove of protein-packed gems.",
mmd : "🍈 Fragrant orbs of ambrosial bliss. Netted rinds embrace the luscious, honey-sweet heart within.",
jd : "🌿 A trove of versatile fibers. Supple leaves spin into sturdy cloth and furnishings.",
cofd : "☕ The blossoming brew of wakefulness. These tiny berries yield an ambrosial elixir that keeps the world buzzing.",
cpd : "🌰 The humble earth nuggets. These golden seeds hoard nourishment that has satiated hunger for centuries.",
bad : "🍌 Nature's sweet gift. Peeling back reveals nutritious yellow bounty that delights taste buds of all ages.",
bgd : "🌱 Humble seeds pack a mighty punch. Small black pearls brimming with protein and vital nutrients.",
cod : "🥥 The island's magical offering. Every part - fruit, fronds, soil - is a gift from this generous tree.",
ad : "🍎 The proud orchard's treasure. A rosy or emerald blush adorns this crisp, sweet delight that elevates any dish.",
cotd : "💐 The crop of silken threads. White blooms give rise to fibers forming the fabric industry's foundation.",
od : "🍊 A tangy vitamin trove. These sun-kissed spheres marry zest with wholesome goodness.",
ld : "🥜 Tiny protein-packed pearls. These unassuming pulse seeds store vigor and vitality.",
mad : "🥭 Summer's regal welcome. Juicy aromas waft from this fleshy orb - the season's first delight.",
mbd : "🥜 Nourishment cloaked in green shells. These verdant legumes craft dishes both flavorful and fortifying.",
mtbd : "🌰 Food's direct link to nature. These rustic seeds reflect the toil of fertile fields.",
gd : "🍇 The queen of vineyards. Clusters of green pearls slowly ripen to bursting ruby gems of juicy sweetness.",
pd : "🍈 Relish for sweltering days. A burst of vigor seeds nestle within these bountiful fruits.",
ppd : "🌰 A humble pulse treasure. These petite golden nuggets keep ancient culinary traditions alive.",
pgd : "🍇 A burst of ruby sweetness. Hundreds of glistening arils pack a vitamin and fiber punch.",
wmd : "🍉 Summer's thirst-quenching gem. A green rind conceals a spectacular pink water-vault within."

           
            },
        },
        hi:{
            translation:{
                click_here:"यहां क्लिक करें",
                for_more_info_click_here:"अधिक जानकारी के लिए यहां क्लिक करें",
                welcome_crops_guide:"कृषि फसल गाइड में आपका स्वागत है",
                description_crops_guide:"उसके उत्पादन, प्रबंधन और सर्वोत्तम प्रथाओं के बारे में विस्तृत जानकारी देखने के लिए एक फसल का चयन करें।",

                greeting : 'नमस्ते अपने फसल चयन को अनुकूलित करें',
                sub_greeting : 'डेटा-संचालित अंतर्दृष्टि के साथ अपनी उपज अधिकतम करें',
                sub_greeting_btn1 : 'आरंभ करें',
                sub_greeting_btn2 : 'और अधिक जानें',
                features:'विशेषताएँ',
                features_heading : "डेटा-संचालित अंतर्दृष्टि के साथ किसानों को सशक्त बनाना",
                features_heading_sub : "सफल फसल के लिए सोच-समझकर निर्णय लें",
                home : "मुख्य पृष्ठ",
                about:"हमारे बारे में",
                guide:"मार्गदर्शक",
                login:"लॉग इन करें",
                logout:"लॉग आउट",
                en:"अंग्रेज़ी",
                hi:"हिंदी",
                ta:"हिंदी",
                pridiction:"अनुमान",
                soil_analysis:"मिट्टी विश्लेषण",
                soil_analysis_guidance:"अपनी मिट्टी की विश्लेषण करें और अपनी फसल के लिए सही उपाय निकालें",
                crop_recommendation:"फसल की सिफारिश",
                crop_recommendation_guidance:"अपनी फसल के लिए सही सिफारिश प्राप्त करें",
                seasonal_crop_recommendation:"मौसमी फसल की सिफारिश",
                seasonal_crop_recommendation_guidance:"अपने क्षेत्र के लिए मौसमी फसल की सिफारिश प्राप्त करें",
                optimal_crops:"आदर्श फसलें",
                optimal_crops_guidance:"अपने क्षेत्र के लिए आदर्श फसलें चुनें",
                home_page_empower: "स्मार्ट क्रॉप सिफारिश के साथ किसानों को सशक्त बनाना",
                home_page_empower_sub:"हमारा प्लेटफॉर्म मिट्टी की स्थिति का विश्लेषण करता है ताकि व्यक्तिगत फसल सुझाव प्रदान कर सके, जिससे किसान सफल फसल के लिए सोच-समझकर निर्णय ले सकें। हमारी विशेषज्ञता और प्रौद्योगिकी के साथ, आप अपने खेतों में उत्पादकता और लाभकारीता को बढ़ा सकते हैं।",
                common_question:"सामान्य प्रश्न",
                comm_here_Are:"यहां हमारे प्लेटफॉर्म के बारे में कुछ सामान्य प्रश्न हैं",
                q1:"वेबसाइट कैसे तय करती है कि किस फसल की सिफारिश करनी चाहिए",
                a1:"हमारी वेबसाइट उन्नत एल्गोरिदम का उपयोग करती है जो मिट्टी की स्थिति का विश्लेषण करते हैं और विभिन्न कारकों के आधार पर सबसे उपयुक्त फसलों की सिफारिश करती हैं",
                q2:"फसल की सिफारिश कितनी सटीक है",
                a2:"हमारी सिफारिशें व्यापक शोध और डेटा विश्लेषण पर आधारित हैं, जिससे वे अत्यधिक सटीक और विश्वसनीय होती हैं",
                q3:"क्या वेबसाइट का उपयोग करना मुफ्त है?",
                a3:"हां, हमारी वेबसाइट पूरी तरह से मुफ्त है और सभी किसानों के लिए उपलब्ध है",    
                welcome_back:"वापसी पर स्वागत है",
                please_sign_in_to_continue:"जारी रखने के लिए कृपया साइन इन करें",
                email:"ईमेल",
                password:"पासवर्ड",
                Dont_have_an_account:"खाता नहीं है?",
                signup:"साइन अप करें",
                Something_went_wrong:"कुछ गलत हो गया",
                create_account:"खाता बनाएं",
                please_sign_in_to_continue:"जारी रखने के लिए कृपया साइन इन करें",
                already_have_an_account:"पहले से ही खाता है?",
                full_name:"पूरा नाम",
                confirm_password:"पासवर्ड की पुष्टि करें",
                our_project_about:"हमारा परियोजना एक क्रॉप सिफारिश प्रणाली बनाकर कृषि पर पर्यावरण परिवर्तन के प्रभाव का सामना करती है। मशीन लर्निंग का उपयोग करके, हम मौसम और मिट्टी की स्थिति के आधार पर फसल चयन को अनुकूलित करते हैं, जिससे उत्पादन बढ़ाने का लक्ष्य होता है।",
                thrive:"एक बेहतर भविष्य के लिए उन्नति",
                greeting_guide:"मार्गदर्शक खंड में आपका स्वागत है",
                greeting_guide_sub:"यहां आप हमारे प्लेटफॉर्म का उपयोग कैसे करें और इसकी सुविधाओं का अधिकतम लाभ कैसे उठाएं, इसके बारे में विस्तृत जानकारी प्राप्त कर सकते हैं",
                
                step1: "स्टेप 1: अपना मृदा स्वास्थ्य कार्ड तैयार करें।",
                sq1: "मृदा स्वास्थ्य कार्ड क्या है?",
                sa1: "मृदा स्वास्थ्य कार्ड का उपयोग मृदा स्वास्थ्य की वर्तमान स्थिति का मूल्यांकन करने के लिए किया जाता है और समय के साथ इसे उस समय से जुड़े भूमि प्रबंधन द्वारा प्रभावित मृदा स्वास्थ्य में परिवर्तनों को निर्धारित करने के लिए इस्तेमाल होता है। मृदा स्वास्थ्य कार्ड पर मृदा स्वास्थ्य सूचकांक और संबंधित वर्णनात्मक शब्द प्रदर्शित होते हैं। इन सूचकांकों पर आम तौर पर किसानों के प्रायोगिक अनुभव और स्थानीय प्राकृतिक संसाधनों के ज्ञान पर आधारित होता है।",
                sa1_1: "मृदा स्वास्थ्य कार्ड (एसएचसी) भारत सरकार की एक योजना है जो कृषि और किसान कल्याण मंत्रालय के तहत कृषि और सहकारिता विभाग द्वारा प्रोत्साहित की जाती है। यह सभी राज्य और केंद्र शासित प्रदेश सरकारों के कृषि विभाग के माध्यम से कार्यान्वित की जा रही है।",
                sq2: "मृदा स्वास्थ्य कार्ड में क्या होता है?",
                sa2: "यह उसके मृदा की स्थिति को 12 पैरामीटरों के संबंध में दर्शाएगा, जैसे N, P, K (मैक्रो- पोषक तत्व); S (सेकेंडरी- पोषक तत्व); Zn, Fe, Cu, Mn, Bo (माइक्रो - पोषक तत्व); और pH, EC, OC (भौतिक पैरामीटर)। इस आधार पर, हमारा मॉडल उस किसान के लिए फसल का चयन करता है जिसे उसे उगाना चाहिए।",
                step2: "स्टेप 2: नमूने का संग्रहण",
                step2_des: "मृदा नमूने सिंचित क्षेत्र में 2.5 हेक्टेयर के ग्रिड में खींचे जाएंगे",
                sq3: "मृदा नमूने कौन खींचेगा?",
                sa3: "राज्य सरकार अपने कृषि विभाग के कर्मचारियों या आउटसोर्स की गई एजेंसी के कर्मचारियों के माध्यम से नमूने लेगी। राज्य सरकार लोकल कृषि / विज्ञान महाविद्यालयों के छात्रों को भी शामिल कर सकती है।",
                sq4: "मृदा नमूना लेने का समय क्या है?",
                sa4: "मृदा नमूने सामान्य रूप से वर्ष में दो बार लिए जाते हैं, रबी और खरीफ फसल के कटाई के बाद या जब कोई फसल खेत में नहीं होती है।",
                sq5: "किस तरह से किसान के खेत से मृदा नमूने लिए जाएंगे?",
                sa5: "मृदा नमूने एक प्रशिक्षित व्यक्ति द्वारा 15-20 सेमी के गहराई से काटकर लिए जाएंगे। यह चारों कोनों और खेत के केंद्र से लिया जाएगा और भलीभांति मिलाया जाएगा और इसका एक हिस्सा नमूना के रूप में उठाया जाएगा।",
                step3: "स्टेप 3: नमूने को प्रयोगशाला में भेजना",
                s3_1: "मृदा नमूना निम्न स्वीकृत मानकों के अनुसार सभी 12 पैरामीटरों के लिए निम्नलिखित तरीके से जांचा जाएगा:",
                s3_2: "कृषि विभाग द्वारा स्वामित्व में रखी मृदा परीक्षण प्रयोगशाला में और उनके अपने कर्मचारियों द्वारा।",
                s3_3: "ICAR संस्थानों में जैसे कि KVKs और SAUs में।",
                s3_4: "विज्ञान महाविद्यालयों / विश्वविद्यालयों के प्रायोगशालाओं में एक प्रोफेसर / वैज्ञानिक के पर्यवेक्षण में छात्रों द्वारा।",
                s3_5: "आउटसोर्स की गई एजेंसी द्वारा स्वामित्व में रखी मृदा परीक्षण प्रयोगशाला में और उनके कर्मचारियों द्वारा।",
                sq6: "मृदा परीक्षण का लागत क्या है?",
                sa6: "राज्य सरकार को प्रति मृदा नमूने के लिए 190 रुपये का दान दिया जाता है। इसमें मृदा नमूने के संग्रह की लागत, उसका परीक्षण, मृदा स्वास्थ्य कार्ड के उत्पादन और किसान को वितरण शामिल है।",
                logo:"क्रोफ़ेसी",

                nitrogen:"नाइट्रोजन (एन)",
                nitrogen_des:"नाइट्रोजन पौधे की वृद्धि और विकास के लिए आवश्यक एक मुख्य पोषक तत्व है। यह फोटोसिंथेसिस, प्रोटीन संश्लेषण और समग्र पौधे की उपचय में महत्वपूर्ण भूमिका निभाता है। नाइट्रोजन की कमी से पौधे की वृद्धि में रुकावट, पत्तों के पीलापन और उत्पादन में कमी हो सकती है।",
                phosphorus:"फास्फोरस (पी)",
                phosphorus_des:"फास्फोरस ऊर्जा स्थानांतरण, जड़ विकास और पौधों में फल गठन के लिए आवश्यक है। यह डीएनए, आरएनए और एटीपी के घटक है, जो विभिन्न अवयविक प्रक्रियाओं में महत्वपूर्ण भूमिका निभाता है। फास्फोरस की कमी से अच्छे जड़ विकास और विलंबित परिपक्वता हो सकती है।", 
                potassium:"पोटैशियम",
                potassium_des:"पोटैशियम पौधों में जल ग्रहण, एंजाइम सक्रियण और फोटोसिंथेसिस को नियंत्रित करने में शामिल है। यह तुर्गोर दबाव, पोषक तत्व परिवहन और समग्र पौधे की स्वास्थ्य सुनिश्चित करने में महत्वपूर्ण भूमिका निभाता है। पोटैशियम की कमी से पौधों की मुरझान, फलों की गुणवत्ता में कमी और रोगों के प्रति अधिक संवेदनशीलता हो सकती है।",
                temperature:"विभिन्नता सेल्सियस में",
                temperature_des:"तापमान पौधे की वृद्धि और विकास पर प्रभाव डालने वाला महत्वपूर्ण कारक है। प्रत्येक फसल के लिए अंकुरण, वनस्पतिक वृद्धि, फूलने और फल सेट करने के लिए एक आदर्श तापमान सीमा होती है। अत्यधिक तापमान पौधों को तनावित कर सकता है, जिससे उनकी भौतिक प्रक्रियाएं और उत्पादन क्षमता प्रभावित हो सकती हैं।",
                humidity:"सापेक्ष आर्द्रता (%)",
                humidity_des:"नमी वह मात्रा है जो वायु में मौजूद है। यह पौधे की श्वासन, पोषक तत्व अवशोषण और समग्र वृद्धि पर प्रभाव डालती है। उच्च आर्द्रता स्तर फंगल रोगों को बढ़ावा दे सकता है, जबकि कम आर्द्रता पौधों में जल की कमी कर सकती है।",
                soil_ph:"मिट्टी का पीएच",
                soil_ph_des:"मिट्टी का पीएच मिट्टी की अम्लीयता या क्षारता का माप है। यह पोषक तत्वों की उपलब्धता, कीटाणु गतिविधि और पौधों की वृद्धि पर प्रभाव डालता है। अधिकांश फसलें अपने लिए एक हल्के अम्लीय से न्यूनतम पीएच सीमा पसंद करती हैं ताकि उत्पोषण अवशोषण और जड़ विकास के लिए आदर्श हो।",
                rainfall:"वर्षा (मिमी)",
                rainfall_des:"वर्षा एक महत्वपूर्ण पर्यावरणीय कारक है जो फसल की वृद्धि और उत्पादन पर प्रभाव डालता है। पर्याप्त वर्षा की कमी जलवायु तनाव का कारण बन सकती है, जिससे पौधे की वृद्धि और उत्पादकता प्रभावित हो सकती है। अत्यधिक वर्षा जलभराव, पोषक तत्वों का अवशोषण और वृद्धि रोगों की बढ़ती संभावना का कारण बन सकती है।",

                ques_parameter:"अपने खेत के लिए इन पैरामीटर्स को कैसे खोजें?",
                nitrogen_p: "नाइट्रोजन - मृदा में नाइट्रोजन सामग्री का अनुपात",
                phosphorus_p: "फॉस्फोरस - मृदा में फॉस्फोरस सामग्री का अनुपात",
                potassium_p: "पोटासियम - मृदा में पोटासियम सामग्री का अनुपात",
                temperature_p: "तापमान - डिग्री सेल्सियस में तापमान",
                humidity_p: "नमी - प्रतिशत में सापेक्ष नमी",
                ph_p: "पीएच - मृदा का पीएच मूल्य",
                rainfall_p: "वर्षा - मिमी में वर्षा",
                predict:"भविष्यवाणी",
                Temperature:"तापमान",
                Rainfall:"वर्षा",
                STemp:"बुआई का तापमान",
                HTemp:"फसल का तापमान",
                
                watermelon:"तरबूज",
                watermelon_wr:"तरबूज़ गर्म मौसम की फसलें हैं जिन्हें बढ़ते मौसम के दौरान गर्म तापमान और भरपूर धूप की आवश्यकता होती है। तरबूज की खेती के लिए आदर्श तापमान सीमा 25°C से 35°C (77°F से 95°F) के बीच है। तरबूज़ को कम से कम 90 से 120 दिनों के लंबे, गर्म बढ़ते मौसम की आवश्यकता होती है।",
                watermelon_sd:"तरबूज़ 6.0 से 7.0 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी में सबसे अच्छे से उगते हैं। तरबूज की खेती के लिए अच्छी कार्बनिक पदार्थ सामग्री वाली बलुई दोमट या दोमट मिट्टी आदर्श होती है। तरबूज़ भारी मिट्टी या लवणीय मिट्टी को सहन नहीं करते हैं।",
                watermelon_cv:"तरबूज की कुछ लोकप्रिय किस्मों में क्रिमसन स्वीट, शुगर बेबी, चार्ल्सटन ग्रे, संगरिया और येलो डॉल शामिल हैं। किस्म का चुनाव जलवायु, रोग प्रतिरोधक क्षमता, फल का आकार और इच्छित उपयोग (ताजा खपत या प्रसंस्करण) जैसे कारकों पर निर्भर करता है।",
                watermelon_lp:"तरबूज की खेती के लिए भूमि की तैयारी में गहरी जुताई या किसी भी कठोर खेत को तोड़ने और अच्छी मिट्टी का वातन सुनिश्चित करने के लिए खेती शामिल है। जल निकासी में सुधार और कुंड सिंचाई की सुविधा के लिए ऊंचे बिस्तरों या मेड़ों का निर्माण किया जा सकता है।",
                watermelon_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोगमुक्त तरबूज के बीजों का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर 1.5 से 2.5 किलोग्राम/हेक्टेयर (1.3 से 2.2 पाउंड/एकड़) के बीच भिन्न होती है।",
                watermelon_soi:"तरबूज आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोए जाते हैं जब मिट्टी का तापमान 20°C (68°F) या इससे अधिक हो जाता है। बीजों को 2-3 सेमी (0.8-1.2 इंच) की गहराई पर और पंक्तियों के बीच 1.5-2 मीटर (5-6.5 फीट) और पहाड़ियों या पौधों के बीच 60-90 सेमी (24-36 इंच) की दूरी पर लगाया जाना चाहिए।",
                watermelon_fu:"तरबूज़ को मध्यम पोषक तत्व की आवश्यकता होती है। बोरॉन और जिंक जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए",
                watermelon_id:"तरबूज़ को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, विशेष रूप से फूल आने और फलों के विकास के चरण के दौरान। तरबूज़ की खेती के लिए फ़रो या ड्रिप सिंचाई विधियों का आमतौर पर उपयोग किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                watermelon_pd:"तरबूज विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें एफिड्स, ककड़ी बीटल, फ्यूजेरियम विल्ट और एन्थ्रेक्नोज शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                watermelon_hm:"तरबूज़ की कटाई आम तौर पर हाथ से की जाती है जब फल का निचला भाग (पेट) पीला-सफ़ेद हो जाता है और फल के तने के सबसे निकट का टेंड्रिल भूरा और सूखा हो जाता है। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है। कटे हुए तरबूजों को चोट लगने और क्षति से बचाने के लिए सावधानी से संभालना चाहिए।",
                watermelon_temp:"15-25° C",
                watermelon_rain:"60-150 mm",
                watermelon_stemp:"22-25° C",
                watermelon_htemp:"28-30° C",
                
                pomegranate:"अनार",
                pomegranate_wr:"अनार अर्ध-शुष्क से उपोष्णकटिबंधीय जलवायु में गर्म, शुष्क ग्रीष्मकाल और हल्की सर्दियों के साथ पनपता है। बढ़ते मौसम के दौरान अनार की खेती के लिए आदर्श तापमान सीमा 20°C से 35°C (68°F से 95°F) के बीच है। उचित फूल और फल लगने के लिए सर्दियों के दौरान अनार को 7°C (45°F) से नीचे 100 से 200 घंटे की शीतलन अवधि की आवश्यकता होती है।",
                pomegranate_sd:"अनार को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन वे 5.5 से 7.5 के पीएच रेंज के साथ अच्छी तरह से सूखा, थोड़ा अम्लीय से लेकर तटस्थ मिट्टी पसंद करते हैं। अच्छी कार्बनिक पदार्थ सामग्री वाली बलुई दोमट या दोमट मिट्टी अनार की खेती के लिए आदर्श होती है। अनार लवणीय मिट्टी के प्रति मध्यम रूप से सहनशील होते हैं।",
                pomegranate_cv:"अनार की कुछ लोकप्रिय किस्मों में वंडरफुल, भगवा, मृदुला, गणेश और रूबी शामिल हैं। किस्म का चुनाव जलवायु, फल का आकार, रंग और इच्छित उपयोग (ताजा खपत या प्रसंस्करण) जैसे कारकों पर निर्भर करता है।",
                pomegranate_lp:"अनार के बागों के लिए भूमि की तैयारी में किसी भी मौजूदा वनस्पति के क्षेत्र को साफ करना, स्टंप और चट्टानों को हटाना और यदि आवश्यक हो तो भूमि को समतल करना शामिल है। जलभराव को रोकने के लिए उचित जल निकासी प्रणालियाँ, जैसे खाई या ऊंचे बिस्तर स्थापित किए जा सकते हैं।",
                pomegranate_si:"अनार को आमतौर पर रूटस्टॉक्स पर कटिंग या ग्राफ्टिंग के माध्यम से प्रचारित किया जाता है। सही किस्म के पौधे और रोग-मुक्त स्टॉक सुनिश्चित करने के लिए उच्च गुणवत्ता, प्रमाणित और रोग-मुक्त रोपण सामग्री का उपयोग किया जाना चाहिए।",
                pomegranate_soi:"रोपण आमतौर पर शुरुआती वसंत में या आखिरी ठंढ के बाद किया जाता है। अनार के पेड़ों के बीच की दूरी विविधता और रूटस्टॉक के आधार पर भिन्न होती है, लेकिन आम तौर पर वर्गाकार या आयताकार पैटर्न में 4 से 6 मीटर (13 से 20 फीट) के बीच होती है।",
                pomegranate_fu:"अनार के पेड़ों को इष्टतम विकास और उपज बनाए रखने के लिए संतुलित उर्वरक की आवश्यकता होती है। जिंक और बोरॉन जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताओं को निर्धारित करने के लिए मृदा परीक्षण आयोजित किया जाना चाहिए।",
                pomegranate_id:"अनार के पेड़ों को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, खासकर फूल और फल विकास के चरणों के दौरान। ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिस्टम का उपयोग आमतौर पर अनार के बगीचों के लिए किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                pomegranate_pd:"अनार के पेड़ विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें फल छेदक, पत्ती और फल धब्बे रोग, विल्ट और बैक्टीरियल ब्लाइट शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                pomegranate_hm:"अनार की कटाई आमतौर पर हाथ से, विशेष कटाई उपकरणों का उपयोग करके या पेड़ की शाखाओं को हिलाकर की जाती है। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है। कटे हुए अनारों को चोट लगने और क्षति से बचाने के लिए सावधानी से संभालना चाहिए।",
                pomegranate_temp:"15-25° C",
                pomegranate_rain:"60-150 mm",
                pomegranate_stemp:"22-25° C",
                pomegranate_htemp:"28-30° C",

                pigeonpeas:"अरहर",
                pigeonpeas_wr:"अरहर एक सूखा-सहिष्णु फसल है जिसे उष्णकटिबंधीय और उपोष्णकटिबंधीय दोनों क्षेत्रों में उगाया जा सकता है। अरहर की खेती के लिए आदर्श तापमान सीमा 20°C से 35°C (68°F से 95°F) के बीच है। अरहर को 600 से 1000 मिमी (24 से 39 इंच) की वार्षिक वर्षा के साथ गर्म, आर्द्र जलवायु की आवश्यकता होती है, जो बढ़ते मौसम के दौरान अच्छी तरह से वितरित होती है।",
                pigeonpeas_sd:"अरहर को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, जिसमें रेतीली, दोमट और यहां तक ​​कि मध्यम लवणीय मिट्टी भी शामिल है। वे 5.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली मिट्टी पसंद करते हैं। अरहर कम मिट्टी की उर्वरता को सहन कर सकती है लेकिन अच्छी कार्बनिक पदार्थ सामग्री वाली मिट्टी में बेहतर प्रदर्शन करती है।",
                pigeonpeas_cv:"अरहर की कुछ लोकप्रिय किस्मों में आशा, मारुति, शरद, यूपीएएस 120 और जीटी 101 शामिल हैं। किस्म का चुनाव परिपक्वता अवधि, रोग प्रतिरोधक क्षमता और इच्छित उपयोग (ताजा खपत, दाल, या चारा) जैसे कारकों पर निर्भर करता है।",
                pigeonpeas_lp:"अरहर की खेती के लिए भूमि की तैयारी में किसी भी कठोर खेत को तोड़ने और अच्छी मिट्टी का वातन सुनिश्चित करने के लिए गहरी जुताई या जुताई शामिल है। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए।",
                pigeonpeas_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोग रहित अरहर के बीज का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर 10 से 15 किलोग्राम/हेक्टेयर (9 से 13 पाउंड/एकड़) के बीच भिन्न होती है।",
                pigeonpeas_soi:"अरहर को आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोया जाता है जब मिट्टी का तापमान 25°C (77°F) या इससे अधिक हो जाता है। बीजों को 5-8 सेमी (2-3 इंच) की गहराई पर और पंक्तियों के बीच 60-90 सेमी (24-36 इंच) और पौधों के बीच 20-30 सेमी (8-12 इंच) की दूरी पर बोना चाहिए।",
                pigeonpeas_fu:"अरहर को मध्यम पोषक तत्वों की आवश्यकता होती है। नाइट्रोजन की थोड़ी मात्रा के साथ फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए।",
                pigeonpeas_id:"अरहर सूखा-सहिष्णु है और इसे मध्यम वर्षा वाले क्षेत्रों में वर्षा आधारित परिस्थितियों में उगाया जा सकता है। हालाँकि, लंबे समय तक सूखे के दौरान, विशेष रूप से फूल आने और फली भरने के चरण के दौरान, पूरक सिंचाई आवश्यक हो सकती है।",
                pigeonpeas_pd:"अरहर विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होती है, जिनमें फली छेदक, फली मक्खी, विल्ट और स्टेरिलिटी मोज़ेक रोग शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए",
                pigeonpeas_hm:"अरहर की फलियाँ आमतौर पर तब हाथ से काटी जाती हैं जब फलियाँ भूरी हो जाती हैं और सूख जाती हैं। पौधों को आधार से काटा जा सकता है और गहाई से पहले सूखने दिया जा सकता है। अरहर की गुणवत्ता बनाए रखने के लिए कटी हुई फसल को उचित तरीके से सुखाना और गहाई करना महत्वपूर्ण है।",
                pigeonpeas_temp:"15-25° C",
                pigeonpeas_rain:"60-150 mm",
                pigeonpeas_stemp:"22-25° C",
                pigeonpeas_htemp:"28-30° C",

                papaya:"पपीता",
                papaya_wr:"पपीता एक उष्णकटिबंधीय फल की फसल है जिसे पूरे वर्ष गर्म तापमान और उच्च आर्द्रता की आवश्यकता होती है। पपीते की खेती के लिए आदर्श तापमान सीमा 20°C से 35°C (68°F से 95°F) के बीच है। पपीते के पौधे पाले के प्रति संवेदनशील होते हैं और 10°C (50°F) से नीचे तापमान बर्दाश्त नहीं कर सकते।",
                papaya_sd:"पपीता विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन यह 5.5 से 6.5 पीएच रेंज वाली अच्छी जल निकासी वाली उपजाऊ मिट्टी को पसंद करता है। अच्छी कार्बनिक पदार्थ सामग्री वाली बलुई दोमट या दोमट मिट्टी पपीते की खेती के लिए आदर्श होती है। पपीते के पौधे जलभराव या लवणीय मिट्टी को सहन नहीं करते हैं।",
                papaya_cv:"पपीते की कुछ लोकप्रिय किस्मों में हवाईयन, कूर्ग हनी ड्यू, रेड लेडी, पूसा डिलीशियस और पूसा नन्हा शामिल हैं। किस्म का चुनाव जलवायु, रोग प्रतिरोधक क्षमता, फल का आकार और इच्छित उपयोग (ताजा खपत या प्रसंस्करण) जैसे कारकों पर निर्भर करता है।",
                papaya_lp:"पपीते की खेती के लिए भूमि की तैयारी में किसी भी मौजूदा वनस्पति के क्षेत्र को साफ करना, स्टंप और चट्टानों को हटाना और यदि आवश्यक हो तो भूमि को समतल करना शामिल है। जलभराव को रोकने के लिए उचित जल निकासी प्रणालियाँ, जैसे खाई या ऊंचे बिस्तर स्थापित किए जा सकते हैं।",
                papaya_si:"पपीते को बीज या ग्राफ्टिंग जैसे वानस्पतिक तरीकों से प्रचारित किया जा सकता है। बुआई के लिए उच्च गुणवत्ता, प्रमाणित एवं रोगमुक्त बीजों का प्रयोग करना चाहिए। अनुशंसित बीजारोपण दर प्रसार विधि और रोपण घनत्व के आधार पर भिन्न होती है।",
                papaya_soi:"पपीते के बीज आमतौर पर नर्सरी बेड या कंटेनरों में बोए जाते हैं, और 2-3 महीने के बाद पौधों को मुख्य खेत में प्रत्यारोपित किया जाता है। पपीते के पौधों के बीच की दूरी विविधता के आधार पर भिन्न-भिन्न होती है, लेकिन आमतौर पर वर्गाकार या आयताकार पैटर्न में 2 से 3 मीटर (6.5 से 10 फीट) के बीच होती है।",
                papaya_fu:"पपीते के पौधों को इष्टतम विकास और उपज बनाए रखने के लिए संतुलित उर्वरक की आवश्यकता होती है। जिंक और बोरॉन जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताओं को निर्धारित करने के लिए मृदा परीक्षण आयोजित किया जाना चाहिए।",
                papaya_id:"पपीते के पौधों को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, खासकर फूल आने और फलों के विकास के चरण के दौरान। पपीते की खेती के लिए आमतौर पर ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिस्टम का उपयोग किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                papaya_pd:"पपीते के पौधे विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें पपीता रिंगस्पॉट वायरस, पपीता माइलबग, पपीता व्हाइटफ्लाई और एन्थ्रेक्नोज शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                papaya_hm:"पपीते के फल आम तौर पर हाथ से काटे जाते हैं जब वे पूरी तरह से परिपक्व हो जाते हैं लेकिन फिर भी दृढ़ होते हैं। फल की शेल्फ लाइफ बढ़ाने के लिए उसकी कटाई छोटे तने से करनी चाहिए। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है।",

                papaya_temp:"15-25° C",
                papaya_rain:"60-150 mm",
                papaya_stemp:"22-25° C",
                papaya_htemp:"28-30° C",

                orange:"संतरा",
                orange_wr:"संतरे पूरे वर्ष गर्म तापमान और भरपूर धूप के साथ उपोष्णकटिबंधीय से उष्णकटिबंधीय जलवायु में पनपते हैं। संतरे की खेती के लिए आदर्श तापमान सीमा 20°C से 30°C (68°F से 86°F) के बीच है। फलों के सर्वोत्तम विकास के लिए संतरे को शून्य से ऊपर तापमान वाली हल्की सर्दी और गर्म, शुष्क वसंत और गर्मियों की आवश्यकता होती है।",
                orange_sd:"संतरे विभिन्न प्रकार की मिट्टी में उगाए जा सकते हैं, लेकिन वे 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, गहरी और उपजाऊ मिट्टी पसंद करते हैं। अच्छी कार्बनिक पदार्थ सामग्री वाली बलुई दोमट या दोमट मिट्टी संतरे की खेती के लिए आदर्श होती है। खराब जल निकासी या उच्च लवणता वाली मिट्टी से बचना चाहिए।",
                orange_cv:"संतरे की कुछ लोकप्रिय किस्मों में वालेंसिया, नेवेल, हैमलिन, पेरा और जाफ़ा शामिल हैं। किस्म का चुनाव जलवायु, रोग प्रतिरोधक क्षमता और इच्छित उपयोग (ताजा उपभोग या प्रसंस्करण) जैसे कारकों पर निर्भर करता है।",
                orange_lp:"संतरे के बागों के लिए भूमि की तैयारी में किसी भी मौजूदा वनस्पति के क्षेत्र को साफ करना, स्टंप और चट्टानों को हटाना और यदि आवश्यक हो तो भूमि को समतल करना शामिल है। जलभराव को रोकने के लिए उचित जल निकासी प्रणालियाँ, जैसे खाई या टाइल वाली नालियाँ स्थापित की जा सकती हैं।",
                orange_si:"संतरे को आमतौर पर रूटस्टॉक्स पर ग्राफ्टिंग या बडिंग के माध्यम से प्रचारित किया जाता है। रूटस्टॉक को कीटों, बीमारियों या मिट्टी की स्थितियों के प्रति इसके प्रतिरोध के लिए चुना जाता है। उच्च गुणवत्ता वाली, प्रमाणित एवं रोगमुक्त रोपण सामग्री का उपयोग करना चाहिए।",
                orange_soi:"रोपण आमतौर पर शुरुआती वसंत में या आखिरी ठंढ के बाद किया जाता है। संतरे के पेड़ों के बीच की दूरी विविधता और रूटस्टॉक के आधार पर भिन्न होती है, लेकिन आमतौर पर वर्गाकार या आयताकार पैटर्न में 6 से 8 मीटर (20 से 26 फीट) के बीच होती है।",
                orange_fu:"संतरे के पेड़ों को इष्टतम विकास और उपज बनाए रखने के लिए संतुलित उर्वरक की आवश्यकता होती है। जिंक और बोरॉन जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताओं को निर्धारित करने के लिए मृदा परीक्षण आयोजित किया जाना चाहिए",
                orange_id:"संतरे के पेड़ों को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, खासकर फूल और फल विकास के चरणों के दौरान। ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिस्टम का उपयोग आमतौर पर संतरे के बगीचों के लिए किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                orange_pd:"संतरे के पेड़ विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें सिट्रस लीफमाइनर, सिट्रस ट्रिस्टेज़ा वायरस, सिट्रस ग्रीनिंग रोग और सिट्रस कैंकर शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए",
                orange_hm:"संतरे की कटाई आमतौर पर हाथ से, विशेष कटाई उपकरणों का उपयोग करके या पेड़ की शाखाओं को हिलाकर की जाती है। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है। कटे हुए संतरे को अक्सर उनकी शेल्फ लाइफ को बढ़ाने और उपस्थिति को बढ़ाने के लिए कवकनाशी या मोम के साथ इलाज किया जाता है।",
                orange_temp:"15-25° C",
                orange_rain:"60-150 mm",
                orange_stemp:"22-25° C",
                orange_htemp:"28-30° C",

                muskmelon:"खरबूजा",
                muskmelon_wr:"खरबूजे की कटाई आमतौर पर तब की जाती है जब फलियाँ भूरी हो जाती हैं और पत्तियाँ सूखने लगती हैं। पौधों को कंबाइन हार्वेस्टर का उपयोग करके या तो मैन्युअल रूप से या यंत्रवत् काटा जाता है। खरबूजे की गुणवत्ता बनाए रखने के लिए कटी हुई फसल को उचित तरीके से सुखाना और गहाई करना महत्वपूर्ण है।",
                muskmelon_sd:"खरबूजे 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी में सबसे अच्छे से उगते हैं। अच्छी कार्बनिक पदार्थ सामग्री वाली बलुई दोमट या दोमट मिट्टी खरबूजे की खेती के लिए आदर्श होती है। खराब जल निकासी या उच्च लवणता वाली मिट्टी से बचना चाहिए",
                muskmelon_cv:"खरबूजे की कुछ लोकप्रिय किस्मों में कैंटालूप, हामी मेलन, गैलिया, चारेंटैस और कैसाबा शामिल हैं। किस्म का चुनाव जलवायु, रोग प्रतिरोधक क्षमता और इच्छित उपयोग (ताजा उपभोग या प्रसंस्करण) जैसे कारकों पर निर्भर करता है।",
                muskmelon_lp:"खरबूजे की खेती के लिए भूमि की तैयारी में किसी भी कड़ी को तोड़ने और अच्छी मिट्टी वातायन सुनिश्चित करने के लिए गहरी जुताई या खेती शामिल है। जल निकासी में सुधार और नाली सिंचाई की सुविधा के लिए ऊंचे बिस्तरों या मेड़ों का निर्माण किया जा सकता है।",
                muskmelon_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोगमुक्त खरबूजे के बीजों का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर 1.5 से 2.5 किलोग्राम/हेक्टेयर (1.3 से 2.2 पाउंड/एकड़) के बीच भिन्न होती है।",
                muskmelon_soi:"खरबूजे आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोए जाते हैं जब मिट्टी का तापमान 20°C (68°F) या इससे अधिक हो जाता है। बीजों को 2-3 सेमी (0.8-1.2 इंच) की गहराई पर और पंक्तियों के बीच 60-90 सेमी (24-36 इंच) और पौधों के बीच 30-45 सेमी (12-18 इंच) की दूरी पर लगाया जाना चाहिए।",
                muskmelon_fu:"खरबूजे को मध्यम पोषक तत्व की आवश्यकता होती है। बोरॉन और जिंक जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए।",
                muskmelon_id:"खरबूजे को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, विशेष रूप से फूल आने और फलों के विकास के चरण के दौरान। खरबूजे की खेती के लिए आमतौर पर ड्रिप सिंचाई या कुंड सिंचाई विधियों का उपयोग किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                muskmelon_pd:"खरबूजे विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें एफिड्स, स्पाइडर माइट्स, पाउडरी फफूंदी और डाउनी फफूंदी शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                muskmelon_hm:"खरबूजे की कटाई आम तौर पर हाथ से की जाती है जब फल बेल से आसानी से फिसल जाता है और तना फल से साफ अलग हो जाता है। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है। कटे हुए खरबूजे को चोट लगने और क्षति से बचाने के लिए सावधानी से संभालना चाहिए।",

                muskmelon_temp:"15-25° C",
                muskmelon_rain:"60-150 mm",
                muskmelon_stemp:"22-25° C",
                muskmelon_htemp:"28-30° C",

                mothbeans:"मोथबीन",
                mothbeans_wr:"मोथबीन एक सूखा-सहिष्णु फसल है जिसे शुष्क और अर्ध-शुष्क क्षेत्रों में उगाया जा सकता है। वे 25°C से 40°C (77°F से 104°F) तक के तापमान वाली गर्म और शुष्क जलवायु परिस्थितियों में पनपते हैं। मोथबीन को 300 से 500 मिमी (12 से 20 इंच) की वार्षिक वर्षा की आवश्यकता होती है।",
                mothbeans_sd:"मोथबीन को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, जिसमें रेतीली, दोमट और यहां तक ​​कि मध्यम लवणीय मिट्टी भी शामिल है। वे 7.0 से 8.5 पीएच रेंज वाली अच्छी जल निकासी वाली मिट्टी पसंद करते हैं। मोथबीन कम मिट्टी की उर्वरता को सहन कर सकता है लेकिन अच्छी कार्बनिक पदार्थ सामग्री वाली मिट्टी में बेहतर प्रदर्शन करता है",
                mothbeans_cv:"मोथबीन की कुछ लोकप्रिय किस्मों में आरएमओ 40, आरएमओ 225, आरएमओ 257 और आरएमओ 435 शामिल हैं। ये किस्में सूखा सहनशीलता, कीटों और रोगों के प्रति प्रतिरोध और उच्च उपज क्षमता के लिए जानी जाती हैं।",
                mothbeans_lp:"मोठबीन की खेती के लिए भूमि की उचित तैयारी आवश्यक है। किसी भी कठोरता को तोड़ने और अच्छी मिट्टी का वातायन सुनिश्चित करने के लिए खेत की गहरी जुताई करनी चाहिए। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए।",
                mothbeans_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोग रहित मोठबीन के बीजों का उपयोग करना चाहिए। किस्म और रोपण विधि के आधार पर अनुशंसित बीजारोपण दर लगभग 15 से 20 किलोग्राम/हेक्टेयर (13 से 18 पाउंड/एकड़) है।",
                mothbeans_soi:"मोथबीन आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोया जाता है जब मिट्टी का तापमान 25°C (77°F) या इससे अधिक हो जाता है। बीजों को 3-5 सेमी (1.2-2 इंच) की गहराई पर और पंक्तियों के बीच 30-45 सेमी (12-18 इंच) की दूरी पर बोना चाहिए।",
                mothbeans_fu:"मोथबीन को मध्यम पोषक तत्व की आवश्यकता होती है। नाइट्रोजन और फास्फोरस आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए।",
                mothbeans_id:"मोथबीन सूखा-सहिष्णु है और इसे सीमित वर्षा वाले क्षेत्रों में वर्षा आधारित परिस्थितियों में उगाया जा सकता है। हालाँकि, लंबे समय तक सूखे के दौरान, विशेष रूप से फूल आने और फली भरने के चरण के दौरान, पूरक सिंचाई आवश्यक हो सकती है",
                mothbeans_pd:"अन्य फलीदार फसलों की तुलना में मोथबीन कीटों और बीमारियों के प्रति अपेक्षाकृत प्रतिरोधी है। हालाँकि, वे एफिड्स, फली छेदक जैसे कीटों और ख़स्ता फफूंदी और जंग जैसी बीमारियों से प्रभावित हो सकते हैं। फसल की सुरक्षा के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                mothbeans_hm:"मोठबीन की कटाई आम तौर पर तब की जाती है जब फलियाँ भूरी हो जाती हैं और पत्तियाँ सूखने लगती हैं। पौधों को कंबाइन हार्वेस्टर का उपयोग करके या तो मैन्युअल रूप से या यंत्रवत् काटा जाता है। मोथबीन की गुणवत्ता बनाए रखने के लिए कटी हुई फसल को उचित रूप से सुखाना और गहाई करना महत्वपूर्ण है।",
                mothbeans_temp:"15-25° C",
                mothbeans_rain:"60-150 mm",
                mothbeans_stemp:"22-25° C",
                mothbeans_htemp:"28-30° C",

                moongbeans:"मूंग",
                moongbeans_wr:"मूंग गर्म मौसम की फसल है जो गर्म और आर्द्र जलवायु परिस्थितियों में पनपती है। मूंग की खेती के लिए आदर्श तापमान सीमा 25°C से 35°C (77°F से 95°F) के बीच है। मूंग की फलियों को 600 से 800 मिमी (24 से 31 इंच) की वार्षिक वर्षा की आवश्यकता होती है, जो बढ़ते मौसम के दौरान अच्छी तरह से वितरित होती है।",
                moongbeans_sd:"मूंग की फलियाँ विभिन्न प्रकार की मिट्टी में उगाई जा सकती हैं, लेकिन वे 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी पसंद करती हैं। अच्छी जल धारण क्षमता वाली बलुई दोमट या दोमट मिट्टी मूंग की खेती के लिए आदर्श होती है। खराब जल निकासी या उच्च लवणता वाली मिट्टी से बचना चाहिए।",
                moongbeans_cv:"मूंग की कुछ लोकप्रिय किस्मों में पूसा विशाल, पूसा रत्न, पंत मूंग 2 और पीडीएम 139 शामिल हैं। किस्म का चुनाव जलवायु, परिपक्वता अवधि और रोग प्रतिरोधक क्षमता जैसे कारकों पर निर्भर करता है।",
                moongbeans_lp:"मूंग की खेती के लिए भूमि की तैयारी में किसी भी कड़ी को तोड़ने और मिट्टी में अच्छी हवा सुनिश्चित करने के लिए गहरी जुताई या खेती शामिल है। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए।",
                moongbeans_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोग रहित मूंग के बीजों का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर 15 से 20 किलोग्राम/हेक्टेयर (13 से 18 पाउंड/एकड़) के बीच भिन्न होती है।",
                moongbeans_soi:"मूंग की फलियाँ देर से वसंत या गर्मियों की शुरुआत में बोई जा सकती हैं जब मिट्टी का तापमान 20°C (68°F) या इससे अधिक हो जाता है। बीजों को 3-5 सेमी (1.2-2 इंच) की गहराई पर और पंक्तियों के बीच 30-45 सेमी (12-18 इंच) की दूरी पर बोना चाहिए।",
                moongbeans_fu:"मूंग की फलियों को मध्यम पोषक तत्वों की आवश्यकता होती है। नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए",
                moongbeans_id:"मूंग की फलियों को बढ़ते मौसम के दौरान, विशेष रूप से फूल आने और फली भरने के चरण के दौरान मध्यम नमी की आवश्यकता होती है। अपर्याप्त या अनियमित वर्षा वाले क्षेत्रों में सिंचाई आवश्यक हो सकती है। मूंग की खेती के लिए फ़रो या स्प्रिंकलर सिंचाई विधियों का उपयोग किया जा सकता है।",
                moongbeans_pd:"मूंग की फलियाँ विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होती हैं, जिनमें एफिड्स, फली छेदक, पीला मोज़ेक वायरस और ख़स्ता फफूंदी शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                moongbeans_hm:"मूंग की फलियों की कटाई आमतौर पर तब की जाती है जब फलियाँ भूरी हो जाती हैं और पत्तियाँ सूखने लगती हैं। पौधों को कंबाइन हार्वेस्टर का उपयोग करके या तो मैन्युअल रूप से या यंत्रवत् काटा जाता है। मूंग की फलियों की गुणवत्ता बनाए रखने के लिए कटी हुई फसल को उचित तरीके से सुखाना और गहाई करना महत्वपूर्ण है।",
                moongbeans_temp:"15-25° C",
                moongbeans_rain:"60-150 mm",
                moongbeans_stemp:"22-25° C",
                moongbeans_htemp:"28-30° C",

                rice:"चावल",
                rice_sd:"चावल गर्म मौसम की फसल है जो उष्णकटिबंधीय और उपोष्णकटिबंधीय क्षेत्रों में उगती है। चावल की खेती के लिए आदर्श तापमान सीमा 20°C और 35°C के बीच है। बढ़ते मौसम के दौरान चावल को प्रचुर मात्रा में धूप की आवश्यकता होती है, प्रति दिन 6 से 8 घंटे तक इष्टतम धूप का जोखिम होता है।",
                rice_wr:"चावल की खेती के लिए अच्छी जल-धारण क्षमता वाली अच्छी जल निकासी वाली उपजाऊ मिट्टी की आवश्यकता होती है। चावल के लिए आदर्श मिट्टी का पीएच 6.0 से 7.0 के बीच होता है, जिसमें थोड़ी अम्लीय मिट्टी को प्राथमिकता दी जाती है। चावल को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, जिसमें चिकनी दोमट, रेतीली दोमट और जलोढ़ मिट्टी शामिल हैं।",
                rice_cv:"भारत चावल की विभिन्न किस्मों का घर है, जिनमें से प्रत्येक की अपनी अनूठी विशेषताएं और विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्तता है। भारत में खेती की जाने वाली कुछ लोकप्रिय चावल किस्मों में बासमती, सांबा मसूरी, आईआर-8, स्वर्णा, शरबती और जया शामिल हैं।",
                rice_lp:"चावल की सफल खेती के लिए भूमि की उचित तैयारी महत्वपूर्ण है। इसमें जुताई, पोखर बनाना (जल भराव की स्थिति बनाना), समतल करना और मेड़ (उठाया हुआ तटबंध) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम कुशल जल प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण सुनिश्चित करते हैं",
                rice_si:"बीज का चयन चावल की खेती का एक महत्वपूर्ण पहलू है। किसानों को प्रतिष्ठित स्रोतों से उच्च गुणवत्ता वाले, प्रमाणित बीज का चयन करना चाहिए। उचित फफूंदनाशकों और कीटनाशकों से बीज उपचार करने से बीजों को बीमारियों और कीटों से बचाने में मदद मिल सकती है",
                rice_soi:"चावल को विभिन्न तरीकों से बोया जा सकता है, जिसमें सीधी बुआई (सूखी या गीली बुआई) या नर्सरी बेड से रोपाई करना शामिल है। बुआई विधि का चुनाव मिट्टी की स्थिति, पानी की उपलब्धता और श्रम की उपलब्धता जैसे कारकों पर निर्भर करता है।",
                rice_fu:"चावल एक पोषक तत्व की मांग करने वाली फसल है, और इष्टतम विकास और उपज के लिए उचित उर्वरक का उपयोग आवश्यक है। चावल की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस और पोटेशियम शामिल हैं। किसानों को मृदा परीक्षण कराना चाहिए और मृदा विश्लेषण परिणामों के आधार पर अनुशंसित उर्वरक आवेदन दरों का पालन करना चाहिए।",
                rice_id:"चावल एक अर्ध-जलीय फसल है जिसके बढ़ते मौसम के दौरान पानी की लगातार आपूर्ति की आवश्यकता होती है। चावल की खेती के लिए कुशल जल प्रबंधन महत्वपूर्ण है। वैकल्पिक रूप से गीला करना और सुखाना (एडब्ल्यूडी) जैसी तकनीकें पैदावार को बनाए रखते हुए पानी के उपयोग को अनुकूलित करने में मदद कर सकती हैं। जलभराव और संभावित फसल क्षति को रोकने के लिए उचित जल निकासी व्यवस्था भी होनी चाहिए।",
                rice_pd:"चावल विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिसका अगर प्रभावी ढंग से प्रबंधन नहीं किया गया तो पैदावार पर काफी असर पड़ सकता है। फसल की सुरक्षा के लिए कीटनाशकों, जैविक नियंत्रण विधियों और सांस्कृतिक प्रथाओं के विवेकपूर्ण उपयोग सहित एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                rice_hm:"अनाज की इष्टतम गुणवत्ता सुनिश्चित करने और नुकसान को कम करने के लिए समय पर कटाई आवश्यक है। खेती के पैमाने के आधार पर, चावल की कटाई मैन्युअल रूप से या विशेष कटाई मशीनरी का उपयोग करके की जा सकती है। काटे गए अनाज की गुणवत्ता बनाए रखने के लिए उचित सुखाने, थ्रेशिंग और भंडारण तकनीक महत्वपूर्ण हैं।",
                rice_temp:"15-25° C",
                rice_rain:"60-150 mm",
                rice_stemp:"22-25° C",
                rice_htemp:"28-30° C",

                kidneybeans:"राजमा",
                kidneybeans_wr:"राजमा गर्म मौसम की फसल है जो समशीतोष्ण से उपोष्णकटिबंधीय जलवायु में पनपती है। इनकी खेती के लिए आदर्श तापमान सीमा 20°C और 30°C के बीच है। उन्हें बढ़ते मौसम के दौरान लगभग 500-700 मिमी की अच्छी तरह से वितरित वर्षा की आवश्यकता होती है, परिपक्वता और कटाई के चरणों के दौरान शुष्क स्थिति होती है।",               
                kidneybeans_sd:"राजमा को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन वे 6.0 से 7.5 की थोड़ी अम्लीय से तटस्थ पीएच रेंज के साथ अच्छी तरह से सूखा, उपजाऊ मिट्टी पसंद करते हैं। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली दोमट मिट्टी उनकी खेती के लिए आदर्श होती है।",
                kidneybeans_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त राजमा की किस्मों की एक श्रृंखला उपलब्ध है। कुछ लोकप्रिय किस्मों में राजमा चित्रा, राजमा श्वेत, राजमा कस्तूरी, राजमा पहाड़ी और राजमा पंचमुखी शामिल हैं।",
                kidneybeans_lp:"राजमा की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें मिट्टी को तोड़ने और कार्बनिक पदार्थ को शामिल करने के लिए गहरी जुताई या जुताई शामिल है। जल निकासी और वातन में सुधार के लिए ऊंचे बिस्तर या मेड़ तैयार किए जा सकते हैं।",
                kidneybeans_si:"राजमा की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाले, रोग-मुक्त बीजों का चयन करना महत्वपूर्ण है। उचित कवकनाशी या कीटनाशकों के साथ बीज उपचार से अंकुरण और प्रारंभिक विकास चरणों के दौरान बीजों को कीटों और बीमारियों से बचाने में मदद मिल सकती है।",
                kidneybeans_soi:"राजमा को सीधे खेत में बोया जा सकता है या नर्सरी बेड में उगाया जा सकता है और फिर रोपाई की जा सकती है। अनुशंसित बुआई की गहराई 2-3 सेमी है, पंक्तियों के बीच 45-60 सेमी और पंक्ति में पौधों के बीच 10-15 सेमी का अंतर है।",
                kidneybeans_fu:"राजमा फलियां वाली फसलें हैं जो जड़ की गांठों के माध्यम से वायुमंडलीय नाइट्रोजन को स्थिर कर सकती हैं। हालाँकि, इष्टतम विकास और उपज के लिए उन्हें अभी भी फास्फोरस, पोटेशियम और अन्य आवश्यक पोषक तत्वों के पर्याप्त स्तर की आवश्यकता होती है। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                kidneybeans_id:"राजमा को नियमित सिंचाई की आवश्यकता होती है, विशेषकर फूल आने और फली बनने के चरण के दौरान। पानी का कुशल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या फ़रो सिंचाई विधियों को नियोजित किया जा सकता है।",
                kidneybeans_pd:"राजमा विभिन्न कीटों और बीमारियों के लिए अतिसंवेदनशील होते हैं, जिनमें बीन एफिड्स, पॉड बोरर्स, जंग और बैक्टीरियल ब्लाइट शामिल हैं। फसल की सुरक्षा के लिए फसल चक्र, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग जैसी एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                kidneybeans_hm:"राजमा की फलियों की कटाई आमतौर पर तब की जाती है जब फलियाँ पीली या सूखी हो जाती हैं और बीज अंदर खड़खड़ाने लगते हैं। खेती के पैमाने के आधार पर कटाई मैन्युअल या यंत्रवत् की जा सकती है। कटी हुई फलियों की गुणवत्ता और दीर्घायु बनाए रखने के लिए उचित सुखाने, थ्रेशिंग और भंडारण तकनीक महत्वपूर्ण हैं।",                
                kidneybeans_temp:"15-25° C",
                kidneybeans_rain:"60-150 mm",
                kidneybeans_stemp:"22-25° C",
                kidneybeans_htemp:"28-30° C",

                wheat:"गेहूं",
                wheat_wr:"गेहूँ ठंडे मौसम की फसल है जो समशीतोष्ण जलवायु में पनपती है। बढ़ते मौसम के दौरान गेहूं की खेती के लिए आदर्श तापमान सीमा 15°C और 25°C के बीच है। गेहूं को पर्याप्त धूप की आवश्यकता होती है, प्रति दिन 6 से 8 घंटे तक इष्टतम धूप होती है।",
                wheat_sd:"गेहूं को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, जिसमें दोमट, चिकनी दोमट और अच्छी जल निकासी वाली रेतीली दोमट मिट्टी शामिल है। गेहूं के लिए आदर्श मिट्टी का पीएच 6.0 से 7.5 के बीच है, थोड़ी क्षारीय मिट्टी को प्राथमिकता दी जाती है। अच्छी जल-धारण क्षमता वाली अच्छी जल निकास वाली मिट्टी गेहूं की खेती के लिए आदर्श होती है।",
                wheat_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त गेहूं की किस्मों की एक विविध श्रृंखला है। भारत में खेती की जाने वाली कुछ लोकप्रिय गेहूं किस्मों में एचडी 2967, पीबीडब्ल्यू 343, डीबीडब्ल्यू 17, एचआई 1544 और लोक 1 शामिल हैं।",
                wheat_lp:"गेहूं की सफल खेती के लिए भूमि की उचित तैयारी महत्वपूर्ण है। इसमें गहरी जुताई, हैरोइंग और समतलीकरण जैसी गतिविधियाँ शामिल हैं। ये कदम उचित बीज अंकुरण, जड़ विकास और खरपतवार नियंत्रण सुनिश्चित करते हैं।",
                wheat_si:"बीज का चयन गेहूं की खेती का एक महत्वपूर्ण पहलू है। किसानों को प्रतिष्ठित स्रोतों से उच्च गुणवत्ता वाले, प्रमाणित बीज का चयन करना चाहिए। उचित फफूंदनाशकों और कीटनाशकों से बीज उपचार करने से बीजों को बीमारियों और कीटों से बचाने में मदद मिल सकती है।",
                wheat_soi:"गेहूं को प्रसारण, ड्रिलिंग या डिबलिंग सहित विभिन्न तरीकों से बोया जा सकता है। अनुशंसित बुआई की गहराई 3-5 सेमी है, पंक्तियों के बीच 20-25 सेमी का अंतर है। बुआई का आदर्श समय क्षेत्र और जलवायु के आधार पर भिन्न-भिन्न होता है।",
                wheat_fu:"गेहूं एक पोषक तत्व की मांग करने वाली फसल है, और इष्टतम विकास और उपज के लिए उचित उर्वरक का उपयोग आवश्यक है। गेहूं की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस और पोटेशियम शामिल हैं। किसानों को मृदा परीक्षण कराना चाहिए और मृदा विश्लेषण परिणामों के आधार पर अनुशंसित उर्वरक आवेदन दरों का पालन करना चाहिए।",
                wheat_id:"गेहूं को उसके बढ़ते मौसम के दौरान पानी की निरंतर आपूर्ति की आवश्यकता होती है, विशेष रूप से कल्ले फूटने, सिर निकलने और दाना भरने के महत्वपूर्ण विकास चरणों के दौरान। कुशल सिंचाई तकनीकें, जैसे स्प्रिंकलर या ड्रिप सिंचाई, पैदावार को बनाए रखते हुए पानी के उपयोग को अनुकूलित करने में मदद कर सकती हैं।",
                wheat_pd:"गेहूं विभिन्न कीटों और बीमारियों, जैसे एफिड्स, जंग और पाउडरयुक्त फफूंदी के प्रति संवेदनशील है, जो अगर प्रभावी ढंग से प्रबंधित नहीं किया गया तो पैदावार पर काफी असर पड़ सकता है। फसल की सुरक्षा के लिए कीटनाशकों के विवेकपूर्ण उपयोग, जैविक नियंत्रण विधियों और सांस्कृतिक प्रथाओं सहित एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                wheat_hm:"अनाज की इष्टतम गुणवत्ता सुनिश्चित करने और नुकसान को कम करने के लिए समय पर कटाई आवश्यक है। खेती के पैमाने के आधार पर, गेहूं की कटाई मैन्युअल रूप से या विशेष कटाई मशीनरी का उपयोग करके की जा सकती है। काटे गए अनाज की गुणवत्ता बनाए रखने के लिए उचित सुखाने, थ्रेशिंग और भंडारण तकनीक महत्वपूर्ण हैं।",               
                wheat_temp:"15-25° C",
                wheat_rain:"60-150 mm",
                wheat_stemp:"22-25° C",
                wheat_htemp:"28-30° C",

                chickpeas:"चना",
                chickpeas_wr:"चना ठंडे मौसम की फसल है जो अर्ध-शुष्क से लेकर उपोष्णकटिबंधीय जलवायु में पनपती है। इनकी खेती के लिए आदर्श तापमान सीमा 15°C और 25°C के बीच है। उन्हें वनस्पति विकास चरण के दौरान ठंडी, शुष्क जलवायु और प्रजनन और परिपक्वता चरण के दौरान अपेक्षाकृत गर्म परिस्थितियों की आवश्यकता होती है।",
                chickpeas_sd:"चने को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन वे 7.0 से 8.5 की थोड़ी क्षारीय पीएच सीमा वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी पसंद करते हैं। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली बलुई दोमट या चिकनी दोमट मिट्टी उनकी खेती के लिए आदर्श होती है।",
                chickpeas_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त चने की कई किस्में मौजूद हैं। कुछ लोकप्रिय किस्मों में देसी (छोटे, गहरे रंग के बीज), काबुली (बड़े, हल्के रंग के बीज), जेजी 16, केपीजी 59 और विहार शामिल हैं।",
                chickpeas_lp:"चने की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें मिट्टी को तोड़ने और कार्बनिक पदार्थ को शामिल करने के लिए गहरी जुताई या जुताई शामिल है। जल निकासी और वातन में सुधार के लिए ऊंचे बिस्तर या मेड़ तैयार किए जा सकते हैं।",
                chickpeas_si:"चने की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाले, रोग-मुक्त बीजों का चयन करना महत्वपूर्ण है। उचित कवकनाशी या कीटनाशकों के साथ बीज उपचार से अंकुरण और प्रारंभिक विकास चरणों के दौरान बीजों को कीटों और बीमारियों से बचाने में मदद मिल सकती है।",
                chickpeas_soi:"चने को सीधे खेत में बोया जा सकता है या नर्सरी बेड में उगाया जा सकता है और फिर रोपाई की जा सकती है। अनुशंसित बुआई गहराई 5-10 सेमी है, पंक्तियों के बीच 30-45 सेमी और पंक्ति में पौधों के बीच 10-15 सेमी का अंतर है।",
                chickpeas_fu:"चना फलियां वाली फसलें हैं जो जड़ की गांठों के माध्यम से वायुमंडलीय नाइट्रोजन को स्थिर कर सकती हैं। हालाँकि, इष्टतम विकास और उपज के लिए उन्हें अभी भी फास्फोरस, पोटेशियम और अन्य आवश्यक पोषक तत्वों के पर्याप्त स्तर की आवश्यकता होती है। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                chickpeas_id:"चने को मध्यम सिंचाई की आवश्यकता होती है, विशेषकर फूल आने और फली बनने की अवस्था के दौरान। पानी का कुशल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या फ़रो सिंचाई विधियों को नियोजित किया जा सकता है।",
                chickpeas_pd:"चने विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें फली छेदक, कटवर्म, विल्ट और एस्कोकाइटा ब्लाइट शामिल हैं। फसल की सुरक्षा के लिए फसल चक्र, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग जैसी एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                chickpeas_hm:"चने की कटाई आमतौर पर तब की जाती है जब फलियाँ भूरी और सूखी हो जाती हैं, और बीज अंदर खड़खड़ाने लगते हैं। खेती के पैमाने के आधार पर कटाई मैन्युअल या यंत्रवत् की जा सकती है। काटे गए चने की गुणवत्ता और दीर्घायु बनाए रखने के लिए उचित सुखाने, थ्रेशिंग और भंडारण तकनीक महत्वपूर्ण हैं।",
                chickpeas_temp:"15-25° C",
                chickpeas_rain:"60-150 mm",
                chickpeas_stemp:"22-25° C",
                chickpeas_htemp:"28-30° C",

                banana:"केला",
                banana_wr:"केला एक उष्णकटिबंधीय फसल है जो गर्म और आर्द्र जलवायु में पनपती है। इनकी खेती के लिए आदर्श तापमान सीमा 20°C और 35°C के बीच है। उन्हें लगभग 1,000-2,000 मिमी की वार्षिक वर्षा की आवश्यकता होती है, जो पूरे वर्ष अच्छी तरह से वितरित होती है।",
                banana_sd:"केले को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन वे 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली उपजाऊ मिट्टी पसंद करते हैं। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली बलुई दोमट या चिकनी दोमट मिट्टी उनकी खेती के लिए आदर्श होती है",
                banana_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त केले की कई किस्में हैं। कुछ लोकप्रिय किस्मों में रोबस्टा, ग्रैंड नैने, रस्थली, नेंद्रन, ने पूवन और करपुरा चक्करकेली शामिल हैं।",                
                banana_lp:"केले की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें मिट्टी को तोड़ने और कार्बनिक पदार्थ को शामिल करने के लिए गहरी जुताई या जुताई शामिल है। जल निकासी और वातन में सुधार के लिए ऊंचे बिस्तर या टीले तैयार किए जा सकते हैं।",
                banana_si:"केले का प्रसार सकर्स या ऊतक-संवर्धित पौधों के माध्यम से किया जाता है। केले की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाली, रोग-मुक्त रोपण सामग्री का चयन करना महत्वपूर्ण है।",
                banana_soi:"केले के सकर्स या पौधे आमतौर पर 30-45 सेमी की गहराई पर गड्ढों या खाइयों में लगाए जाते हैं। पौधों के बीच अनुशंसित दूरी पंक्तियों में 1.5-2.5 मीटर है, पंक्तियों के बीच 2-3 मीटर है।",
                banana_fu:"केले भारी पोषक तत्व हैं और इष्टतम विकास और उपज के लिए पर्याप्त पोषक तत्वों की आवश्यकता होती है। केले की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस, पोटेशियम और कार्बनिक पदार्थ शामिल हैं। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                banana_id:"केले को नियमित सिंचाई की आवश्यकता होती है, विशेषकर शुष्क मौसम और फल विकास के चरणों के दौरान। पानी का कुशल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या फ़रो सिंचाई विधियों को नियोजित किया जा सकता है।",
                banana_pd:"केले विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें नेमाटोड, केला वीविल्स, सिगाटोका लीफ स्पॉट और पनामा रोग शामिल हैं। फसल की सुरक्षा के लिए फसल चक्र, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग जैसी एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                banana_hm:"केले की कटाई आम तौर पर तब की जाती है जब उंगलियां (व्यक्तिगत फल) अच्छी तरह से विकसित हो जाती हैं, और फलों का गुच्छा वांछित परिपक्वता चरण तक पहुंच जाता है। कटाई आमतौर पर तेज चाकू या दरांती से तने को काटकर मैन्युअल रूप से की जाती है। काटे गए केले की गुणवत्ता और शेल्फ-लाइफ को बनाए रखने के लिए उचित प्रबंधन और भंडारण तकनीक महत्वपूर्ण हैं।",               
                banana_temp:"15-25° C",
                banana_rain:"60-150 mm",
                banana_stemp:"22-25° C",
                banana_htemp:"28-30° C",

                blackgram:"उड़द",
                blackgram_wr:"उड़द एक गर्म मौसम की फसल है जो उष्णकटिबंधीय और उपोष्णकटिबंधीय जलवायु में पनपती है। इसकी खेती के लिए आदर्श तापमान सीमा 25°C और 35°C के बीच है। वानस्पतिक विकास चरण के दौरान इसे गर्म और आर्द्र जलवायु और परिपक्वता चरण के दौरान अपेक्षाकृत शुष्क परिस्थितियों की आवश्यकता होती है।",
                blackgram_sd:"उड़द की खेती विभिन्न प्रकार की मिट्टी में की जा सकती है, लेकिन यह 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी को पसंद करती है। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली बलुई दोमट या चिकनी दोमट मिट्टी इसकी खेती के लिए आदर्श होती है।",
                blackgram_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त उड़द की किस्मों की एक श्रृंखला उपलब्ध है। कुछ लोकप्रिय किस्मों में टी 9, पैंट यू 19, पैंट यू 31, पैंट यू 35 और कृष्णनाथ शामिल हैं।",
                blackgram_lp:"उड़द की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें मिट्टी को तोड़ने और कार्बनिक पदार्थ को शामिल करने के लिए गहरी जुताई या जुताई शामिल है। जल निकासी और वातन में सुधार के लिए ऊंचे बिस्तर या मेड़ तैयार किए जा सकते हैं।",
                blackgram_si:"उड़द की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाले, रोग-मुक्त बीजों का चयन करना महत्वपूर्ण है। उचित कवकनाशी या कीटनाशकों के साथ बीज उपचार से अंकुरण और प्रारंभिक विकास चरणों के दौरान बीजों को कीटों और बीमारियों से बचाने में मदद मिल सकती है।",
                blackgram_soi:"उड़द को सीधे खेत में बोया जा सकता है या नर्सरी बेड में उगाया जा सकता है और फिर रोपाई की जा सकती है। अनुशंसित बुआई की गहराई 3-5 सेमी है, पंक्तियों के बीच 30-45 सेमी और पंक्ति में पौधों के बीच 10-15 सेमी का अंतर है।",
                blackgram_fu:"उड़द एक फलीदार फसल है जो जड़ की गांठों के माध्यम से वायुमंडलीय नाइट्रोजन को स्थिर कर सकती है। हालाँकि, इष्टतम विकास और उपज के लिए अभी भी फास्फोरस, पोटेशियम और अन्य आवश्यक पोषक तत्वों के पर्याप्त स्तर की आवश्यकता होती है। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए",
                blackgram_id:"उड़द की फसल को मध्यम सिंचाई की आवश्यकता होती है, विशेषकर फूल आने और फली बनने की अवस्था के दौरान। पानी का कुशल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या फ़रो सिंचाई विधियों को नियोजित किया जा सकता है।",
                blackgram_pd:"ब्लैकग्राम विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें फली छेदक, एफिड्स, पीला मोज़ेक वायरस और पाउडरयुक्त फफूंदी शामिल हैं। फसल की सुरक्षा के लिए फसल चक्र, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग जैसी एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                blackgram_hm:"उड़द की कटाई आम तौर पर तब की जाती है जब फलियाँ भूरी और सूखी हो जाती हैं, और बीज अंदर खड़खड़ाने लगते हैं। खेती के पैमाने के आधार पर कटाई मैन्युअल या यंत्रवत् की जा सकती है। काटे गए उड़द की गुणवत्ता और दीर्घायु बनाए रखने के लिए उचित सुखाने, थ्रेशिंग और भंडारण तकनीक महत्वपूर्ण हैं।",
                blackgram_temp:"15-25° C",
                blackgram_rain:"60-150 mm",
                blackgram_stemp:"22-25° C",
                blackgram_htemp:"28-30° C",

                coconut:"नारियल",
                coconut_wr:"नारियल एक उष्णकटिबंधीय फसल है जो गर्म और आर्द्र जलवायु में पनपती है। इसकी खेती के लिए आदर्श तापमान सीमा 20°C और 35°C के बीच है। इसके लिए लगभग 1,000-2,000 मिमी की वार्षिक वर्षा की आवश्यकता होती है, जो पूरे वर्ष अच्छी तरह से वितरित होती है।",
                coconut_sd:"नारियल को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन यह 5.5 से 8.0 की पीएच रेंज वाली अच्छी जल निकासी वाली, रेतीली दोमट या लैटेराइट मिट्टी को पसंद करता है। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली मिट्टी नारियल की खेती के लिए आदर्श होती है।",
                coconut_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त नारियल की किस्मों की एक श्रृंखला है। कुछ लोकप्रिय किस्मों में ताली, वेस्ट कोस्ट टाल, ईस्ट कोस्ट टाल, लैकाडिव माइक्रो और लैकाडिव ऑर्डिनरी शामिल हैं।",
                coconut_lp:"नारियल की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें गहरी जुताई करना या उचित आकार के गड्ढे खोदना, कार्बनिक पदार्थ शामिल करना और पर्याप्त जल निकासी सुनिश्चित करना शामिल है।",
                coconut_si:"नारियल का प्रवर्धन बीज या पौध के माध्यम से किया जाता है। नारियल की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाली, रोग-मुक्त रोपण सामग्री का चयन करना महत्वपूर्ण है।",
                coconut_soi:"नारियल के बीज या पौधे आमतौर पर गड्ढों या खाइयों में 45-60 सेमी की गहराई पर लगाए जाते हैं। पौधों के बीच त्रिकोणीय या चौकोर पैटर्न में अनुशंसित दूरी 7-9 मीटर है।",
                coconut_fu:"नारियल एक भारी आहार है और इष्टतम विकास और उपज के लिए पर्याप्त पोषक तत्वों की आवश्यकता होती है। नारियल की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस, पोटेशियम और कार्बनिक पदार्थ शामिल हैं। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                coconut_id:"नारियल को नियमित सिंचाई की आवश्यकता होती है, विशेषकर शुष्क मौसम और फल विकास के चरणों के दौरान। कुशल जल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या बेसिन सिंचाई विधियों को नियोजित किया जा सकता है।",
                coconut_pd:"नारियल विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें घुन, गैंडा बीटल, कली सड़न और विल्ट रोग शामिल हैं। फसल की सुरक्षा के लिए फसल चक्र, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग जैसी एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों को लागू किया जाना चाहिए।",
                coconut_hm:"नारियल की कटाई आमतौर पर तब की जाती है जब फल पूरी तरह से परिपक्व हो जाते हैं और भूसी भूरे रंग की हो जाती है। कटाई आमतौर पर पेड़ों पर चढ़कर या विशेष कटाई उपकरणों का उपयोग करके मैन्युअल रूप से की जाती है। काटे गए नारियल की गुणवत्ता और शेल्फ-जीवन को बनाए रखने के लिए उचित प्रबंधन और भंडारण तकनीक महत्वपूर्ण हैं।",             
                coconut_temp:"15-25° C",
                coconut_rain:"60-150 mm",
                coconut_stemp:"22-25° C",
                coconut_htemp:"28-30° C",

                apple:"सेब",
                apple_wr:"सेब एक समशीतोष्ण फसल है जिसे सर्दियों के महीनों के दौरान एक अच्छी तरह से परिभाषित शीतलन अवधि की आवश्यकता होती है। बढ़ते मौसम के दौरान सेब की खेती के लिए आदर्श तापमान सीमा 15°C और 25°C के बीच है। पर्याप्त शीतकालीन ठंड (7 डिग्री सेल्सियस से नीचे) उचित सुप्तता और उसके बाद फूल आने और फल लगने के लिए महत्वपूर्ण है।",              
                apple_sd:"सेब विभिन्न प्रकार की मिट्टी में उगाए जा सकते हैं, लेकिन वे 5.5 से 6.5 पीएच रेंज वाली गहरी, अच्छी जल निकासी वाली और उपजाऊ मिट्टी में सबसे अच्छे से पनपते हैं। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली दोमट या रेतीली दोमट मिट्टी सेब की खेती के लिए आदर्श होती है।",
                apple_cv:"भारत में विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त सेब की किस्मों की एक श्रृंखला उपलब्ध है। कुछ लोकप्रिय किस्मों में रॉयल डिलीशियस, रेड डिलीशियस, गोल्डन डिलीशियस, अंबरी, लाल अंबरी और स्टार्किंग डिलीशियस शामिल हैं।",
                apple_lp:"सेब की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें गहरी जुताई करना या उचित आकार के गड्ढे खोदना, कार्बनिक पदार्थ शामिल करना और पर्याप्त जल निकासी सुनिश्चित करना शामिल है।",
                apple_si:"सेब को आम तौर पर ग्राफ्टेड पौधों या रूटस्टॉक्स के माध्यम से प्रचारित किया जाता है। सेब की अच्छी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाली, रोग-मुक्त रोपण सामग्री का चयन करना महत्वपूर्ण है।",
                apple_soi:"सेब के पौधे आमतौर पर गड्ढों या खाइयों में 30-45 सेमी की गहराई पर लगाए जाते हैं। पौधों के बीच अनुशंसित दूरी रूटस्टॉक और प्रशिक्षण प्रणाली के आधार पर भिन्न-भिन्न होती है, जो पंक्तियों के बीच 3-6 मीटर से लेकर पंक्ति के भीतर पौधों के बीच 2-4 मीटर तक होती है।",
                apple_fu:"सेब को इष्टतम विकास, फूल और फल उत्पादन के लिए पर्याप्त पोषक तत्वों की आवश्यकता होती है। सेब की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस, पोटेशियम और कार्बनिक पदार्थ शामिल हैं। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                apple_id:"सेब को नियमित सिंचाई की आवश्यकता होती है, विशेषकर फलों के विकास और वृद्धि के चरण के दौरान। कुशल जल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिंचाई विधियों को नियोजित किया जा सकता है।",
                apple_pd:"सेब विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होते हैं, जिनमें कोडिंग मोथ, सेब स्कैब, पाउडरयुक्त फफूंदी और अग्नि दोष शामिल हैं। फसल की सुरक्षा के लिए एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों, जैसे छंटाई, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग को लागू किया जाना चाहिए।",
                apple_hm:"आम तौर पर सेब की कटाई तब की जाती है जब वे वांछित आकार, रंग और परिपक्वता अवस्था तक पहुंच जाते हैं। कटाई आम तौर पर पेड़ों से फलों को सावधानीपूर्वक तोड़कर मैन्युअल रूप से की जाती है। काटे गए सेब की गुणवत्ता और शेल्फ-लाइफ को बनाए रखने के लिए उचित हैंडलिंग, ग्रेडिंग और भंडारण तकनीक महत्वपूर्ण हैं।",
                apple_temp:"15-25° C",
                apple_rain:"60-150 mm",
                apple_stemp:"22-25° C",
                apple_htemp:"28-30° C",

                coffee:"कॉफ़ी",
                coffee_wr:"कॉफ़ी एक उष्णकटिबंधीय फसल है जो गर्म और आर्द्र जलवायु में पनपती है। कॉफ़ी की खेती के लिए आदर्श तापमान सीमा 15°C और 28°C के बीच है। इसके लिए लगभग 1,500-2,500 मिमी की वार्षिक वर्षा की आवश्यकता होती है, जो पूरे वर्ष अच्छी तरह से वितरित होती है।",
                coffee_sd:"कॉफ़ी को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन यह 5.5 से 6.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी को पसंद करती है। अच्छी जल धारण क्षमता और पर्याप्त जल निकासी वाली दोमट या रेतीली दोमट मिट्टी कॉफी की खेती के लिए आदर्श होती है",
                coffee_cv:"भारत में कॉफ़ी की दो मुख्य किस्में हैं जो विभिन्न क्षेत्रों और बढ़ती परिस्थितियों के लिए उपयुक्त हैं: अरेबिका और रोबस्टा। केंट, एस.795 और कावेरी जैसी अरेबिका किस्में पहाड़ियों में उगाई जाती हैं, जबकि एस.274 और सीएक्सआर जैसी रोबस्टा किस्में मैदानी इलाकों में उगाई जाती हैं।",
                coffee_lp:"कॉफ़ी की सफल खेती के लिए भूमि की उचित तैयारी आवश्यक है। इसमें गहरी जुताई करना या उचित आकार के गड्ढे खोदना, कार्बनिक पदार्थ शामिल करना और पर्याप्त जल निकासी सुनिश्चित करना शामिल है। कॉफ़ी के पौधों को आंशिक छाया प्रदान करने के लिए छायादार पेड़ भी लगाए जा सकते हैं।",
                coffee_si:"कॉफ़ी का प्रचार बीज या अंकुर के माध्यम से किया जाता है। अच्छी कॉफ़ी फसल के लिए विश्वसनीय स्रोतों से उच्च गुणवत्ता वाली, रोग-मुक्त रोपण सामग्री का चयन करना महत्वपूर्ण है।",
                coffee_soi:"कॉफी के बीज या पौधे आमतौर पर 15-20 सेमी की गहराई पर गड्ढों या खाइयों में लगाए जाते हैं। पौधों के बीच अनुशंसित दूरी विविधता के आधार पर भिन्न-भिन्न होती है, पंक्तियों के बीच 1.5-2.5 मीटर और एक पंक्ति में पौधों के बीच 1-1.5 मीटर तक होती है।",
                coffee_fu:"कॉफ़ी के पौधों को इष्टतम विकास, फूल और फलियों के उत्पादन के लिए पर्याप्त पोषक तत्वों की आवश्यकता होती है। कॉफ़ी की खेती के लिए आवश्यक प्राथमिक पोषक तत्वों में नाइट्रोजन, फास्फोरस, पोटेशियम और कार्बनिक पदार्थ शामिल हैं। किसानों को मिट्टी का परीक्षण कराना चाहिए और सिफारिशों के आधार पर उचित उर्वरकों का प्रयोग करना चाहिए।",
                coffee_id:"कॉफ़ी को नियमित सिंचाई की आवश्यकता होती है, विशेषकर शुष्क मौसम और फलों के विकास के चरणों के दौरान। कुशल जल उपयोग सुनिश्चित करने और पानी की बर्बादी को कम करने के लिए ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिंचाई विधियों को नियोजित किया जा सकता है।",
                coffee_pd:"कॉफ़ी विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें कॉफ़ी बेरी बोरर, कॉफ़ी लीफ रस्ट और रूट-नॉट नेमाटोड शामिल हैं। फसल की सुरक्षा के लिए एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियों, जैसे छंटाई, प्रतिरोधी किस्मों का उपयोग, जैविक नियंत्रण एजेंटों और कीटनाशकों के विवेकपूर्ण उपयोग को लागू किया जाना चाहिए।",
                coffee_hm:"कॉफ़ी बीन्स की कटाई आमतौर पर तब की जाती है जब जामुन गहरे लाल रंग में बदल जाते हैं, जो पकने का संकेत देते हैं। पके हुए जामुनों को हाथ से चुनकर या बड़े पैमाने पर वृक्षारोपण के लिए यांत्रिक हार्वेस्टर का उपयोग करके कटाई मैन्युअल रूप से की जा सकती है। कटी हुई कॉफी बीन्स की गुणवत्ता और स्वाद को बनाए रखने के लिए उचित सुखाने, प्रसंस्करण और भंडारण तकनीक महत्वपूर्ण हैं।",
                coffee_temp:"15-25° C",
                coffee_rain:"60-150 mm",
                coffee_stemp:"22-25° C",
                coffee_htemp:"28-30° C",


                cotton:"कपास",
                cotton_wr:"कपास एक गर्म मौसम की फसल है जिसे भरपूर धूप और गर्म तापमान के साथ लंबे समय तक उगाने की आवश्यकता होती है। कपास की वृद्धि के लिए आदर्श तापमान सीमा 20°C से 30°C (68°F से 86°F) के बीच है। कपास पाले के प्रति संवेदनशील है और ठंडे तापमान से क्षतिग्रस्त या नष्ट हो सकती है। यह फल लगने और बीजकोष खुलने के चरण के दौरान कम आर्द्रता और न्यूनतम वर्षा वाले क्षेत्रों में पनपता है।",
                cotton_sd:"कपास 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी में सबसे अच्छी तरह उगती है। अच्छी जल धारण क्षमता वाली बलुई दोमट या दोमट मिट्टी कपास की खेती के लिए आदर्श होती है। मिट्टी को संकुचित करने से बचना चाहिए क्योंकि यह जड़ के विकास और पानी के प्रवेश में बाधा उत्पन्न कर सकता है।",
                cotton_cv:"कपास की किस्मों को मोटे तौर पर दो मुख्य प्रकारों में वर्गीकृत किया जा सकता है: अपलैंड कपास (गॉसिपियम हिर्सुटम) और अतिरिक्त-लंबे स्टेपल कपास (गॉसिपियम बारबाडेंस)। कुछ लोकप्रिय उपरी कपास किस्मों में डेल्टापाइन, स्टोनविले और फाइबरमैक्स शामिल हैं, जबकि पिमा और गीज़ा लोकप्रिय अतिरिक्त-लंबी स्टेपल किस्में हैं।",
                cotton_lp:"कपास की खेती के लिए भूमि की उचित तैयारी महत्वपूर्ण है। किसी भी कठोरता को तोड़ने और अच्छी मिट्टी का वातायन सुनिश्चित करने के लिए खेत की गहरी जुताई करनी चाहिए। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए। जल निकासी में सुधार और कुंड सिंचाई की सुविधा के लिए क्यारियाँ या मेड़ें बनाई जा सकती हैं।",
                cotton_si:"बुआई के लिए उच्च गुणवत्ता वाले, रोग प्रतिरोधी और प्रमाणित कपास के बीजों का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर भिन्न होती है, लेकिन आम तौर पर 10 से 15 किलोग्राम/हेक्टेयर (9 से 13 पाउंड/एकड़) तक होती है।",
                cotton_soi:"कपास आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोया जाता है जब मिट्टी का तापमान 18°C ​​(64°F) या इससे अधिक हो जाता है। बुआई प्रसारण, ड्रिलिंग या सटीक रोपण विधियों द्वारा की जा सकती है। अनुशंसित पंक्ति रिक्ति आमतौर पर 75 से 100 सेमी (30 से 40 इंच) के बीच होती है, और बीज की गहराई लगभग 2.5 से 5 सेमी (1 से 2 इंच) होनी चाहिए।",
                cotton_fu:"कपास एक भारी पोषक है और इष्टतम विकास और उपज के लिए पर्याप्त उर्वरक की आवश्यकता होती है। नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए। उर्वरकों के विभाजित प्रयोग की अक्सर सिफ़ारिश की जाती है।",
                cotton_id:"बढ़ते मौसम के दौरान कपास को लगातार नमी की आवश्यकता होती है, खासकर बीजकोष विकास चरण के दौरान। कपास की सिंचाई के लिए फ़रो, स्प्रिंकलर या ड्रिप सिंचाई प्रणाली का उपयोग किया जा सकता है। सिंचाई की आवृत्ति और मात्रा मिट्टी के प्रकार, जलवायु और फसल के विकास के चरण जैसे कारकों पर निर्भर करती है।",
                cotton_pd:"कपास विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें बॉलवर्म, एफिड्स, व्हाइटफ्लाइज़, कॉटन लीफ कर्ल वायरस और फ्यूजेरियम विल्ट शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                cotton_hm:"कपास की कटाई आमतौर पर कपास बीनने वालों या स्ट्रिपर्स का उपयोग करके यंत्रवत् की जाती है। कपास बीनने वाले पौधे से पूरे खुले हुए गूदे को हटा देते हैं, जबकि स्ट्रिपर्स गड़गड़ाहट से रोएं को हटा देते हैं, जिससे गड़गड़ाहट पौधे पर ही रह जाती है। अधिकतम उपज और फाइबर की गुणवत्ता सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है।",
                cotton_temp:"15-25° C",
                cotton_rain:"60-150 mm",
                cotton_stemp:"22-25° C",
                cotton_htemp:"28-30° C",

                grapes:"अंगूर",
                grapes_wr:"अंगूर बढ़ते मौसम के दौरान लंबे, धूप वाले दिनों और ठंडी रातों के साथ गर्म, शुष्क जलवायु में पनपते हैं। अंगूर की खेती के लिए आदर्श तापमान सीमा दिन के दौरान 20°C से 30°C (68°F से 86°F) और रात में 15°C से 20°C (59°F से 68°F) के बीच है। अंगूर को बढ़ते मौसम के दौरान कम से कम 1,500 से 2,000 घंटे की धूप और 500 से 800 मिमी (20 से 31 इंच) की वार्षिक वर्षा की आवश्यकता होती है।",
                grapes_sd:"अंगूर को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन वे 5.5 से 7.0 पीएच रेंज वाली अच्छी जल निकासी वाली, गहरी और उपजाऊ मिट्टी पसंद करते हैं। अच्छी जल धारण क्षमता वाली बलुई दोमट, दोमट या चिकनी दोमट मिट्टी अंगूर की खेती के लिए आदर्श होती है। खराब जल निकासी या उच्च लवणता वाली मिट्टी से बचना चाहिए।",
                grapes_cv:"विभिन्न वाइन शैलियों, टेबल अंगूर और किशमिश के लिए उपयुक्त अंगूर की कई किस्में हैं। कुछ लोकप्रिय वाइन अंगूर की किस्मों में कैबरनेट सॉविनन, मर्लोट, चार्डोनेय और रिस्लीन्ग शामिल हैं। टेबल अंगूर की किस्मों जैसे थॉम्पसन सीडलेस, क्रिमसन सीडलेस और रेड ग्लोब की खेती व्यापक रूप से ताजा खपत के लिए की जाती है।",
                grapes_lp:"अंगूर के बागों के लिए भूमि की तैयारी में किसी भी मौजूदा वनस्पति के क्षेत्र को साफ करना, स्टंप और चट्टानों को हटाना और यदि आवश्यक हो तो भूमि को समतल करना शामिल है। जलभराव को रोकने के लिए उचित जल निकासी प्रणालियाँ, जैसे खाई या टाइल वाली नालियाँ स्थापित की जा सकती हैं। बेलों को प्रशिक्षित करने के लिए ट्रेलाइज़िंग या सपोर्ट सिस्टम स्थापित किया जाना चाहिए।",
                grapes_si:"अंगूर को कटिंग के माध्यम से या रूटस्टॉक्स पर ग्राफ्ट करके प्रचारित किया जा सकता है। रूटस्टॉक्स को अक्सर कीटों, बीमारियों या मिट्टी की स्थितियों के प्रति उनके प्रतिरोध के लिए चुना जाता है। बेलों को रोग-मुक्त और सही किस्म का होना सुनिश्चित करने के लिए उच्च गुणवत्ता वाली, प्रमाणित रोपण सामग्री का उपयोग किया जाना चाहिए।",
                grapes_soi:"अंगूर को कटिंग के माध्यम से या रूटस्टॉक्स पर ग्राफ्ट करके प्रचारित किया जा सकता है। रूटस्टॉक्स को अक्सर कीटों, बीमारियों या मिट्टी की स्थितियों के प्रति उनके प्रतिरोध के लिए चुना जाता है। रोपण आम तौर पर शुरुआती वसंत में किया जाता है, जिसमें विविधता और प्रशिक्षण प्रणाली के आधार पर बेलों के बीच 1.5 से 3 मीटर (5 से 10 फीट) और पंक्तियों के बीच 2 से 3.5 मीटर (6.5 से 11.5 फीट) की दूरी होती है।",
                grapes_fu:"अंगूर की बेलों को इष्टतम विकास और उपज बनाए रखने के लिए संतुलित उर्वरक की आवश्यकता होती है। जिंक और बोरॉन जैसे सूक्ष्म पोषक तत्वों के साथ-साथ नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताओं को निर्धारित करने के लिए मृदा परीक्षण आयोजित किया जाना चाहिए।",
                grapes_id:"बढ़ते मौसम के दौरान अंगूर को लगातार नमी की आवश्यकता होती है, खासकर फलों के विकास और पकने के चरण के दौरान। ड्रिप सिंचाई या माइक्रो-स्प्रिंकलर सिस्टम का उपयोग आमतौर पर अंगूर की सिंचाई के लिए किया जाता है, क्योंकि वे सटीक जल वितरण प्रदान करते हैं और पानी की बर्बादी को कम करते हैं।",
                grapes_pd:"अंगूर की लताएँ विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होती हैं, जिनमें ख़स्ता फफूंदी, डाउनी फफूंदी, अंगूर फ़ाइलोक्सेरा और अंगूर बेरी कीट शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए",
                grapes_hm:"अंगूर की कटाई आमतौर पर हाथ से या यांत्रिक हार्वेस्टर की सहायता से की जाती है, जो उत्पादन के पैमाने और अंगूर (शराब, टेबल अंगूर, या किशमिश) के इच्छित उपयोग पर निर्भर करता है। फलों की सर्वोत्तम गुणवत्ता और स्वाद सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है।",
                grapes_temp:"15-25° C",
                grapes_rain:"60-150 mm",
                grapes_stemp:"22-25° C",
                grapes_htemp:"28-30° C",


                jute:"जूट",
                jute_wr:"जूट एक गर्म मौसम की फसल है जो गर्म और आर्द्र जलवायु परिस्थितियों में पनपती है। जूट की खेती के लिए आदर्श तापमान सीमा 24°C से 35°C (75°F से 95°F) के बीच है। जूट को बढ़ते मौसम के दौरान 1,200 से 1,500 मिमी (47 से 59 इंच) की अच्छी तरह से वितरित वार्षिक वर्षा की आवश्यकता होती है।",               
                jute_sd:"जूट 5.5 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ और दोमट मिट्टी में सबसे अच्छा बढ़ता है। मिट्टी कार्बनिक पदार्थों से भरपूर होनी चाहिए और उसकी जल धारण क्षमता अच्छी होनी चाहिए। भारी मिट्टी वाली मिट्टी या खराब उर्वरता वाली रेतीली मिट्टी से बचना चाहिए।",
                jute_cv:"जूट की दो मुख्य किस्में सफेद जूट (कोरकोरस कैप्सुलरिस) और टोसा जूट (कोरकोरस ऑलिटोरियस) हैं। सफेद जूट की कुछ लोकप्रिय किस्में जेआरसी-212, जेआरसी-321 और जेआरओ-524 हैं, जबकि लोकप्रिय टोसा जूट किस्मों में जेआरओ-878, जेआरओ-632 और जेआरओ-8432 शामिल हैं।",
                jute_lp:"जूट की खेती के लिए भूमि की उचित तैयारी महत्वपूर्ण है। किसी भी कठोरता को तोड़ने और अच्छी मिट्टी का वातायन सुनिश्चित करने के लिए खेत की गहरी जुताई करनी चाहिए। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए",
                jute_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोगमुक्त जूट के बीजों का उपयोग करना चाहिए। सफेद जूट के लिए अनुशंसित बीजारोपण दर 6-8 किग्रा/हेक्टेयर (5.4-7.1 पाउंड/एकड़) है, और टोसा जूट के लिए, यह 5-7 किग्रा/हेक्टेयर (4.5-6.2 पाउंड/एकड़) है।",
                jute_soi:"जूट आमतौर पर क्षेत्र के आधार पर मध्य मार्च से मध्य मई तक बोया जाता है। बीजों को 2-3 सेमी (0.8-1.2 इंच) की गहराई पर और पंक्तियों के बीच 20-25 सेमी (8-10 इंच) और पौधों के बीच 5-7 सेमी (2-3 इंच) की दूरी पर बोना चाहिए।",
                jute_fu:"जूट एक पोषक तत्व की मांग करने वाली फसल है और इसे संतुलित उर्वरक की आवश्यकता होती है। नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए। उर्वरकों के विभाजित प्रयोग की अक्सर सिफ़ारिश की जाती है।",
                jute_id:"जूट को बढ़ते मौसम के दौरान पर्याप्त नमी की आवश्यकता होती है, खासकर विकास के शुरुआती चरणों के दौरान। अपर्याप्त या अनियमित वर्षा वाले क्षेत्रों में सिंचाई आवश्यक हो सकती है। जूट की खेती के लिए आमतौर पर कुंड या बेसिन सिंचाई विधियों का उपयोग किया जाता है।",
                jute_pd:"जूट विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें तना सड़न, एन्थ्रेक्नोज और तना घुन शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                jute_hm:"जूट की कटाई आम तौर पर पौधों को जमीनी स्तर के करीब से काटकर की जाती है जब पौधे पूरी तरह खिल जाते हैं और पत्तियां गिरने लगती हैं। काटे गए पौधों को लकड़ी के तने से रेशे को अलग करने के लिए बंडल बनाकर (पानी में भिगोकर) रखा जाता है।",
                jute_temp:"15-25° C",
                jute_rain:"60-150 mm",
                jute_stemp:"22-25° C",
                jute_htemp:"28-30° C",


                lentil:"मसूर",
                lentil_wr:"मसूर दाल ठंडे मौसम की फसल है जो मध्यम तापमान में पनपती है। मसूर की खेती के लिए आदर्श तापमान सीमा 15°C से 25°C (59°F से 77°F) के बीच है। मसूर की फसलें वनस्पति अवस्था के दौरान पाले को सहन कर सकती हैं लेकिन फूल आने और फली भरने की अवस्था के दौरान उच्च तापमान और सूखे के प्रति संवेदनशील होती हैं।",
                lentil_sd:"6.0 से 8.0 पीएच रेंज वाली अच्छी जल निकासी वाली, उपजाऊ मिट्टी में दालें सबसे अच्छी उगती हैं। मसूर की खेती के लिए अच्छी जल धारण क्षमता वाली दोमट या बलुई दोमट मिट्टी को प्राथमिकता दी जाती है। दालें खारेपन और जलभराव की स्थिति के प्रति संवेदनशील होती हैं।",
                lentil_cv:"मसूर की कुछ लोकप्रिय किस्मों में मसूर (लाल मसूर), मल्का (भूरी मसूर), मोराद (हरी मसूर) और पूसा वैभव (बड़े बीज वाली किस्म) शामिल हैं। किस्म का चुनाव जलवायु, परिपक्वता अवधि और इच्छित उपयोग (साबुत या विभाजित दाल) जैसे कारकों पर निर्भर करता है।",
                lentil_lp:"मसूर की खेती के लिए भूमि की तैयारी में किसी भी कड़ी को तोड़ने और अच्छी मिट्टी का वातन सुनिश्चित करने के लिए गहरी जुताई शामिल है। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए।",
                lentil_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोग रहित मसूर के बीज का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर 30-50 किलोग्राम/हेक्टेयर (27-45 पाउंड/एकड़) के बीच भिन्न होती है।",
                lentil_soi:"जलवायु के आधार पर मसूर की बुआई देर से शरद ऋतु या शुरुआती वसंत में की जा सकती है। बीज को 2-4 सेमी (0.8-1.6 इंच) की गहराई पर और पंक्तियों के बीच 20-30 सेमी (8-12 इंच) की दूरी पर लगाया जाना चाहिए।",
                lentil_fu:"दालों को मध्यम पोषक तत्व की आवश्यकता होती है। नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए।",
                lentil_id:"बढ़ते मौसम के दौरान दाल को मध्यम नमी की आवश्यकता होती है, खासकर फूल आने और फली भरने के चरण के दौरान। अपर्याप्त या अनियमित वर्षा वाले क्षेत्रों में सिंचाई आवश्यक हो सकती है। मसूर की खेती के लिए फ़रो या स्प्रिंकलर सिंचाई विधियों का उपयोग किया जा सकता है।",
                lentil_pd:"मसूर की दाल विभिन्न कीटों और बीमारियों के प्रति संवेदनशील होती है, जिनमें एफिड्स, फली छेदक, जंग और विल्ट शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                lentil_hm:"मसूर की कटाई आमतौर पर तब की जाती है जब फलियाँ भूरी हो जाती हैं और पत्तियाँ सूखने लगती हैं। पौधों को कंबाइन हार्वेस्टर का उपयोग करके या तो मैन्युअल रूप से या यंत्रवत् काटा जाता है। मसूर की गुणवत्ता बनाए रखने के लिए कटी हुई फसल को उचित तरीके से सुखाना और गहाई करना महत्वपूर्ण है।",
                lentil_temp:"15-25° C",
                lentil_rain:"60-150 mm",
                lentil_stemp:"22-25° C",
                lentil_htemp:"28-30° C",


                maize:"मक्का",
                maize_wr:"मक्का एक गर्म मौसम की फसल है जिसे बढ़ते मौसम के दौरान गर्म तापमान और पर्याप्त नमी की आवश्यकता होती है। मक्के की खेती के लिए आदर्श तापमान सीमा 20°C से 30°C (68°F से 86°F) के बीच है। मक्के को 500 से 800 मिमी (20 से 31 इंच) की वार्षिक वर्षा की आवश्यकता होती है, जो बढ़ते मौसम के दौरान अच्छी तरह से वितरित होती है।",
                maize_sd:"मक्के को विभिन्न प्रकार की मिट्टी में उगाया जा सकता है, लेकिन यह 6.0 से 7.5 पीएच रेंज वाली अच्छी जल निकासी वाली उपजाऊ मिट्टी को पसंद करता है। अच्छी जल धारण क्षमता वाली दोमट या चिकनी दोमट मिट्टी मक्के की खेती के लिए आदर्श होती है। खराब जल निकासी या उच्च लवणता वाली मिट्टी से बचना चाहिए।",
                maize_cv:"मक्के की कई किस्में उपलब्ध हैं, जिनमें संकर और खुले परागण वाली किस्में शामिल हैं। कुछ लोकप्रिय संकर मक्के की किस्मों में डेकाल्ब, पायनियर और मोनसेंटो शामिल हैं, जबकि हिकॉरी किंग और रीड्स येलो डेंट जैसी खुले परागण वाली किस्मों की भी व्यापक रूप से खेती की जाती है।",
                maize_lp:"मक्के के लिए भूमि की तैयारी में किसी भी कठोर भाग को तोड़ने और अच्छी मिट्टी का वातन सुनिश्चित करने के लिए गहरी जुताई शामिल है। चिकनी बीज क्यारी बनाने के लिए डिस्किंग और हैरोइंग की जानी चाहिए। जल निकासी में सुधार और नाली सिंचाई की सुविधा के लिए ऊंचे बिस्तरों या मेड़ों का निर्माण किया जा सकता है।",
                maize_si:"बुआई के लिए उच्च गुणवत्ता वाले, प्रमाणित एवं रोग प्रतिरोधी मक्के के बीज का उपयोग करना चाहिए। अनुशंसित बीजारोपण दर विविधता और रोपण विधि के आधार पर भिन्न होती है, लेकिन आम तौर पर 15 से 25 किलोग्राम/हेक्टेयर (13 से 22 पाउंड/एकड़) तक होती है।",
                maize_soi:"मक्का आमतौर पर देर से वसंत या गर्मियों की शुरुआत में बोया जाता है जब मिट्टी का तापमान 15°C (59°F) या इससे अधिक हो जाता है। बुआई प्रसारण, ड्रिलिंग या सटीक रोपण विधियों द्वारा की जा सकती है। अनुशंसित पंक्ति रिक्ति आमतौर पर 60 से 90 सेमी (24 से 36 इंच) के बीच होती है, और बीज की गहराई लगभग 5 सेमी (2 इंच) होनी चाहिए।",
                maize_fu:"मक्का एक भारी आहार है और इष्टतम विकास और उपज के लिए पर्याप्त उर्वरक की आवश्यकता होती है। नाइट्रोजन, फास्फोरस और पोटेशियम आवश्यक प्राथमिक पोषक तत्व हैं। विशिष्ट उर्वरक आवश्यकताएँ मिट्टी की उर्वरता के स्तर पर निर्भर करती हैं और मिट्टी परीक्षण के माध्यम से निर्धारित की जानी चाहिए। उर्वरकों के विभाजित प्रयोग की अक्सर सिफ़ारिश की जाती है।",
                maize_id:"मक्के को बढ़ते मौसम के दौरान लगातार नमी की आवश्यकता होती है, विशेषकर रेशम बनने और दाना भरने के चरण के दौरान। मक्के की सिंचाई के लिए फ़रो, स्प्रिंकलर या ड्रिप सिंचाई प्रणाली का उपयोग किया जा सकता है। सिंचाई की आवृत्ति और मात्रा मिट्टी के प्रकार, जलवायु और फसल के विकास के चरण जैसे कारकों पर निर्भर करती है।",
                maize_pd:"मक्का विभिन्न कीटों और बीमारियों के प्रति संवेदनशील है, जिनमें तना छेदक, आर्मीवर्म, लीफ ब्लाइट और ईयर रोट शामिल हैं। फसल की सुरक्षा और टिकाऊ उत्पादन सुनिश्चित करने के लिए सांस्कृतिक, जैविक और रासायनिक नियंत्रण विधियों को शामिल करते हुए एकीकृत कीट प्रबंधन रणनीतियों को लागू किया जाना चाहिए।",
                maize_hm:"मक्के की कटाई आमतौर पर कंबाइन हार्वेस्टर का उपयोग करके यंत्रवत् की जाती है जब दाने पूरी तरह से परिपक्व और सूखे होते हैं। अधिकतम उपज और गुणवत्ता सुनिश्चित करने के लिए कटाई का उचित समय महत्वपूर्ण है। कुछ क्षेत्रों में, ताजा खपत या चारे के लिए मक्के की कटाई मैन्युअल रूप से की जा सकती है।",  
                maize_temp:"15-25° C",
                maize_rain:"60-150 mm",
                maize_stemp:"22-25° C",
                maize_htemp:"28-30° C",


                mango:"आम",
                mango_wr:"आम एक उष्णकटिबंधीय फल है जो गर्मी के मौसम में उगाई जाती है। आम की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। आम को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                mango_sd:"आम की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। आम की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                mango_cv:"भारत में विभिन्न प्रकार की आम की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में आम की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                mango_lp:"सफल आम की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                mango_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए आम के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                mango_soi:"आम को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। आम की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                mango_fu:"आम एक पोषक तत्व मांगने वाला फल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। आम की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                mango_id:"आम एक आधा-जलीय फल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। आम की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                mango_pd:"आम विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                mango_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। आम को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                mango_temp:"15-25° C",
                mango_rain:"60-150 mm",
                mango_stemp:"22-25° C",
                mango_htemp:"28-30° C",

                moongbeans:"मूंगफली",
                moongbeans_wr:"सफल मूंगफली की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                moongbeans_sd:"मूंगफली की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। मूंगफली की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                moongbeans_cv:"भारत में विभिन्न प्रकार की मूंगफली की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में मूंगफली की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                moongbeans_lp:"मूंगफली को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। मूंगफली की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                moongbeans_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए मूंगफली के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                moongbeans_soi:"मूंगफली एक पोषक तत्व मांगने वाला फल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। मूंगफली की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                moongbeans_fu:"मूंगफली एक आधा-जलीय फल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। मूंगफली की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                moongbeans_id:"मूंगफली एक उष्णकटिबंधीय फल है जो गर्मी के मौसम में उगाई जाती है। मूंगफली की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। मूंगफली को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                moongbeans_pd:"मूंगफली विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                moongbeans_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। मूंगफली को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                moongbeans_temp:"15-25° C",
                moongbeans_rain:"60-150 mm",
                moongbeans_stemp:"22-25° C",
                moongbeans_htemp:"28-30° C",

                mothbeans:"मोथबीन्स",
                mothbeans_wr:"सफल मोथबीन्स की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                mothbeans_sd:"मोथबीन्स की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। मोथबीन्स की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                mothbeans_cv:"भारत में विभिन्न प्रकार की मोथबीन्स की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में मोथबीन्स की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                mothbeans_lp:"मोथबीन्स को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। मोथबीन्स की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                mothbeans_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए मोथबीन्स के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                mothbeans_soi:"मोथबीन्स एक पोषक तत्व मांगने वाला फल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। मोथबीन्स की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                mothbeans_fu:"मोथबीन्स एक आधा-जलीय फल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। मोथबीन्स की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                mothbeans_id:"मोथबीन्स एक उष्णकटिबंधीय फल है जो गर्मी के मौसम में उगाई जाती है। मोथबीन्स की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। मोथबीन्स को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                mothbeans_pd:"मोथबीन्स विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                mothbeans_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। मोथबीन्स को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                mothbeans_temp:"15-25° C",
                mothbeans_rain:"60-150 mm",
                mothbeans_stemp:"22-25° C",
                mothbeans_htemp:"28-30° C",

                muskmelon:"मस्कमेलन",
                muskmelon_wr:"सफल मस्कमेलन की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                muskmelon_sd:"मस्कमेलन की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। मस्कमेलन की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                muskmelon_cv:"भारत में विभिन्न प्रकार की मस्कमेलन की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में मस्कमेलन की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                muskmelon_lp:"मस्कमेलन को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। मस्कमेलन की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                muskmelon_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए मस्कमेलन के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                muskmelon_soi:"मस्कमेलन एक पोषक तत्व मांगने वाला फल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। मस्कमेलन की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                muskmelon_fu:"मस्कमेलन एक आधा-जलीय फल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। मस्कमेलन की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                muskmelon_id:"मस्कमेलन एक उष्णकटिबंधीय फल है जो गर्मी के मौसम में उगाई जाती है। मस्कमेलन की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। मस्कमेलन को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                muskmelon_pd:"मस्कमेलन विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                muskmelon_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। मस्कमेलन को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                muskmelon_temp:"15-25° C",
                muskmelon_rain:"60-150 mm",
                muskmelon_stemp:"22-25° C",
                muskmelon_htemp:"28-30° C",


                mustard:"सरसों",
                papaya_wr:"सफल पपीता की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                papaya_sd:"पपीता की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। पपीता की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                papaya_cv:"भारत में विभिन्न प्रकार की पपीता की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में पपीता की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                papaya_lp:"पपीता को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। पपीता की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                papaya_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए पपीता के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                papaya_soi:"पपीता एक पोषक तत्व मांगने वाला फल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। पपीता की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                papaya_fu:"पपीता एक आधा-जलीय फल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। पपीता की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                papaya_id:"पपीता एक उष्णकटिबंधीय फल है जो गर्मी के मौसम में उगाई जाती है। पपीता की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। पपीता को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                papaya_pd:"पपीता विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                papaya_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। पपीता को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                papaya_temp:"15-25° C",
                papaya_rain:"60-150 mm",
                papaya_stemp:"22-25° C",
                papaya_htemp:"28-30° C",

                pigeonpeas:"अरहर",
                pigeonpeas_wr:"सफल अरहर की खेती के लिए उचित भूमि की तैयारी महत्वपूर्ण है। इसमें हल करना, पुद्दलिंग (जल-भराव अवस्था बनाना), समतलीकरण और बंड (ऊंची डीवार) निर्माण जैसी गतिविधियाँ शामिल हैं। ये कदम सुनिश्चित करते हैं कि पानी का प्रबंधन, खरपतवार नियंत्रण और उचित बीज अंकुरण हो।",
                pigeonpeas_sd:"अरहर की खेती के लिए अच्छी ड्रेन होने वाली, उर्वर और अच्छी पानी धारण क्षमता वाली मिट्टी की आवश्यकता होती है। अरहर की उचित वृद्धि के लिए अच्छी द्रव्यमान धारण क्षमता वाली मिट्टी का चयन करना चाहिए।",
                pigeonpeas_cv:"भारत में विभिन्न प्रकार की अरहर की खेती की जाती है, जिनमें विभिन्न विशेषताएँ और विभिन्न क्षेत्रों और उगाने की स्थितियों के लिए उपयुक्तता होती है। भारत में अरहर की कुछ लोकप्रिय विविधताएँ शामिल हैं।",
                pigeonpeas_lp:"अरहर को विभिन्न प्रकार की मिट्टियों में उगाया जा सकता है, जिनमें लोमी, रेतीलोम और सर्वप्रयोजनीय मिट्टियां शामिल हैं। अरहर की उचित वृद्धि के लिए उचित मिट्टी का चयन करना चाहिए।",
                pigeonpeas_si:"मैं एक वेबपेज बना रहा हूं जिसमें किसानों के लिए अरहर के बारे में सारी जानकारी होगी। कृपया मुझे वह सामग्री दें जिसमें मौसम की आवश्यकताएं, मिट्टी का विवरण, फसल की किस्में, भूमि की तैयारी, बीज की जानकारी, बुआई की जानकारी, उर्वरक का उपयोग, सिंचाई का विवरण, कीट और रोग प्रबंधन, फसल की विधि शामिल होनी चाहिए।",
                pigeonpeas_soi:"अरहर एक पोषक तत्व मांगने वाली फसल है, और उचित उर्वर लागत उचित वृद्धि और उत्पादन के लिए आवश्यक है। अरहर की खेती के लिए मुख्य पोषक तत्व नाइट्रोजन, फास्फोरस और पोटैशियम शामिल हैं।",
                pigeonpeas_fu:"अरहर एक आधा-जलीय फसल है जिसे अपने वृद्धि के मौसम में निरंतर पानी की आपूर्ति की आवश्यकता होती है। अरहर की खेती के लिए कुशल पानी प्रबंधन महत्वपूर्ण है।",
                pigeonpeas_id:"अरहर एक उष्णकटिबंधीय फसल है जो गर्मी के मौसम में उगाई जाती है। अरहर की उचित वृद्धि के लिए आदर्श तापमान सीमा 24°C से 30°C के बीच होती है। अरहर को अच्छी वृद्धि के लिए अच्छी दिन की रोशनी और उचित वायुमंडल की आवश्यकता होती है।",
                pigeonpeas_pd:"अरहर विभिन्न कीटों और रोगों के प्रति संवेदनशील है, जो यदि प्रभावी ढंग से प्रबंधित नहीं किए गए हैं तो उत्पादन पर बड़ा प्रभाव डाल सकते हैं। एकीकृत कीट प्रबंधन (आईपीएम) रणनीतियाँ, जिसमें कीटनाशकों का युक्तिसंगत उपयोग, जैविक नियंत्रण विधियाँ और सांस्कृतिक प्रथाओं का उपयोग शामिल है, फसल की सुरक्षा के लिए लागू किया जाना चाहिए।",
                pigeonpeas_hm:"समय पर फसल काटना महत्वपूर्ण है ताकि उत्पाद की गुणवत्ता को आदर्श बनाया जा सके और हानियों को कम किया जा सके। अरहर को मैन्युअल रूप से काटा जा सकता है या विशेषज्ञ कटाई मशीन का उपयोग किया जा सकता है, जो उगाने के मात्र के आधार पर निर्भर करता है। उचित सुखाने, अलगाव और भंडारण तकनीकें उगाई गई अनाज की गुणवत्ता को बनाए रखने के लिए महत्वपूर्ण हैं।",
                pigeonpeas_temp:"15-25° C",
                pigeonpeas_rain:"60-150 mm",
                pigeonpeas_stemp:"22-25° C",
                pigeonpeas_htemp:"28-30° C",
                
                crop_info:"फसल",
                rd:"🌾 नम्र अनाज जो राष्ट्रों को खिलाता है। हरे-भरे धान पोषण के सुनहरे खजाने का मार्ग प्रशस्त करते हैं।",
md:"🌽सुनहरी फसल। लम्बे हरे डंठलों में पौष्टिक, मीठी गुठलियों से भरे प्रतिष्ठित भुट्टे होते हैं।",
wd:"🌾जीवन की एक प्राचीन लाठी। लहराते एम्बर के क्षेत्र रोटियों की ओर ले जाते हैं जो दुनिया भर में मेजों की शोभा बढ़ाते हैं।",
kbd:"🫘 प्रकृति की माणिक उपज देने वाली जोरदार लताएँ। प्रत्येक फली प्रोटीन से भरे रत्नों के भंडार को आश्रय देती है।",
mmd:"🍈 अमृतमय आनंद की सुगंधित मालाएँ। जालीदार छिलके भीतर के सुस्वाद, शहद-मीठे हृदय को गले लगाते हैं।",
jd:"🌿बहुमुखी रेशों का भंडार। कोमल पत्तियाँ मजबूत कपड़े और साज-सामान में बदल जाती हैं।",
cofd:"☕ जागृति का खिलता हुआ काढ़ा। इन छोटे जामुनों से एक अमृत निकलता है जो दुनिया को गुलजार रखता है।",
cpd:"🌰 विनम्र पृथ्वी डली। ये सुनहरे बीज पोषण का भंडार रखते हैं जो सदियों से भूख को संतुष्ट करता है।",
bad:"🍌प्रकृति का मधुर उपहार। पीछे छीलने से पोषक पीले रंग का प्रचुरता का पता चलता है जो सभी उम्र के स्वाद कलियों को प्रसन्न करता है।",
bgd:"🌱विनम्र बीज एक शक्तिशाली पंच पैक करते हैं। प्रोटीन और महत्वपूर्ण पोषक तत्वों से भरपूर छोटे काले मोती।",
cod:"🥥 द्वीप की जादुई पेशकश। प्रत्येक भाग - फल, पत्ते, मिट्टी - इस उदार वृक्ष का एक उपहार है।",
ad:"🍎गर्व बाग का खजाना। एक गुलाबी या पन्ना ब्लश इस कुरकुरे, मीठे आनंद को सुशोभित करता है जो किसी भी व्यंजन को बढ़ा देता है।",
cotd:"💐रेशमी धागों की फसल। सफेद फूल कपड़ा उद्योग की नींव बनाने वाले रेशों को जन्म देते हैं।",
od:"🍊 एक तीखा विटामिन भंडार। धूप से चूमे हुए ये गोले संपूर्ण अच्छाई के साथ उत्साह का मेल कराते हैं।",
ld:"🥜 छोटे प्रोटीन से भरे मोती। ये साधारण दाल के बीज शक्ति और जीवन शक्ति को संग्रहित करते हैं।",
mad:"🥭 ग्रीष्म ऋतु का शाही स्वागत। इस मांसल गोले से रसदार सुगंध आती है - मौसम का पहला आनंद।",
mbd:"🥜 हरी सीपियों में छिपा हुआ पोषण। ये हरी-भरी फलियाँ स्वादिष्ट और शक्तिवर्धक दोनों प्रकार के व्यंजन बनाती हैं।",
mtbd:"🌰 भोजन का प्रकृति से सीधा संबंध। ये देहाती बीज उपजाऊ खेतों की मेहनत को दर्शाते हैं।",
gd:"🍇अंगूर के बागों की रानी। हरे मोतियों के समूह धीरे-धीरे पककर रसदार मिठास वाले रूबी रत्नों में बदल जाते हैं।",
pd:"🍈 गर्मी भरे दिनों का आनंद लें। इन प्रचुर फलों के भीतर स्फूर्ति के बीज फूटते हैं।",
ppd:"🌰 एक विनम्र नाड़ी खजाना। ये खूबसूरत सुनहरी डली प्राचीन पाक परंपराओं को जीवित रखती हैं।",
pgd:"🍇 माणिक मिठास का विस्फोट। सैकड़ों चमकदार दाने विटामिन और फाइबर से भरपूर होते हैं।",
wmd:"🍉ग्रीष्म ऋतु का प्यास बुझाने वाला रत्न। एक हरे छिलके के भीतर एक शानदार गुलाबी जल-तिजोरी छिपी हुई है।"

            },  
        },
        ta:{
            translation:{
                sq5:"உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வைப் பெறுங்கள்",
                sa5:"உங்கள் பயிருக்கு சரியான பரிந்துரையைப் பெறுங்கள்",
                s3_1:"பருவத்தின் அடிப்படையில் பயிர் வளர்ப்பு",
                step3:"பயிர் பரிந்துரை",
                s3_2:"மண்ணின் ஆரோக்கியத்தின் அடிப்படையில் விளைச்சலை எவ்வாறு அதிகரிப்பது என்பதற்கான குறிப்புகள்",
                s3_3:"உகந்த விளைச்சல்",
                s3_4:"மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வைப் பெறுங்கள்",
                s3_5:"உங்கள் பயிர் தேர்வை மேம்படுத்தவும்",
                sq6:"உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வைப் பெறுங்கள்",
                sa6:"உங்கள் பயிருக்கு சரியான பரிந்துரையைப் பெறுங்கள்",
                click_here:"இங்கே கிளிக் செய்க",
                
                for_more_info_click_here:"மேலும் தகவலுக்கு இங்கே கிளிக் செய்க",
                welcome_crops_guide : 'வரவேற்கின்ற பயிர்கள் வழிகாட்டி',
                description_crops_guide : 'உங்கள் பயிர் தேர்வை மேம்படுத்தவும்',

                greeting : 'உங்கள் பயிர் தேர்வை மேம்படுத்தவும்',
                sub_greeting : 'தரவு உந்துதல் நுண்ணறிவு மூலம் உங்கள் விளைச்சலை அதிகரிக்கவும்',
                sub_greeting_btn1 : 'தொடங்கு',
                sub_greeting_btn2 : 'மற்றும் அதிக ஜானெம்',
                features:'அம்சங்கள்',
                features_heading : "தரவு உந்துதல் நுண்ணறிவுடன் விவசாயிகளை மேம்படுத்துதல்",
                features_heading_sub : "ஒரு வெற்றிகரமான அறுவடைக்கு தகவலறிந்த முடிவுகளை எடுங்கள்",
                home : "முக்கிய பக்கம்",
                about:"எங்களை பற்றி",
                guide:"வழிகாட்டி",
                login:"உள்நுழைய",
                logout:"வ",
                en:"ஆங்கிலம்",
                hi:"ஹிந்தி",
                ta:"தமிழ்",
                pridiction:"கணிப்பு",
                soil_analysis:"மண் பகுப்பாய்வு",
                soil_analysis_guidance:"உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வைப் பெறுங்கள்",
                crop_recommendation:"பயிர் பரிந்துரை",
                crop_recommendation_guidance:"உங்கள் பயிருக்கு சரியான பரிந்துரையைப் பெறுங்கள்",
                seasonal_crop_recommendation:"பயிர் பரிந்துரை",
                seasonal_crop_recommendation_guidance:"பருவத்தின் அடிப்படையில் பயிர் வளர்ப்பு",
                optimal_crops:"உகந்த விளைச்சல்",
                optimal_crops_guidance:"மண்ணின் ஆரோக்கியத்தின் அடிப்படையில் விளைச்சலை எவ்வாறு அதிகரிப்பது என்பதற்கான குறிப்புகள்",
                home_page_empower: "ஸ்மார்ட் பயிர் பரிந்துரைகள் மூலம் விவசாயிகளுக்கு அதிகாரமளித்தல்",
                home_page_empower_sub:"எங்கள் தளம் மண் நிலைகளை பகுப்பாய்வு செய்வதன் மூலம் வகையற்ற பயிர் பரிந்துரைகளை வழங்குகிறது, விவசாயிகள்",
                common_question:"கேள்விகள்",
                comm_here_Are:"இது எங்கள் தளத்தைப் பற்றிய பொது கேள்விகள்",
                q1:"தளம் எந்த பயிர்களை பரிந்துரைக்க வேண்டும் என்பதை எப்படி தீர்மானிக்கிறது", 
                a1:"எங்கள் தளம் வேலை செய்கிற உயர்த்தப்பட்ட ஆல்கரிதங்கள் மண் நிலையை பகுப்பாய்வு செய்கிறது மற்றும் பல காரணங்களைப் பெற்று அதிகமான பயிர்களை பரிந்துரைக்கிறது",
                q2:"பயிர் பரிந்துரைகள் எவ்வாறு சரியானவை",
                a3:"எங்கள் பரிந்துரைகள் பரிந்துரைகள் பல ஆராய்ச்சியின் மூலம் மற்றும் தரவு பகுப்பாய்வத்தின் மூலம் அதிகமான சரியானவை மற்றும் நம்பகமானவை ஆகும்",
                a2:"எங்கள் பரிந்துரைகள் பல ஆராய்ச்சியின் மூலம் மற்றும் தரவு பகுப்பாய்வத்தின் மூலம் அதிகமான சரியானவை மற்றும் நம்பகமானவை ஆகும்",   
                q3:"தளம் இலவசமாக பயன்படுத்தலாமா?",
                welcome_back:"மீண்டும் வரவேற்கிறோம்",
                please_sign_in_to_continue:"தொடர உள்நுழைக",
                email:"மின்னஞ்சல்",
                password:"கடவுச்சொல்",
                Dont_have_an_account:"கணக்கு இல்லை?",
                signup:"பதிவு செய்க",
                Something_went_wrong:"ஏதோ தவறு ஆனது",
                create_account:"கணக்கை உருவாக்கு",
                please_sign_in_to_continue:"தொடர உள்நுழைக",
                already_have_an_account:"ஏதோ ஒரு கணக்கு உள்ளதா?",
                full_name:"முழு பெயர்",
                confirm_password:"கடவுச்சொல்லை உறுதிசெய்க",
                our_project_about:"எங்கள் திட்டம் காலாந்தர மாசாந்தியத்தின் விளைவுகளை எதிர்க்கும் விவசாயத்தில் ஒரு பயிர் பரிந்துரை அமைப்பை உருவாக்குகிறது. இயந்திர கற்றலைப் பயன்படுத்தி, வானிலை மற்றும் மண் நிலைகளின் அடிப்படையில் பயிர் தேர்வை மேம்படுத்துவதை லக்ஷித்து உத்பத்தியை அதிகரிக்க உள்ளது.",
                thrive:"நல்ல நாளாக வளரும்",
                greeting_guide:"வழிகாட்டி பகுதிக்கு வரவேற்கிறோம்",
                greeting_guide_sub:"இங்கே உங்கள் தளத்தை எப்படி பயன்படுத்துவது மற்றும் அதன் அம்சங்களை அதிகப்படுத்துவது பற்றிய விரிவான தகவல்களைக் காணலாம்",
                
                nitrogen:"நைட்ரஜன் (என்)",
                nitrogen_des:"நைட்ரஜன் வளர்ச்சி மற்றும் வளர்ச்சிக்கு தேவையான ஒரு முக்கிய உணவு உற்பத்தி தத்துவமாகும். இது படைப்பானத்தை, புரோட்டீன் சேர்த்தலை மற்றும் மொத்த உணவு உற்பத்தியில் முக்கிய பங்கு வஹிக்கும். நைட்ரஜன் குறைபாடு வளர்ச்சியில் தடுமாறு, இலைகளின் மஞ்சளானது மற்றும் உத்பத்தியில் குறைபடுவது சார்ந்ததாகலாம்.",
                phosphorus:"வளர்ச்சி  (பி)",
                phosphorus_des:"முக்கிய வளர்ச்சியை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது உணவு உற்பத்தி உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வைப் பெறுங்கள் தத்துவமாகும். இது படைப்பானத்தை",
                potassium:"காசியம்",
                potassium_des:"காசியம் நீர் படுத்தல், செயல்திறன் மற்றும்",
                temperature:"ளர்ச்",
                temperature_des:"வளர்ச்சி படைப்பானத்தை மற்றும் வளர்ச்சியை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது.",
                humidity:"ஈர்ப்பு (%)",
                humidity_des:"நீர் படுத்தல் படைப்பானத்தை மற்றும் நீர் படுத்தலை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது.",
                soil_ph:"மண் பி",
                soil_ph_des:"மண் பி மண் பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது.",
                rainfall:"மழை (மிமி)",
                rainfall_des:"மழை மழை படைப்பானத்தை மற்றும் மழையை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது.",
                ques_parameter:"உங்கள் பயிருக்கு இந்த பகுப்பாய்வுகளை எப்படி கண்டறியுவது?",

                step1: "படி 1: உங்கள் மண் ஆரோக்கிய அட்டையை பெறுகின்றோம்.",
                sq1: "மண் ஆரோக்கிய அட்டை என்றால் என்ன?",
                sa1: "மண் ஆரோக்கிய அட்டை மண் ஆரோக்கியத்தின் தற்போதைய நிலையை மதிப்பிடுவதும், நேரம் வரை இது உள்ள புவி நிர்வாக மூலம் பாதிக்கப்பட்ட மண் ஆரோக்கியத்தில் மாற்றங்களை நிர்ணயிக்கும் போது அந்தத் தகவலை மதிப்பிடுவதும். மண் ஆரோக்கிய அட்டை மண் ஆரோக்கிய சூசகங்கள் மற்றும் உத்தியுடைய விளக்கமான வார்த்தைகளைக் காட்டுகின்றது.",
                sa1_1: "மண் ஆரோக்கிய அட்டை (எஸ்.எச்.சி) இந்திய அரசின் ஒரு திட்டம் ஆகும் என்பது விவசாய மற்றும் விவசாயத்திற்கான மத்திய பயிற்சி மற்றும் சஹகரம் அமைப்புகளின் மூலம் வளர்க்கப்படுகின்றது.",
                sq2: "மண் ஆரோக்கிய அட்டை என்றால் என்னவென்று என்ன?",
                sa2: "அந்தப் பூமியின் நிலையை 12 பேராகக் காட்டப்படும், அத்துடன் N, P, K (மேக்ரோ- உடல் உட்பொருள்கள்); S (எதிர்கால்- உட்பொருள்); Zn, Fe, Cu, Mn, Bo (மைக்ரோ - உடல் உட்பொருள்கள்); மற்றும் pH, EC, OC (உற்பத்தியியல் பெருமானங்கள்) என்றும் 12 பேராக காட்டப்படும். இந்தத் தகவலை அடிப்படையாகக் கொண்டு, எங்கும் பயிற்சிக்கும் பகுதியில் நாம் எந்த பயிற்சியை மாற்ற வேண்டும் என்பதை எங்கள் மாதிரியின் உத்தியை அறியும்.",
                step2: "படி 2: நம்பகம் செய்யும்",
                step2_des: "உங்கள் மண் ஆரோக்கிய அட்டையை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது",
                sq3: "நம்பகம் செய்யும் என்றால் என்ன?",
                sa3: "நம்பகம் செய்யும் என்றால் உங்கள் மண் ஆரோக்கிய அட்டையை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது. இது உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வுக்கு உத்தியாகும்.",
                sq4: "நம்பகம் செய்யும் என்றால் என்னவென்று என்ன?",
                sa4: "நம்பகம் செய்யும் என்றால் உங்கள் மண் ஆரோக்கிய அட்டையை பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை அதிகரிக்க உள்ளது. இது உங்கள் மண்ணின் கலவை பற்றிய விரிவான பகுப்பாய்வுக்கு உத்தியாகும்.",
                logo:"குரோபிசி",
                nitrogen_p: "நைட்ரஜன் - மண் உள்ளிட்ட நைட்ரஜன் உள்ளிட்ட நிர்வாகம்",
                phosphorus_p: "நம்பகம் செய்யும் என்றால் உங்கள் மண் ஆரோக்கிய",
                potassium_p: "என்றால் உங்கள் மண் ஆரோக்கிய",
                temperature_p: "பகுப்பாய்வு செய்வதன் மூலம் உத்பத்தியை",
                humidity_p: "உற்பத்தியான நம்பிக்கை - சதவீதம் வாயுநிலை",
                ph_p: ".எச்.சி) இந்திய அரசின் ஒரு",
                rainfall_p: "மழை - மிமிக்கில் மழை",
                predict:"கணிப்பு",
                Temperature:"கணிப்பு",
                Rainfall:"மழைப்பொழிவு",
                STemp:"அறுவடை வெப்பநிலை",
                HTemp:"அறுவடை வெப்பநிலை",

                rice:"அரிசி",
                rice_wr:"நெல் வெப்பமண்டல மற்றும் மிதவெப்ப மண்டல பகுதிகளில் செழித்து வளரும் ஒரு சூடான பருவ பயிர். நெல் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 35°C வரை இருக்கும். வளரும் பருவத்தில் அரிசிக்கு ஏராளமான சூரிய ஒளி தேவைப்படுகிறது, உகந்த சூரிய ஒளி வெளிப்பாடு ஒரு நாளைக்கு 6 முதல் 8 மணி நேரம் வரை இருக்கும்.",               
                rice_sd:"நெல் சாகுபடிக்கு நல்ல வடிகால் வசதியுள்ள, நல்ல நீர் தேங்கும் திறன் கொண்ட வளமான மண் தேவைப்படுகிறது. அரிசிக்கு ஏற்ற மண்ணின் pH 6.0 முதல் 7.0 வரை இருக்கும், சற்று அமிலத்தன்மை கொண்ட மண் விரும்பப்படுகிறது. களிமண் களிமண், மணல் களிமண் மற்றும் வண்டல் மண் உள்ளிட்ட பல்வேறு மண் வகைகளில் அரிசியை வளர்க்கலாம்.",
                rice_cv:"இந்தியா பல்வேறு வகையான அரிசி வகைகளுக்கு தாயகமாக உள்ளது, ஒவ்வொன்றும் அதன் தனித்துவமான பண்புகள் மற்றும் வெவ்வேறு பகுதிகள் மற்றும் வளரும் நிலைமைகளுக்கு ஏற்றது. பாஸ்மதி, சம்பா மசூரி, ஐஆர்-8, ஸ்வர்ணா, சர்பதி மற்றும் ஜெயா ஆகியவை இந்தியாவில் பயிரிடப்படும் சில பிரபலமான அரிசி வகைகளாகும்.",
                rice_lp:"கோதுமை ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர், மேலும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு சரியான உரமிடுதல் கோதுமை ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர், மேலும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு சரியான உரமிடுதல்",
                rice_si:"நெல் சாகுபடியில் விதை தேர்வு ஒரு முக்கிய அம்சமாகும். விவசாயிகள் உயர்தர, சான்று பெற்ற விதைகளை நம்பகமான ஆதாரங்களில் இருந்து தேர்வு செய்ய வேண்டும். பொருத்தமான பூஞ்சைக் கொல்லிகள் மற்றும் பூச்சிக்கொல்லிகளுடன் விதை நேர்த்தி செய்வதன் மூலம் விதைகளை நோய்கள் மற்றும் பூச்சிகளிலிருந்து பாதுகாக்க முடியும்.",
                rice_soi:"நேரடி விதைப்பு (உலர்ந்த அல்லது ஈரமான விதைப்பு) அல்லது நாற்றங்கால் படுக்கைகளில் இருந்து நாற்றுகளை நடவு செய்தல் உள்ளிட்ட பல்வேறு முறைகள் மூலம் நெல் விதைக்கலாம். விதைப்பு முறையைத் தேர்ந்தெடுப்பது மண்ணின் நிலை, நீர் இருப்பு மற்றும் தொழிலாளர் இருப்பு போன்ற காரணிகளைப் பொறுத்தது.",
                rice_fu:"நெல் ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர், மேலும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு சரியான உரமிடுதல் அவசியம். நெல் சாகுபடிக்குத் தேவையான முதன்மை ஊட்டச்சத்துக்களில் நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை அடங்கும். விவசாயிகள் மண் பரிசோதனை செய்து, மண் பகுப்பாய்வு முடிவுகளின் அடிப்படையில் பரிந்துரைக்கப்பட்ட உரமிடுதல் விகிதங்களைப் பின்பற்ற வேண்டும்.",
                rice_id:"நெல் ஒரு அரை நீர்ப்பயிராகும், அதன் வளரும் பருவத்தில் நிலையான நீர் வழங்கல் தேவைப்படுகிறது. நெல் சாகுபடிக்கு திறமையான நீர் மேலாண்மை முக்கியமானது. மாற்று நனைத்தல் மற்றும் உலர்த்துதல் இடைவெளியும், ஒரு வரிசைக்குள் செடிகளுக்கு இடையே 10-15 செ.மீ..",
                rice_pd:"அரிசி பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட்டால் விளைச்சலை கணிசமாக பாதிக்கும். பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முறைகள் மற்றும் கலாச்சார நடைமுறைகள் உள்ளிட்ட ஒருங்கிணைந்த பூச்சி மேலாண்மை (IPM) உத்திகள் பயிரை பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                rice_hm:"உகந்த தானிய தரத்தை உறுதி செய்வதற்கும், இழப்பைக் குறைப்பதற்கும் சரியான நேரத்தில் அறுவடை செய்வது அவசியம். நெல் சாகுபடியின் அளவைப் பொறுத்து, கைமுறையாக அல்லது சிறப்பு அறுவடை இயந்திரங்களைப் பயன்படுத்தி அறுவடை செய்யலாம். அறுவடை செய்யப்பட்ட தானியத்தின் தரத்தை பராமரிக்க சரியான உலர்த்துதல், கதிரடித்தல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",

                kidneybeans:"சிறுநீரக பீன்ஸ்",
                kidneybeans_wr:"கிட்னி பீன்ஸ் ஒரு சூடான பருவ பயிர் ஆகும், இது மிதமான மற்றும் மிதவெப்ப மண்டல காலநிலையில் செழித்து வளரும். அவற்றின் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20 ° C மற்றும் 30 ° C ஆகும். அவை வளரும் பருவத்தில் 500-700 மிமீ நன்கு விநியோகிக்கப்படும் மழைப்பொழிவு தேவைப்படுகிறது, முதிர்ச்சி மற்றும் அறுவடை நிலைகளின் போது வறண்ட நிலைகள் இருக்கும்.",
                kidneybeans_sd:"சிறுநீரக பீன்ஸ் பல்வேறு மண் வகைகளில் வளர்க்கப்படலாம், ஆனால் அவை 6.0 முதல் 7.5 வரையிலான சற்றே அமிலத்தன்மை மற்றும் நடுநிலை pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகின்றன. நல்ல நீர் தேங்கும் திறன் மற்றும் போதிய வடிகால் வசதி கொண்ட களிமண் நிலம் இவற்றின் சாகுபடிக்கு ஏற்றது.",
                kidneybeans_cv:"இந்தியாவில் பல்வேறு பகுதிகள் மற்றும் வளரும் நிலைமைகளுக்கு ஏற்ற வகையிலான சிறுநீரக பீன்ஸ் வகைகள் உள்ளன. ராஜ்மா சித்ரா, ராஜ்மா ஷ்வெட், ராஜ்மா கஸ்தூரி, ராஜ்மா பஹாடி மற்றும் ராஜ்மா பஞ்சமுகி போன்ற சில பிரபலமான வகைகள்.",
                kidneybeans_lp:"கோதுமை ஒரு குளிர் பருவ பயிர் ஆகும், இது மிதமான காலநிலையில் செழித்து வளரும். வளரும் பருவத்தில் கோதுமை சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15 ° C முதல் 25 ° C வரை இருக்கும். கோதுமைக்கு போதுமான சூரிய ஒளி வெளிப்பாடு தேவைப்படுகிறது, உகந்த சூரிய ஒளி ஒரு நாளைக்கு 6 முதல் 8 மணி நேரம் வரை இருக்கும்",
                kidneybeans_si:"நம்பகமான மூலங்களிலிருந்து உயர்தர, நோயற்ற விதைகளைத் தேர்ந்தெடுப்பது ஒரு நல்ல சிறுநீரக பயிருக்கு முக்கியமானது. தகுந்த பூஞ்சைக் கொல்லிகள் அல்லது பூச்சிக்கொல்லிகளைக் கொண்டு விதை நேர்த்தி செய்வது, முளைக்கும் மற்றும் ஆரம்ப வளர்ச்சி நிலைகளின் போது பூச்சிகள் மற்றும் நோய்களிலிருந்து விதைகளைப் பாதுகாக்க உதவும்.",
                kidneybeans_soi:"கிட்னி பீன்ஸ் நேரடியாக வயலில் விதைக்கலாம் அல்லது நாற்றங்கால் பாத்திகளில் வளர்த்து பின்னர் இடமாற்றம் செய்யலாம். பரிந்துரைக்கப்பட்ட விதைப்பு ஆழம் 2-3 செ.மீ., வரிசைகளுக்கு இடையே 45-60 செ.மீ இடைவெளியும், ஒரு வரிசைக்குள் செடிகளுக்கு இடையே 10-15 செ.மீ.",
                kidneybeans_fu:"சிறுநீரக பீன்ஸ் வேர் முடிச்சுகள் மூலம் வளிமண்டல நைட்ரஜனை சரிசெய்யக்கூடிய பருப்பு பயிர்கள். இருப்பினும், அவர்களுக்கு இன்னும் போதுமான அளவு பாஸ்பரஸ், பொட்டாசியம் மற்றும் பிற அத்தியாவசிய ஊட்டச்சத்துக்கள் உகந்த வளர்ச்சி மற்றும் விளைச்சலுக்கு தேவைப்படுகிறது. விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                kidneybeans_id:"சிறுநீரக பீன்களுக்கு வழக்கமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக பூக்கும் மற்றும் காய்கள் உருவாகும் நிலைகளில். சொட்டு நீர் பாசனம் அல்லது சால் நீர்ப்பாசனம் முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                kidneybeans_pd:" பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு சிறுநீரக பீன்ஸ் எளிதில் பாதிக்கப்படுகிறது. ஒருங்கிணைந்த பூச்சி மேலாண்மை (IPM) உத்திகளான பயிர் சுழற்சி, எதிர்ப்புத் திறன் கொண்ட இரகங்களின் பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முகவர்கள் த வேண்டும்.",
                kidneybeans_hm:"காய்கள் மஞ்சள் அல்லது காய்ந்து, விதைகள் உள்ளே சத்தமிடும் போது சிறுநீரக பீன்ஸ் பொதுவாக அறுவடை செய்யப்படுகிறது. சாகுபடியின் அளவைப் பொறுத்து அறுவடையை கைமுறையாகவோ அல்லது இயந்திரமாகவோ செய்யலாம். அறுவடை செய்யப்பட்ட பீன்ஸின் தரம் மற்றும் நீண்ட ஆயுளைப் பராமரிக்க சரியான உலர்த்துதல், கதிரடித்தல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                kidneybeans_temp:"15-25° C",
                kidneybeans_rain:"60-150 mm",
                kidneybeans_stemp:"22-25° C",
                kidneybeans_htemp:"28-30° C",

                wheat:"கோதுமை",
                wheat_wr:"கோதுமை ஒரு குளிர் பருவ பயிர் ஆகும், இது மிதமான காலநிலையில் செழித்து வளரும். வளரும் பருவத்தில் கோதுமை சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15 ° C முதல் 25 ° C வரை இருக்கும். கோதுமைக்கு போதுமான சூரிய ஒளி வெளிப்பாடு தேவைப்படுகிறது, உகந்த சூரிய ஒளி ஒரு நாளைக்கு 6 முதல் 8 மணி நேரம் வரை இருக்கும்.",
                wheat_sd:"களிமண், களிமண் களிமண் மற்றும் நன்கு வடிகட்டிய மணல் களிமண் உள்ளிட்ட பல்வேறு மண் வகைகளில் கோதுமை வளர்க்கப்படலாம். கோதுமைக்கான சிறந்த மண்ணின் pH 6.0 முதல் 7.5 வரை இருக்கும், சற்று காரமான மண் விரும்பப்படுகிறது. கோதுமை பயிரிடுவதற்கு, நல்ல நீர் தேங்கும் திறன் கொண்ட, நன்கு வடிகட்டிய மண் சிறந்தது.",
                wheat_cv:"இந்தியாவில் பல்வேறு பகுதிகள் மற்றும் வளரும் நிலைமைகளுக்கு ஏற்ற பல்வேறு வகையான கோதுமை வகைகள் உள்ளன. இந்தியாவில் பயிரிடப்படும் சில பிரபலமான கோதுமை வகைகளில் HD 2967, PBW 343, DBW 17, HI 1544 மற்றும் லோக் 1 ஆகியவை அடங்கும்.",
                wheat_lp:" பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட் பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட் பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட்.",
                wheat_si:"கோதுமை சாகுபடியில் விதை தேர்வு ஒரு முக்கிய அம்சமாகும். விவசாயிகள் உயர்தர, சான்று பெற்ற விதைகளை நம்பகமான ஆதாரங்களில் இருந்து தேர்வு செய்ய வேண்டும். பொருத்தமான பூஞ்சைக் கொல்லிகள் மற்றும் பூச்சிக்கொல்லிகளுடன் விதை நேர்த்தி செய்வதன் மூலம் விதைகளை நோய்கள் மற்றும் பூச்சிகளிலிருந்து பாதுகாக்க முடியும்.",
                wheat_soi:"ஒலிபரப்பு, துளையிடுதல் அல்லது டிபிளிங் உள்ளிட்ட பல்வேறு முறைகள் மூலம் கோதுமையை விதைக்கலாம். பரிந்துரைக்கப்பட்ட விதைப்பு ஆழம் 3-5 செ.மீ., வரிசைகளுக்கு இடையே 20-25 செ.மீ இடைவெளி. சிறந்த விதைப்பு நேரம் பிராந்தியம் மற்றும் காலநிலையைப் பொறுத்து மாறுபடும்.",
                wheat_fu:"கோதுமை ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர், மேலும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு சரியான உரமிடுதல் அவசியம். கோதுமை சாகுபடிக்கு தேவையான முதன்மை ஊட்டச்சத்துக்களில் நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை அடங்கும். விவசாயிகள் மண் பரிசோதனை செய்து, மண் பகுப்பாய்வு முடிவுகளின் அடிப்படையில் பரிந்துரைக்கப்பட்ட உரமிடுதல் விகிதங்களைப் பின்பற்ற வேண்டும்.",
                wheat_id:"கோதுமை அதன் வளரும் பருவத்தில், குறிப்பாக உழவு, தலைப்பு மற்றும் தானியங்களை நிரப்புதல் போன்ற முக்கியமான வளர்ச்சி நிலைகளின் போது சீரான நீர் வழங்கல் தேவைப்படுகிறது. தெளிப்பான் அல்லது சொட்டு நீர் பாசனம் போன்ற திறமையான நீர்ப்பாசன நுட்பங்கள், விளைச்சலைப் பராமரிக்கும் போது, ​​நீர் பயன்பாட்டை மேம்படுத்த உதவும்.",
                wheat_pd:"கோதுமை அஃபிட்ஸ், துரு மற்றும் நுண்துகள் பூஞ்சை காளான் போன்ற பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு எளிதில் பாதிக்கப்படுகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட்டால் விளைச்சலை கணிசமாக பாதிக்கும். பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முறைகள் மற்றும் கலாச்சார நடைமுறைகள் உள்ளிட்ட ஒருங்கிணைந்த பூச்சி மேலாண்மை (IPM) உத்திகள் பயிரைப் பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                wheat_hm:"உகந்த தானிய தரத்தை உறுதி செய்வதற்கும், இழப்பைக் குறைப்பதற்கும் சரியான நேரத்தில் அறுவடை செய்வது அவசியம். சாகுபடியின் அளவைப் பொறுத்து, கோதுமையை கைமுறையாக அல்லது சிறப்பு அறுவடை இயந்திரங்களைப் பயன்படுத்தி அறுவடை செய்யலாம். அறுவடை செய்யப்பட்ட தானியத்தின் தரத்தை பராமரிக்க சரியான உலர்த்துதல், கதிரடித்தல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                wheat_temp:"15-25° C",
                wheat_rain:"60-150 mm",
                wheat_stemp:"22-25° C",
                wheat_htemp:"28-30° C",


                chickpeas:"கொண்டைக்கடலை",
                chickpeas_wr:"கொண்டைக்கடலை ஒரு குளிர் பருவ பயிர் ஆகும், இது அரை வறண்ட காலநிலை முதல் மிதவெப்ப மண்டல காலநிலை வரை வளரும். அவற்றின் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15 ° C மற்றும் 25 ° C ஆகும். தாவர வளர்ச்சியின் போது குளிர்ந்த, வறண்ட காலநிலை மற்றும் இனப்பெருக்க மற்றும் முதிர்வு நிலைகளின் போது ஒப்பீட்டளவில் வெப்பமான சூழ்நிலைகள் தேவைப்படுகின்றன.",
                chickpeas_sd:"கொண்டைக்கடலையை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 7.0 முதல் 8.5 வரையிலான சற்றே காரத்தன்மை கொண்ட, நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகின்றன. நல்ல நீரைத் தாங்கும் திறன் மற்றும் போதுமான வடிகால் வசதி கொண்ட மணல் கலந்த களிமண் அல்லது களிமண் களிமண் மண் அவற்றின் சாகுபடிக்கு ஏற்றது.",
                chickpeas_cv:"இந்தியாவில் பல்வேறு பகுதிகளுக்கும் வளரும் சூழ்நிலைகளுக்கும் ஏற்ற வகையிலான கொண்டைக்கடலை வகைகள் உள்ளன. சில பிரபலமான வகைகளில் தேசி (சிறிய, அடர் நிற விதைகள்), காபூலி (பெரிய, வெளிர் நிற விதைகள்), ஜேஜி 16, கேபிஜி 59 மற்றும் விஹார் ஆகியவை அடங்கும்.",
                chickpeas_lp:" பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட் பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட் பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது, இது திறம்பட நிர்வகிக்கப்படாவிட்",
                chickpeas_si:"நம்பகமான மூலங்களிலிருந்து உயர்தர, நோயற்ற விதைகளைத் தேர்ந்தெடுப்பது ஒரு நல்ல கொண்டைக்கடலை பயிருக்கு முக்கியமானது. பொருத்தமான பூஞ்சைக் கொல்லிகள் அல்லது பூச்சிக்கொல்லிகளைக் கொண்டு விதை நேர்த்தி செய்வது, முளைக்கும் மற்றும் ஆரம்ப வளர்ச்சியின் போது பூச்சிகள் மற்றும் நோய்களிலிருந்து விதைகளைப் பாதுகாக்க உதவும்.",
                chickpeas_soi:"கொண்டைக்கடலையை நேரடியாக வயலில் விதைக்கலாம் அல்லது நாற்றங்கால் பாத்திகளில் வளர்த்து பின் நடவு செய்யலாம். பரிந்துரைக்கப்பட்ட விதைப்பு ஆழம் 5-10 செ.மீ., வரிசைகளுக்கு இடையே 30-45 செ.மீ இடைவெளியும், ஒரு வரிசைக்குள் செடிகளுக்கு இடையே 10-15 செ.மீ.",
                chickpeas_fu:"கொண்டைக்கடலை என்பது பருப்பு பயிர்கள் ஆகும், அவை வேர் முடிச்சுகள் மூலம் வளிமண்டல நைட்ரஜனை சரி செய்ய முடியும். இருப்பினும், அவர்களுக்கு இன்னும் போதுமான அளவு பாஸ்பரஸ், பொட்டாசியம் மற்றும் பிற அத்தியாவசிய ஊட்டச்சத்துக்கள் உகந்த வளர்ச்சி மற்றும் விளைச்சலுக்கு தேவைப்படுகிறது. விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                chickpeas_id:"கொண்டைக்கடலைக்கு மிதமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக பூக்கும் மற்றும் காய்கள் உருவாகும் நிலைகளில். சொட்டு நீர் பாசனம் அல்லது சால் நீர்ப்பாசனம் முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                chickpeas_pd:"கொண்டைக்கடலையானது காய் துளைப்பான்கள், வெட்டுப்புழுக்கள், வாடல் மற்றும் அஸ்கோகிட்டா ப்ளைட் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது. ஒருங்கிணைந்த பூச்சி மேலாண்மை (IPM) உத்திகளான பயிர் சுழற்சி, எதிர்ப்புத் திறன் கொண்ட இரகங்களின் பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முகவர்கள் மற்றும் பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு போன்றவற்றை பயிரைப் பாதுகாக்க செயல்படுத்த வேண்டும்.",
                chickpeas_hm:"கொண்டைக்கடலை பொதுவாக காய்கள் பழுப்பு நிறமாகவும் உலர்ந்ததாகவும் மாறும் போது அறுவடை செய்யப்படுகிறது, மேலும் விதைகள் உள்ளே சலசலக்கும். சாகுபடியின் அளவைப் பொறுத்து அறுவடையை கைமுறையாகவோ அல்லது இயந்திரமாகவோ செய்யலாம். அறுவடை செய்யப்பட்ட கொண்டைக்கடலையின் தரம் மற்றும் நீண்ட ஆயுளைப் பராமரிக்க சரியான உலர்த்துதல், கதிரடித்தல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                chickpeas_temp:"15-25° C",
                chickpeas_rain:"60-150 mm",
                chickpeas_stemp:"22-25° C",
                chickpeas_htemp:"28-30° C",



                banana:"ண்டைக்",
                banana_wr:"சணல் ஒரு சூடான பருவ பயிர் ஆகும், இது வெப்பமான மற்றும் ஈரப்பதமான காலநிலை நிலைகளில் செழித்து வளரும். சணல் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 24°C முதல் 35°C (75°F முதல் 95°F) வரை இருக்கும். சணல் வளரும் பருவத்தில் 1,200 முதல் 1,500 மிமீ (47 முதல் 59 அங்குலம்) வரை நன்கு விநியோகிக்கப்படும் வருடாந்திர மழைப்பொழிவு தேவைப்படுகிறது.",
                banana_sd:"5.5 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மற்றும் களிமண் மண்ணில் சணல் சிறப்பாக வளரும். மண்ணில் கரிமப் பொருட்கள் நிறைந்திருக்க வேண்டும் மற்றும் நல்ல தண்ணீரைத் தாங்கும் திறன் இருக்க வேண்டும். கனமான களிமண் மண் அல்லது குறைந்த வளம் கொண்ட மணல் மண் தவிர்க்கப்பட வேண்டும்.",
                banana_cv:"பயிரிடப்படும் சணலின் இரண்டு முக்கிய வகைகள் வெள்ளை சணல் (கார்கோரஸ் கேப்சுலாரிஸ்) மற்றும் டோசா சணல் (கார்கோரஸ் ஒலிடோரியஸ்). வெள்ளை சணலின் சில பிரபலமான வகைகள் JRC-212, JRC-321 மற்றும் JRO-524 ஆகும், அதே சமயம் பிரபலமான Tossa சணல் வகைகளில் JRO-878, JRO-632 மற்றும் JRO-8432 ஆகியவை அடங்கும்.",
                banana_lp:"சணல் சாகுபடிக்கு சரியான நிலத்தை தயார்படுத்துவது மிகவும் முக்கியம். வயலை ஆழமாக உழவு செய்து, எந்த ஒரு கடினத்தையும் உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும்.",
                banana_si:"உயர் தரமான, சான்றளிக்கப்பட்ட மற்றும் நோய் இல்லாத சணல் விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். வெள்ளைச் சணலுக்குப் பரிந்துரைக்கப்படும் விதைப்பு விகிதம் 6-8 கிலோ/எக்டர் (5.4-7.1 பவுண்ட்/ஏக்கர்), மற்றும் டோசா சணலுக்கு ஹெக்டேருக்கு 5-7 கிலோ (4.5-6.2 பவுண்ட்/ஏக்கர்).",
                banana_soi:"சணல் பொதுவாக இப்பகுதியைப் பொறுத்து மார்ச் நடுப்பகுதியிலிருந்து மே நடுப்பகுதி வரை விதைக்கப்படுகிறது. விதைகளை 2-3 செமீ (0.8-1.2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 20-25 செமீ (8-10 அங்குலம்) இடைவெளியிலும், செடிகளுக்கு இடையே 5-7 செமீ (2-3 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                banana_fu:"சணல் ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர் மற்றும் சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும். உரங்களின் பிளவு பயன்பாடுகள் பெரும்பாலும் பரிந்துரைக்கப்படுகின்றன.",
                banana_id:"சணலுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக வளர்ச்சியின் ஆரம்ப கட்டங்களில் போதுமான ஈரப்பதம் தேவைப்படுகிறது. போதுமான அல்லது ஒழுங்கற்ற மழைப்பொழிவு உள்ள பகுதிகளில் நீர்ப்பாசனம் தேவைப்படலாம். சணல் சாகுபடிக்கு சால் அல்லது பேசின் நீர்ப்பாசன முறைகள் பொதுவாகப் பயன்படுத்தப்படுகின்றன.",
                banana_pd:"சணல் தண்டு அழுகல், ஆந்த்ராக்னோஸ் மற்றும் தண்டு அந்துப்பூச்சி உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு எளிதில் பாதிக்கப்படுகிறது. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                banana_hm:"சணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போது தரை மட்டத்திற்கு அருகில் உள்ள செடிகளை வெட்டுவதன் மூலம் அறுவடை செய்யப்படுகிறது. அறுவடை செய்யப்பட்ட செடிகள் பின்னர் மூட்டையாகி, மரத்தண்டுகளிலிருந்து நார்களைப் பிரிக்க (தண்ணீரில் ஊறவைக்கப்படுகின்றன).",
                banana_temp:"15-25° C",
                banana_rain:"60-150 mm",
                banana_stemp:"22-25° C",
                banana_htemp:"28-30° C",


                blackgram:"கருப்பட்டி",
                blackgram_wr:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போதும் ஒரு சூடான பருவ பயிர். அதன் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 25 ° C மற்றும் 35 ° C ஆகும். இதற்கு தாவர வளர்ச்சியின் போது சூடான மற்றும் ஈரப்பதமான காலநிலை மற்றும் முதிர்வு நிலையில் ஒப்பீட்டளவில் வறண்ட நிலை தேவைப்படுகிறது.",
                blackgram_sd:"கருப்பட்டியை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அது 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகிறது. நல்ல நீரைத் தாங்கும் திறன் மற்றும் போதுமான வடிகால் வசதி கொண்ட மணல் கலந்த செம்மண் அல்லது களிமண் களிமண் மண் அதன் சாகுபடிக்கு ஏற்றது.",
                blackgram_cv:"இந்தியாவில் பல்வேறு பகுதிகள் மற்றும் வளரும் நிலைமைகளுக்கு ஏற்ற கருப்பட்டி வகைகள் உள்ளன. T 9, Pant U 19, Pant U 31, Pant U 35 மற்றும் கிருஷ்ணநாத் ஆகியவை சில பிரபலமான வகைகளாகும்.",
                blackgram_lp:"கருப்பட்டி பொதுவாக காய்கள் பழுப்பு நிறமாகவும் உலர்ந்ததாகவும் மாறும் போது அறுவடை செய்யப்படுகிறது, மேலும் விதைகள் உள்ளே சத்தமிடும். சாகுபடியின் அளவைப் பொறுத்து அறுவடையை கைமுறையாகவோ அல்லது இயந்திரமாகவோ செய்யலாம். அறுவடை செய்யப்பட்ட கருப்பட்டியின் தரம் மற்றும் நீண்ட ஆயுளைப் பராமரிக்க முறையா",
                blackgram_si:"நம்பகமான மூலங்களிலிருந்து உயர்தர, நோயற்ற விதைகளைத் தேர்ந்தெடுப்பது நல்ல உளுந்து பயிருக்கு முக்கியமானது. பொருத்தமான பூஞ்சைக் கொல்லிகள் அல்லது பூச்சிக்கொல்லிகளைக் கொண்டு விதை நேர்த்தி செய்வது, முளைக்கும் மற்றும் ஆரம்ப வளர்ச்சியின் போது பூச்சிகள் மற்றும் நோய்களிலிருந்து விதைகளைப் பாதுகாக்க உதவும்.",
                blackgram_soi:"கருப்பட்டியை நேரடியாக வயலில் விதைக்கலாம் அல்லது நாற்றங்கால் பாத்திகளில் வளர்த்து பின் நடவு செய்யலாம். பரிந்துரைக்கப்பட்ட விதைப்பு ஆழம் 3-5 செ.மீ., வரிசைகளுக்கு இடையே 30-45 செ.மீ இடைவெளியும், ஒரு வரிசைக்குள் செடிகளுக்கு இடையே 10-15 செ.மீ.",
                blackgram_fu:"உளுந்து ஒரு பயறு வகை பயிர் ஆகும், இது வேர் முடிச்சுகள் மூலம் வளிமண்டல நைட்ரஜனை சரி செய்ய முடியும். இருப்பினும், உகந்த வளர்ச்சி மற்றும் விளைச்சலுக்கு இன்னும் போதுமான அளவு பாஸ்பரஸ், பொட்டாசியம் மற்றும் பிற அத்தியாவசிய ஊட்டச்சத்துக்கள் தேவைப்படுகிறது. விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                blackgram_id:"கருப்பட்டிக்கு மிதமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக பூக்கும் மற்றும் காய்கள் உருவாகும் நிலைகளில். சொட்டு நீர் பாசனம் அல்லது சால் நீர்ப்பாசனம் முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                blackgram_pd:"கருப்பட்டி காய் துளைப்பான்கள், அசுவினி, மஞ்சள் மொசைக் வைரஸ் மற்றும் நுண்துகள் பூஞ்சை காளான் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு எளிதில் பாதிக்கப்படுகிறது. ஒருங்கிணைந்த பூச்சி மேலாண்மை (ஐபிஎம்) உத்திகளான பயிர் சுழற்சி, எதிர்ப்புத் திறன் கொண்ட இரகங்களின் பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முகவர்கள் மற்றும் பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு போன்றவை பயிரைப் பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                blackgram_hm:"கருப்பட்டி பொதுவாக காய்கள் பழுப்பு நிறமாகவும் உலர்ந்ததாகவும் மாறும் போது அறுவடை செய்யப்படுகிறது, மேலும் விதைகள் உள்ளே சத்தமிடும். சாகுபடியின் அளவைப் பொறுத்து அறுவடையை கைமுறையாகவோ அல்லது இயந்திரமாகவோ செய்யலாம். அறுவடை செய்யப்பட்ட கருப்பட்டியின் தரம் மற்றும் நீண்ட ஆயுளைப் பராமரிக்க முறையான உலர்த்துதல், கதிரடித்தல் மற்றும் சேமிப்பு உத்திகள் முக்கியம்.",
                blackgram_temp:"15-25° C",
                blackgram_rain:"60-150 mm",
                blackgram_stemp:"22-25° C",
                blackgram_htemp:"28-30° C",


                coconut:"தென்னை",
                coconut_wr:"தென்னை ஒரு வெப்பமண்டல பயிர் ஆகும், இது சூடான மற்றும் ஈரப்பதமான காலநிலையில் செழித்து வளரும். அதன் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20 ° C மற்றும் 35 ° C ஆகும். இதற்கு ஆண்டு முழுவதும் 1,000-2,000 மிமீ மழைப்பொழிவு தேவைப்படுகிறது, இது ஆண்டு முழுவதும் நன்கு விநியோகிக்கப்படுகிறது.",
                coconut_sd:"தென்னையை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அது 5.5 முதல் 8.0 pH வரம்பில் நன்கு வடிகட்டிய, மணல் கலந்த களிமண் அல்லது லேட்டரிடிக் மண்ணை விரும்புகிறது. நல்ல நீர் தேங்கும் திறன் மற்றும் போதுமான வடிகால் வசதி கொண்ட மண் தென்னை சாகுபடிக்கு ஏற்றது.",
                coconut_cv:"இந்தியாவில் பல்வேறு பகுதிகளுக்கும் வளரும் நிலைமைகளுக்கும் ஏற்ற பலவகையான தேங்காய் வகைகள் உள்ளன. சில பிரபலமான வகைகளில் தாலி, வெஸ்ட் கோஸ்ட் டால், ஈஸ்ட் கோஸ்ட் டால், லக்கேடிவ் மைக்ரோ மற்றும் லாக்காடிவ் ஆர்டினரி ஆகியவை அடங்கும்.",
                coconut_lp:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போதுஆழமாக உழுதல் அல்லது பொருத்தமான அளவு குழிகளை தோண்டுதல், கரிமப் பொருட்களைச் சேர்ப்பது மற்றும் போதுமான வடிகால் உறுதி செய்வது ஆகியவை அடங்கும்.",
                coconut_si:"தேங்காய் விதைகள் அல்லது நாற்றுகள் மூலம் இனப்பெருக்கம் செய்யப்படுகிறது. நம்பத்தகுந்த ஆதாரங்களில் இருந்து உயர்தர, நோயற்ற நடவுப் பொருட்களைத் தேர்ந்தெடுப்பது ஒரு நல்ல தென்னை பயிருக்கு முக்கியமானது.",
                coconut_soi:"தென்னை விதைகள் அல்லது நாற்றுகள் பொதுவாக 45-60 செ.மீ ஆழத்தில் குழி அல்லது அகழிகளில் நடப்படும். தாவரங்களுக்கு இடையே பரிந்துரைக்கப்பட்ட இடைவெளி முக்கோண அல்லது சதுர வடிவில் 7-9 மீட்டர் ஆகும்.",
                coconut_fu:"தேங்காய் ஒரு கனமான தீவனம் மற்றும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு போதுமான ஊட்டச்சத்து தேவைப்படுகிறது. தென்னை சாகுபடிக்கு தேவையான முதன்மை ஊட்டச்சத்துக்கள் நைட்ரஜன், பாஸ்பரஸ், பொட்டாசியம் மற்றும் கரிமப் பொருட்கள். விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                coconut_id:"தென்னைக்கு வழக்கமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக வறண்ட காலம் மற்றும் பழங்கள் வளரும் நிலைகளில். சொட்டு நீர் பாசனம் அல்லது பேசின் நீர்ப்பாசன முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                coconut_pd:"தென்னை பூச்சிகள், காண்டாமிருக வண்டுகள், மொட்டு அழுகல் மற்றும் வாடல் நோய்கள் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது. ஒருங்கிணைந்த பூச்சி மேலாண்மை (ஐபிஎம்) உத்திகளான பயிர் சுழற்சி, எதிர்ப்புத் திறன் கொண்ட இரகங்களின் பயன்பாடு, உயிரியல் கட்டுப்பாட்டு முகவர்கள் மற்றும் பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு போன்றவை பயிரைப் பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                coconut_hm:"பழங்கள் முழுமையாக முதிர்ச்சியடையும் போது தேங்காய் பொதுவாக அறுவடை செய்யப்படுகிறது, மேலும் உமி பழுப்பு நிறமாக மாறும். அறுவடை பொதுவாக மரங்களில் ஏறி அல்லது சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி கைமுறையாக செய்யப்படுகிறது. அறுவடை செய்யப்பட்ட தேங்காய்களின் தரம் மற்றும் அடுக்கு ஆயுளைப் பராமரிக்க சரியான கையாளுதல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                coconut_temp:"15-25° C",
                coconut_rain:"60-150 mm",
                coconut_stemp:"22-25° C",
                coconut_htemp:"28-30° C",


                apple:"ஆப்பிள்",
                apple_wr:"ஆப்பிள்கள் ஒரு மிதமான பயிராகும், குளிர்கால மாதங்களில் நன்கு வரையறுக்கப்பட்ட குளிர்ச்சியான காலம் தேவைப்படுகிறது. வளரும் பருவத்தில் ஆப்பிள் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15 ° C முதல் 25 ° C வரை இருக்கும். போதுமான குளிர்கால குளிர் (7°Cக்கு கீழே) சரியான உறக்கநிலை மற்றும் அடுத்தடுத்த பூக்கும் மற்றும் பழம்தருவதற்கு முக்கியமானது.",
                apple_sd:"ஆப்பிள்கள் பல்வேறு மண் வகைகளில் வளர்க்கப்படலாம், ஆனால் அவை 5.5 முதல் 6.5 pH வரம்பில் ஆழமான, நன்கு வடிகட்டிய மற்றும் வளமான மண்ணில் சிறப்பாக செழித்து வளரும். நல்ல நீர் தேங்கும் திறன் மற்றும் போதுமான வடிகால் வசதி கொண்ட களிமண் அல்லது மணல் கலந்த களிமண் மண் ஆப்பிள் சாகுபடிக்கு ஏற்றது.",
                apple_cv:"இந்தியாவில் பல்வேறு பகுதிகளுக்கும் வளரும் நிலைமைகளுக்கும் ஏற்ற ஆப்பிள் வகைகள் உள்ளன. ராயல் டெலிசியஸ், ரெட் டெலிசியஸ், கோல்டன் டெலிசியஸ், ஆம்ப்ரி, லால் அம்ப்ரி மற்றும் ஸ்டார்க்கிங் டெலிசியஸ் ஆகியவை சில பிரபலமான வகைகளில் அடங்கும்.",
                apple_lp:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போது அவசியம். இது ஆழமாக உழுதல் அல்லது பொருத்தமான அளவு குழிகளை தோண்டுதல், கரிமப் பொருட்களைச் சேர்ப்பது மற்றும் போதுமான வடிகால் உறுதி செய்வது ஆகியவை அடங்கும்.",
                apple_si:"ஆப்பிள்கள் பொதுவாக ஒட்டுரக மரக்கன்றுகள் அல்லது வேர் தண்டுகள் மூலம் இனப்பெருக்கம் செய்யப்படுகின்றன. நம்பகமான மூலங்களிலிருந்து உயர்தர, நோய் இல்லாத நடவுப் பொருட்களைத் தேர்ந்தெடுப்பது ஒரு நல்ல ஆப்பிள் பயிருக்கு முக்கியமானது.",
                apple_soi:"ஆப்பிள் மரக்கன்றுகள் பொதுவாக 30-45 செமீ ஆழத்தில் குழிகளில் அல்லது அகழிகளில் நடப்படுகிறது. தாவரங்களுக்கு இடையே பரிந்துரைக்கப்படும் இடைவெளியானது ஆணிவேர் மற்றும் பயிற்சி முறையைப் பொறுத்து மாறுபடும், வரிசைகளுக்கு இடையே 3-6 மீட்டர் மற்றும் ஒரு வரிசைக்குள் தாவரங்களுக்கு இடையே 2-4 மீட்டர்.",
                apple_fu:"ஆப்பிள்கள் உகந்த வளர்ச்சி, பூக்கள் மற்றும் பழ உற்பத்திக்கு போதுமான ஊட்டச்சத்துக்கள் தேவை. ஆப்பிள் சாகுபடிக்கு தேவையான முதன்மை ஊட்டச்சத்துக்களில் நைட்ரஜன், பாஸ்பரஸ், பொட்டாசியம் மற்றும் கரிம பொருட்கள் ஆகியவை அடங்கும். விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                apple_id:"ஆப்பிள்களுக்கு வழக்கமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக பழ வளர்ச்சி மற்றும் வளரும் நிலைகளில். சொட்டு நீர் பாசனம் அல்லது நுண் தெளிப்பான் நீர்ப்பாசன முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                apple_pd:"அந்துப்பூச்சி, ஆப்பிள் ஸ்கேப், நுண்துகள் பூஞ்சை காளான் மற்றும் தீ ப்ளைட் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆப்பிள்கள் எளிதில் பாதிக்கப்படுகின்றன. ஒருங்கிணைந்த பூச்சி மேலாண்மை (ஐபிஎம்) உத்திகளான கத்தரித்தல், எதிர்ப்புத் திறன் கொண்ட இரகங்களைப் பயன்படுத்துதல், உயிரியல் கட்டுப்பாட்டு முகவர்கள் மற்றும் பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு ஆகியவை பயிரைப் பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                apple_hm:"ஆப்பிள்கள் பொதுவாக விரும்பிய அளவு, நிறம் மற்றும் முதிர்வு நிலையை அடையும் போது அறுவடை செய்யப்படுகின்றன. அறுவடை பொதுவாக மரங்களில் இருந்து பழங்களை கவனமாக பறிப்பதன் மூலம் கைமுறையாக செய்யப்படுகிறது. அறுவடை செய்யப்பட்ட ஆப்பிள்களின் தரம் மற்றும் அடுக்கு ஆயுளைப் பராமரிக்க சரியான கையாளுதல், தரப்படுத்தல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                apple_temp:"15-25° C",
                apple_rain:"60-150 mm",
                apple_stemp:"22-25° C",
                apple_htemp:"28-30° C",


                coffee:"காபி",
                coffee_wr:"காபி ஒரு வெப்பமண்டல பயிர் ஆகும், இது சூடான மற்றும் ஈரப்பதமான காலநிலையில் செழித்து வளரும். காபி சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15°C முதல் 28°C வரை இருக்கும். ஆண்டு முழுவதும் 1,500-2,500 மிமீ மழைப்பொழிவு தேவைப்படுகிறது.",
                coffee_sd:"காபியை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அது 5.5 முதல் 6.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகிறது. நல்ல நீர் தேங்கும் திறன் மற்றும் போதுமான வடிகால் வசதி கொண்ட களிமண் அல்லது மணல் கலந்த களிமண் மண் காபி சாகுபடிக்கு ஏற்றது.",
                coffee_cv:"இந்தியாவில் வெவ்வேறு பகுதிகளுக்கும் வளரும் நிலைமைகளுக்கும் ஏற்ற இரண்டு முக்கிய காபி வகைகள் உள்ளன: அராபிகா மற்றும் ரோபஸ்டா. அரபிகா ரகங்களான கென்ட், எஸ்.795, மற்றும் காவேரி மலைப்பகுதிகளிலும், ரோபஸ்டா ரகங்களான எஸ்.274 மற்றும் சிஎக்ஸ்ஆர் சமவெளிகளிலும் பயிரிடப்படுகிறது.",
                coffee_lp:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போது சரியான நிலத்தை தயார் செய்வது அவசியம். இது ஆழமாக உழுதல் அல்லது பொருத்தமான அளவு குழிகளை தோண்டுதல், கரிமப் பொருட்களைச் சேர்ப்பது மற்றும் போதுமான வடிகால் உறுதி செய்வது ஆகியவை அடங்கும். காபி செடிகளுக்கு பகுதி நிழலை வழங்க நிழல் தரும் மரங்களையும் நடலாம்.",
                coffee_si:"காபி விதைகள் அல்லது நாற்றுகள் மூலம் பரப்பப்படுகிறது. நம்பகமான மூலங்களிலிருந்து உயர்தர, நோயற்ற நடவுப் பொருட்களைத் தேர்ந்தெடுப்பது ஒரு நல்ல காபி பயிருக்கு முக்கியமானது.",
                coffee_soi:"காபி விதைகள் அல்லது நாற்றுகள் பொதுவாக 15-20 செமீ ஆழத்தில் குழிகளில் அல்லது அகழிகளில் நடப்படுகின்றன. தாவரங்களுக்கு இடையே பரிந்துரைக்கப்படும் இடைவெளி பல்வேறு வகைகளைப் பொறுத்து மாறுபடும், வரிசைகளுக்கு இடையே 1.5-2.5 மீட்டர் மற்றும் ஒரு வரிசையில் உள்ள தாவரங்களுக்கு இடையே 1-1.5 மீட்டர்.",
                coffee_fu:"காபி செடிகளுக்கு உகந்த வளர்ச்சி, பூக்கள் மற்றும் பீன்ஸ் உற்பத்திக்கு போதுமான ஊட்டச்சத்துக்கள் தேவை. காபி சாகுபடிக்கு தேவையான முதன்மை ஊட்டச்சத்துக்கள் நைட்ரஜன், பாஸ்பரஸ், பொட்டாசியம் மற்றும் கரிம பொருட்கள். விவசாயிகள் மண் பரிசோதனை செய்து பரிந்துரைகளின் அடிப்படையில் உரிய உரங்களை இட வேண்டும்.",
                coffee_id:"காபிக்கு வழக்கமான நீர்ப்பாசனம் தேவைப்படுகிறது, குறிப்பாக வறண்ட காலம் மற்றும் பழ வளர்ச்சி நிலைகளில். சொட்டு நீர் பாசனம் அல்லது நுண் தெளிப்பான் நீர்ப்பாசன முறைகள் திறமையான நீர் பயன்பாட்டை உறுதி செய்வதற்கும், நீர் விரயத்தை குறைப்பதற்கும் பயன்படுத்தப்படலாம்.",
                coffee_pd:"காபி பெர்ரி துளைப்பான், காபி இலை துரு மற்றும் வேர் முடிச்சு நூற்புழுக்கள் உட்பட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு காபி எளிதில் பாதிக்கப்படுகிறது. ஒருங்கிணைந்த பூச்சி மேலாண்மை (ஐபிஎம்) உத்திகளான கத்தரித்தல், எதிர்ப்புத் திறன் கொண்ட இரகங்களைப் பயன்படுத்துதல், உயிரியல் கட்டுப்பாட்டு முகவர்கள் மற்றும் பூச்சிக்கொல்லிகளின் நியாயமான பயன்பாடு ஆகியவை பயிரைப் பாதுகாக்க செயல்படுத்தப்பட வேண்டும்.",
                coffee_hm:"காபி பீன்ஸ் பொதுவாக பெர்ரிகள் அடர் சிவப்பு நிறமாக மாறும் போது அறுவடை செய்யப்படுகிறது, இது முதிர்ச்சியைக் குறிக்கிறது. பழுத்த பெர்ரிகளை கைமுறையாக எடுப்பதன் மூலமோ அல்லது பெரிய அளவிலான தோட்டங்களுக்கு இயந்திர அறுவடை இயந்திரங்களைப் பயன்படுத்துவதன் மூலமோ அறுவடை செய்யலாம். அறுவடை செய்யப்பட்ட காபி கொட்டைகளின் தரம் மற்றும் சுவையை பராமரிக்க சரியான உலர்த்துதல், பதப்படுத்துதல் மற்றும் சேமிப்பு நுட்பங்கள் முக்கியம்.",
                coffee_temp:"15-25° C",
                coffee_rain:"60-150 mm",
                coffee_stemp:"22-25° C",
                coffee_htemp:"28-30° C",


                cotton:"பருத்தி",
                cotton_wr:"பருத்தி ஒரு சூடான பருவ பயிர் ஆகும், இது சூரிய ஒளி மற்றும் சூடான வெப்பநிலையுடன் நீண்ட வளரும் காலம் தேவைப்படுகிறது. பருத்தி வளர்ச்சிக்கான உகந்த வெப்பநிலை வரம்பு 20°C முதல் 30°C (68°F முதல் 86°F வரை) வரை இருக்கும். பருத்தி உறைபனிக்கு உணர்திறன் கொண்டது மற்றும் உறைபனி வெப்பநிலையால் சேதமடையலாம் அல்லது கொல்லப்படலாம். இது குறைந்த ஈரப்பதம் மற்றும் குறைந்த மழைப்பொழிவு உள்ள பகுதிகளில் பழங்கள் மற்றும் காய்கள் திறக்கும் நிலைகளில் செழித்து வளரும்.",
                cotton_sd:"6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணில் பருத்தி சிறப்பாக வளரும். பருத்தி பயிரிடுவதற்கு நல்ல நீர் தேங்கும் திறன் கொண்ட மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. வேர் வளர்ச்சி மற்றும் நீர் ஊடுருவலைத் தடுக்கும் என்பதால், மண்ணின் சுருக்கத்தைத் தவிர்க்க வேண்டும்.",
                cotton_cv:"பருத்தி வகைகளை இரண்டு முக்கிய வகைகளாகப் பிரிக்கலாம்: மேட்டுப் பருத்தி (Gossypium hirsutum) மற்றும் கூடுதல் நீண்ட பிரதான பருத்தி (Gossypium barbadense). சில பிரபலமான மலைநாட்டு பருத்தி வகைகளில் டெல்டபைன், ஸ்டோன்வில்லே மற்றும் ஃபைபர்மேக்ஸ் ஆகியவை அடங்கும், அதே சமயம் பிமா மற்றும் கிசா ஆகியவை பிரபலமான கூடுதல் நீளமான பிரதான வகைகள்.",
                cotton_lp:"பருத்தி பயிரிடுவதற்கு முறையான நிலத்தை தயார் செய்வது மிகவும் முக்கியம். வயலை ஆழமாக உழவு செய்து, எந்த ஒரு கடினத்தையும் உடைத்து, நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும். பாத்திகள் அல்லது முகடுகளை வடிகால் மேம்படுத்தவும், சால் நீர்ப்பாசனத்தை எளிதாக்கவும் அமைக்கலாம்.",
                cotton_si:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போதுபட்ட பருத்தி விதைகளைப் பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் பல்வேறு மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக ஹெக்டேருக்கு 10 முதல் 15 கிலோ (ஏக்கருக்கு 9 முதல் 13 பவுண்ட்) வரை இருக்கும்.",
                cotton_soi:"பருத்தி பொதுவாக வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் மண்ணின் வெப்பநிலை 18 ° C (64 ° F) அல்லது அதற்கு அதிகமாக இருக்கும் போது விதைக்கப்படுகிறது. ஒளிபரப்பு, துளையிடுதல் அல்லது துல்லியமான நடவு முறைகள் மூலம் விதைப்பு செய்யலாம். பரிந்துரைக்கப்பட்ட வரிசை இடைவெளி பொதுவாக 75 முதல் 100 செமீ (30 முதல் 40 அங்குலம்) வரை இருக்கும், மேலும் விதை ஆழம் 2.5 முதல் 5 செமீ (1 முதல் 2 அங்குலம்) வரை இருக்க வேண்டும்.",
                cotton_fu:"பருத்தி ஒரு கனமான தீவனம் மற்றும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு போதுமான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும். உரங்களின் பிளவு பயன்பாடுகள் பெரும்பாலும் பரிந்துரைக்கப்படுகின்றன.",
                cotton_id:"பருத்தி வளரும் பருவத்தில், குறிப்பாக காய் வளர்ச்சி நிலையில் சீரான ஈரப்பதம் தேவைப்படுகிறது. பருத்தி பாசனத்திற்கு உரோமம், தெளிப்பான் அல்லது சொட்டு நீர் பாசன முறைகளைப் பயன்படுத்தலாம். நீர்ப்பாசனத்தின் அதிர்வெண் மற்றும் அளவு மண் வகை, காலநிலை மற்றும் பயிர் வளர்ச்சி நிலை போன்ற காரணிகளைப் பொறுத்தது.",
                cotton_pd:"காபி இலை துரு, காபி பெர்ரி துளைப்பான் மற்றும் நூற்புழுக்கள் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு காபி செடிகள் எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                cotton_hm:"பருத்தி பொதுவாக பருத்தி பிக்கர்கள் அல்லது ஸ்ட்ரிப்பர்களைப் பயன்படுத்தி இயந்திரத்தனமாக அறுவடை செய்யப்படுகிறது. பருத்தி எடுப்பவர்கள் செடியிலிருந்து முழு திறந்த காய்களையும் அகற்றுகிறார்கள், அதே சமயம் ஸ்ட்ரிப்பர்ஸ் பர்ஸில் இருந்து பஞ்சை அகற்றி, செடியின் மீது பர்ர்களை விட்டுவிடுகிறார்கள். அதிகபட்ச மகசூல் மற்றும் நார் தரத்தை உறுதி செய்ய சரியான அறுவடை நேரம் முக்கியமானது.",
                cotton_temp:"15-25° C",
                cotton_rain:"60-150 mm",
                cotton_stemp:"22-25° C",
                cotton_htemp:"28-30° C",


                grapes:"திராட்சை",
                grapes_wr:"திராட்சை வளரும் பருவத்தில் நீண்ட, சன்னி நாட்கள் மற்றும் குளிர் இரவுகளுடன் சூடான, வறண்ட காலநிலையில் செழித்து வளரும். திராட்சை சாகுபடிக்கு உகந்த வெப்பநிலை பகலில் 20°C முதல் 30°C (68°F முதல் 86°F) வரையிலும், இரவில் 15°C முதல் 20°C வரையிலும் (59°F முதல் 68°F வரை) இருக்கும். திராட்சை வளரும் பருவத்தில் குறைந்தபட்சம் 1,500 முதல் 2,000 மணிநேர சூரிய ஒளி மற்றும் ஆண்டு மழை 500 முதல் 800 மிமீ (20 முதல் 31 அங்குலம்) வரை தேவைப்படுகிறது.",
                grapes_sd:"திராட்சை வளரும் பருவத்தில் நீண்ட, சன்னி நாட்கள் மற்றும் குளிர் இரவுகளுடன் சூடான, வறண்ட காலநிலையில் செழித்து வளரும். திராட்சை சாகுபடிக்கு உகந்த வெப்பநிலை பகலில் 20°C முதல் 30°C (68°F முதல் 86°F) வரையிலும், இரவில் 15°C முதல் 20°C வரையிலும் (59°F முதல் 68°F வரை) இருக்கும். திராட்சை வளரும் பருவத்தில் குறைந்தபட்சம் 1,500 முதல் 2,000 மணிநேர சூரிய ஒளி மற்றும் ஆண்டு மழை 500 முதல் 800 மிமீ (20 முதல் 31 அங்குலம்) வரை தேவைப்படுகிறது.",
                grapes_cv:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போதுட்சைகள் மற்றும் திராட்சைகளுக்கு ஏற்ற பல திராட்சை வகைகள் உள்ளன. சில பிரபலமான ஒயின் திராட்சை வகைகளில் கேபர்நெட் சாவிக்னான், மெர்லோட், சார்டோன்னே மற்றும் ரைஸ்லிங் ஆகியவை அடங்கும். தாம்சன் சீட்லெஸ், கிரிம்சன் சீட்லெஸ் மற்றும் ரெட் குளோப் போன்ற டேபிள் திராட்சை வகைகள் புதிய நுகர்வுக்காக பரவலாக பயிரிடப்படுகின்றன.",
                grapes_lp:"திராட்சை திராட்சைத் தோட்டங்களுக்கான நிலத்தை தயாரிப்பதில், தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது ஓடு வடிகால் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம். கொடிகளை பயிற்றுவிக்க டிரெல்லிசிங் அல்லது ஆதரவு அமைப்புகள் அமைக்கப்பட வேண்டும்.",
                grapes_si:"ணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போதுசை பற்றிய அனைத்து தகவல்களையும் உள்ளடக்கிய ஒரு வலைப்பக்கத்தை உருவாக்குகிறேன். வானிலை தேவைகள், மண் விவரங்கள், பயிர் வகைகள், நிலம் தயாரித்தல், விதை தகவல், விதைப்பு தகவல், உர பயன்பாடு, நீர்ப்பாசன விவரங்கள், பூச்சி மற்றும் நோய் மேலாண்மை, அறுவடை முறை ஆகியவற்றை உள்ளடக்கிய உள்ளடக்கத்தை எனக்கு வழங்கவும்.",
                grapes_soi:"திராட்சைகளை வெட்டல் மூலம் பரப்பலாம் அல்லது வேர் தண்டுகளில் ஒட்டலாம். பூச்சிகள், நோய்கள் அல்லது மண் நிலைகளுக்கு எதிர்ப்புத் தெரிவிப்பதற்காக வேர் தண்டுகள் பெரும்பாலும் தேர்ந்தெடுக்கப்படுகின்றன. நடவு பொதுவாக வசந்த காலத்தின் துவக்கத்தில் செய்யப்படுகிறது, கொடிகளுக்கு இடையே 1.5 முதல் 3 மீட்டர் (5 முதல் 10 அடி) இடைவெளியும், பல்வேறு மற்றும் பயிற்சி முறையைப் பொறுத்து வரிசைகளுக்கு இடையே 2 முதல் 3.5 மீட்டர் (6.5 முதல் 11.5 அடி) இடைவெளியும் இருக்கும்.",
                grapes_fu:"திராட்சை கொடிகள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                grapes_id:"திராட்சை வளரும் பருவத்தில், குறிப்பாக பழ வளர்ச்சி மற்றும் பழுக்க வைக்கும் நிலைகளில் சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது நுண்ணிய தெளிப்பான் அமைப்புகள் பொதுவாக திராட்சை பாசனத்திற்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                grapes_pd:"திராட்சை கொடிகள் நுண்துகள் பூஞ்சை காளான், பூஞ்சை காளான், திராட்சை பைலோக்செரா மற்றும் திராட்சை பெர்ரி அந்துப்பூச்சி உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                grapes_hm:"திராட்சை பொதுவாக கையால் அல்லது இயந்திர அறுவடை இயந்திரங்களின் உதவியுடன் அறுவடை செய்யப்படுகிறது, இது உற்பத்தியின் அளவு மற்றும் திராட்சையின் நோக்கம் (ஒயின், டேபிள் திராட்சை அல்லது திராட்சைகள்) ஆகியவற்றைப் பொறுத்து. பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் மிகவும் முக்கியமானது.",
                grapes_temp:"15-25° C",
                grapes_rain:"60-150 mm",
                grapes_stemp:"22-25° C",
                grapes_htemp:"28-30° C",


                jute:"சணல்",
                jute_wr:"சணல் ஒரு சூடான பருவ பயிர் ஆகும், இது வெப்பமான மற்றும் ஈரப்பதமான காலநிலை நிலைகளில் செழித்து வளரும். சணல் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 24°C முதல் 35°C (75°F முதல் 95°F) வரை இருக்கும். சணல் வளரும் பருவத்தில் 1,200 முதல் 1,500 மிமீ (47 முதல் 59 அங்குலம்) வரை நன்கு விநியோகிக்கப்படும் வருடாந்திர மழைப்பொழிவு தேவைப்படுகிறது.",
                jute_sd:"5.5 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மற்றும் களிமண் மண்ணில் சணல் சிறப்பாக வளரும். மண்ணில் கரிமப் பொருட்கள் நிறைந்திருக்க வேண்டும் மற்றும் நல்ல தண்ணீரைத் தாங்கும் திறன் இருக்க வேண்டும். கனமான களிமண் மண் அல்லது குறைந்த வளம் கொண்ட மணல் மண் தவிர்க்கப்பட வேண்டும்.",
                jute_cv:"பயிரிடப்படும் சணலின் இரண்டு முக்கிய வகைகள் வெள்ளை சணல் (கார்கோரஸ் கேப்சுலாரிஸ்) மற்றும் டோசா சணல் (கார்கோரஸ் ஒலிடோரியஸ்). வெள்ளை சணலின் சில பிரபலமான வகைகள் JRC-212, JRC-321 மற்றும் JRO-524 ஆகும், அதே சமயம் பிரபலமான Tossa சணல் வகைகளில் JRO-878, JRO-632 மற்றும் JRO-8432 ஆகியவை அடங்கும்.",
                jute_lp:"சணல் சாகுபடிக்கு சரியான நிலத்தை தயார்படுத்துவது மிகவும் முக்கியம். வயலை ஆழமாக உழவு செய்து, எந்த ஒரு கடினத்தையும் உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும்.",
                jute_si:"உயர் தரமான, சான்றளிக்கப்பட்ட மற்றும் நோய் இல்லாத சணல் விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். வெள்ளைச் சணலுக்குப் பரிந்துரைக்கப்படும் விதைப்பு விகிதம் 6-8 கிலோ/எக்டர் (5.4-7.1 பவுண்ட்/ஏக்கர்), மற்றும் டோசா சணலுக்கு ஹெக்டேருக்கு 5-7 கிலோ (4.5-6.2 பவுண்ட்/ஏக்கர்).",
                jute_soi:"சணல் பொதுவாக இப்பகுதியைப் பொறுத்து மார்ச் நடுப்பகுதியிலிருந்து மே நடுப்பகுதி வரை விதைக்கப்படுகிறது. விதைகளை 2-3 செமீ (0.8-1.2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 20-25 செமீ (8-10 அங்குலம்) இடைவெளியிலும், செடிகளுக்கு இடையே 5-7 செமீ (2-3 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                jute_fu:"சணல் ஒரு ஊட்டச்சத்து தேவைப்படும் பயிர் மற்றும் சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும். உரங்களின் பிளவு பயன்பாடுகள் பெரும்பாலும் பரிந்துரைக்கப்படுகின்றன.",
                jute_id:"சணலுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக வளர்ச்சியின் ஆரம்ப கட்டங்களில் போதுமான ஈரப்பதம் தேவைப்படுகிறது. போதுமான அல்லது ஒழுங்கற்ற மழைப்பொழிவு உள்ள பகுதிகளில் நீர்ப்பாசனம் தேவைப்படலாம். சணல் சாகுபடிக்கு சால் அல்லது பேசின் நீர்ப்பாசன முறைகள் பொதுவாகப் பயன்படுத்தப்படுகின்றன.",
                jute_pd:"சணல் தண்டு அழுகல், ஆந்த்ராக்னோஸ் மற்றும் தண்டு அந்துப்பூச்சி உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு எளிதில் பாதிக்கப்படுகிறது. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                jute_hm:"சணல் பொதுவாக செடிகள் முழுவதுமாக பூத்து இலைகள் உதிரத் தொடங்கும் போது தரை மட்டத்திற்கு அருகில் உள்ள செடிகளை வெட்டுவதன் மூலம் அறுவடை செய்யப்படுகிறது. அறுவடை செய்யப்பட்ட செடிகள் பின்னர் மூட்டையாகி, மரத்தண்டுகளிலிருந்து நார்களைப் பிரிக்க (தண்ணீரில் ஊறவைக்கப்படுகின்றன).",
                jute_temp:"15-25° C",
                jute_rain:"60-150 mm",
                jute_stemp:"22-25° C",
                jute_htemp:"28-30° C",


                lentil:"பருப்பு",
                lentil_wr:"பருப்பு மிதமான வெப்பநிலையில் செழித்து வளரும் குளிர் பருவ பயிர். பயறு சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 15°C முதல் 25°C (59°F முதல் 77°F வரை) வரை இருக்கும். பயறுகள் தாவர நிலையில் உறைபனியை பொறுத்துக்கொள்ளும், ஆனால் பூக்கும் மற்றும் காய்களை நிரப்பும் நிலைகளில் அதிக வெப்பநிலை மற்றும் வறட்சியை உணரும்.",
                lentil_sd:"6.0 முதல் 8.0 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணில் பயறுகள் சிறப்பாக வளரும். பயறு சாகுபடிக்கு நல்ல நீர் தேங்கும் திறன் கொண்ட களிமண் அல்லது மணல் கலந்த களிமண் மண் விரும்பப்படுகிறது. பருப்பு உப்புத்தன்மை மற்றும் நீர்நிலைகளுக்கு உணர்திறன் கொண்டது.",
                lentil_cv:"மசூர் (சிவப்பு பருப்பு), மல்கா (பழுப்பு பருப்பு), மொராட் (பச்சை பயறு), மற்றும் பூசா வைபவ் (பெரிய விதை வகை) ஆகியவை சில பிரபலமான பயறு வகைகளாகும். பல்வேறு வகைகளின் தேர்வு காலநிலை, முதிர்வு காலம் மற்றும் நோக்கம் கொண்ட பயன்பாடு (முழு அல்லது பிளவுபட்ட பருப்பு) போன்ற காரணிகளைப் பொறுத்தது.",
                lentil_lp:"பயறு பயிரிடுவதற்கு நிலத்தை தயார்படுத்துவது ஆழமான உழவை உள்ளடக்கியது. ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும்.",
                lentil_si:"உயர்தர, சான்று பெற்ற, நோயற்ற பயறு விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் 30-50 கிலோ/ஹெக்டர் (27-45 பவுண்ட்/ஏக்கர்) வகை மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும்.",
                lentil_soi:"காலநிலையைப் பொறுத்து, இலையுதிர்காலத்தின் பிற்பகுதியில் அல்லது வசந்த காலத்தின் துவக்கத்தில் பருப்புகளை விதைக்கலாம். விதைகளை 2-4 செ.மீ (0.8-1.6 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 20-30 செ.மீ (8-12 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                lentil_fu:"பருப்புக்கு மிதமான ஊட்டச்சத்து தேவை. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும்.",
                lentil_id:"பருப்பு வளரும் பருவத்தில், குறிப்பாக பூக்கும் மற்றும் காய்களை நிரப்பும் நிலைகளில் மிதமான ஈரப்பதம் தேவைப்படுகிறது. போதுமான அல்லது ஒழுங்கற்ற மழைப்பொழிவு உள்ள பகுதிகளில் நீர்ப்பாசனம் தேவைப்படலாம். பயறு சாகுபடிக்கு சால் அல்லது தெளிப்பு நீர்ப்பாசன முறைகளைப் பயன்படுத்தலாம்.",
                lentil_pd:"அசுவினி, காய் துளைப்பான், துரு, வாடல் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு பயறுகள் எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                lentil_hm:"காய்கள் பழுப்பு நிறமாகி, இலைகள் உலரத் தொடங்கும் போது பயறு பொதுவாக அறுவடை செய்யப்படுகிறது. தாவரங்கள் கைமுறையாகவோ அல்லது இயந்திரமாகவோ ஒருங்கிணைந்த அறுவடை இயந்திரத்தைப் பயன்படுத்தி வெட்டப்படுகின்றன. அறுவடை செய்யப்பட்ட பயிரை சரியான முறையில் உலர்த்துதல் மற்றும் கதிரடித்தல் ஆகியவை பயறு வகைகளின் தரத்தை பராமரிக்க மிகவும் முக்கியம்.",
                lentil_temp:"15-25° C",
                lentil_rain:"60-150 mm",
                lentil_stemp:"22-25° C",
                lentil_htemp:"28-30° C",


                maize:"மக்காச்சோளம்",    
                maize_wr:"மக்காச்சோளம் ஒரு சூடான பருவ பயிர் ஆகும், இது வளரும் பருவம் முழுவதும் சூடான வெப்பநிலை மற்றும் போதுமான ஈரப்பதம் தேவைப்படுகிறது. மக்காச்சோளம் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 30°C (68°F முதல் 86°F வரை) வரை இருக்கும். மக்காச்சோளத்திற்கு 500 முதல் 800 மிமீ (20 முதல் 31 அங்குலம்) ஆண்டு மழை தேவைப்படுகிறது, வளரும் பருவத்தில் நன்கு விநியோகிக்கப்படுகிறது.",
                maize_sd:"மக்காச்சோளத்தை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அது 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகிறது. களிமண் அல்லது களிமண் களிமண் மண், நல்ல நீர் பிடிக்கும் திறன் கொண்டவை மக்காச்சோள சாகுபடிக்கு ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                maize_cv:"கலப்பின மற்றும் திறந்த-மகரந்தச் சேர்க்கை வகைகள் உட்பட ஏராளமான மக்காச்சோள வகைகள் உள்ளன. சில பிரபலமான கலப்பின மக்காச்சோள வகைகளில் டெகல்ப், முன்னோடி மற்றும் மான்சாண்டோ ஆகியவை அடங்கும், அதே சமயம் ஹிக்கரி கிங் மற்றும் ரீட்ஸ் மஞ்சள் டென்ட் போன்ற திறந்த-மகரந்தச் சேர்க்கை ரகங்களும் பரவலாகப் பயிரிடப்படுகின்றன.",
                maize_lp:"மக்காச்சோளத்திற்கான நிலத்தை தயாரிப்பதில் ஆழமான உழவு உள்ளடங்கியது. இது கடினப் பகுதியை உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்கிறது. ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும். வடிகால் வசதியை மேம்படுத்தவும், சால் நீர்ப்பாசனத்தை எளிதாக்கவும் உயர்த்தப்பட்ட படுக்கைகள் அல்லது முகடுகளை உருவாக்கலாம்.",
                maize_si:"உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோய் எதிர்ப்பு சக்தி கொண்ட மக்காச்சோள விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் பல்வேறு மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக ஹெக்டேருக்கு 15 முதல் 25 கிலோ (13 முதல் 22 பவுண்ட்/ஏக்கர்) வரை இருக்கும்.",
                maize_soi:"மக்காச்சோளம் பொதுவாக வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் மண்ணின் வெப்பநிலை 15 ° C (59 ° F) அல்லது அதற்கு அதிகமாக இருக்கும் போது விதைக்கப்படுகிறது. ஒளிபரப்பு, துளையிடுதல் அல்லது துல்லியமான நடவு முறைகள் மூலம் விதைப்பு செய்யலாம். பரிந்துரைக்கப்பட்ட வரிசை இடைவெளி பொதுவாக 60 முதல் 90 செமீ (24 முதல் 36 அங்குலம்) வரை இருக்கும், மேலும் விதை ஆழம் சுமார் 5 செமீ (2 அங்குலம்) இருக்க வேண்டும்.",
                maize_fu:"மக்காச்சோளம் ஒரு கனமான தீவனம் மற்றும் உகந்த வளர்ச்சி மற்றும் மகசூலுக்கு போதுமான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும். உரங்களின் பிளவு பயன்பாடுகள் பெரும்பாலும் பரிந்துரைக்கப்படுகின்றன.",
                maize_id:"மக்காச்சோளத்திற்கு வளரும் பருவம் முழுவதும், குறிப்பாக பட்டு மற்றும் தானியங்களை நிரப்பும் நிலைகளில் சீரான ஈரப்பதம் தேவைப்படுகிறது. மக்காச்சோள பாசனத்திற்கு உரோமம், தெளிப்பான் அல்லது சொட்டு நீர் பாசன முறைகளைப் பயன்படுத்தலாம். நீர்ப்பாசனத்தின் அதிர்வெண் மற்றும் அளவு மண் வகை, காலநிலை மற்றும் பயிர் வளர்ச்சி நிலை போன்ற காரணிகளைப் பொறுத்தது.",
                maize_pd:"மக்காச்சோளம் தண்டு துளைப்பான்கள், படைப்புழுக்கள், இலை கருகல் மற்றும் காது அழுகல் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகிறது. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                maize_hm:"கர்னல்கள் முழுமையாக முதிர்ச்சியடைந்து காய்ந்தவுடன், மக்காச்சோளம் பொதுவாக ஒரு ஒருங்கிணைந்த அறுவடை இயந்திரத்தைப் பயன்படுத்தி இயந்திரத்தனமாக அறுவடை செய்யப்படுகிறது. அதிகபட்ச மகசூல் மற்றும் தரத்தை உறுதிப்படுத்த சரியான நேரத்தில் அறுவடை முக்கியமானது. சில பகுதிகளில், புதிய நுகர்வு அல்லது தீவனத்திற்காக சோளத்தை கைமுறையாக அறுவடை செய்யலாம்.",
                maize_temp:"15-25° C",
                maize_rain:"60-150 mm",
                maize_stemp:"22-25° C",
                maize_htemp:"28-30° C",


                mango:"மாம்பழம்",
                mango_wr:"மாம்பழங்கள் ஆண்டு முழுவதும் வெப்பமான வெப்பநிலையுடன் வெப்பமண்டல மற்றும் மிதவெப்ப மண்டல காலநிலையில் செழித்து வளரும். மா சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 35°C (68°F முதல் 95°F) வரை இருக்கும். மாம்பழங்கள் சரியான பூக்கும் மற்றும் காய் வளர்ச்சிக்கு 3-4 மாதங்கள் உலர்த்தும் காலம் தேவைப்படுகிறது.",
                mango_sd:"மாம்பழங்களை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 5.5 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, ஆழமான மற்றும் வளமான மண்ணை விரும்புகின்றன. மணற்பாங்கான களிமண் அல்லது நல்ல கரிமச் சத்து கொண்ட களிமண் மண் மா சாகுபடிக்கு ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                mango_cv:"சில பிரபலமான மாம்பழ வகைகளில் அல்போன்சோ, கேசர், தாஷேரி, லாங்க்ரா மற்றும் ஹிம்சாகர் ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, மண் வகை மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                mango_lp:"மாம்பழத் தோட்டங்களுக்கு நிலம் தயாரிப்பது, தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது ஓடு வடிகால் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம்.",
                mango_si:"மாம்பழங்களை விதைகள் (பாலிம்பிரியானிக் வகைகள்) அல்லது ஒட்டுதல் அல்லது காற்று அடுக்கு போன்ற தாவர முறைகள் மூலம் பரப்பலாம். உயர்தர, நோயற்ற மற்றும் உண்மையான வகை நடவுப் பொருட்களைப் பயன்படுத்த வேண்டும்.",
                mango_soi:"நடவு பொதுவாக மழைக்காலத்தில் அல்லது வசந்த காலத்தின் துவக்கத்தில் செய்யப்படுகிறது. மா மரங்களுக்கிடையேயான இடைவெளி வகை மற்றும் மண்ணின் வகையைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக சதுர அல்லது செவ்வக வடிவங்களில் 8 முதல் 12 மீட்டர்கள் (26 முதல் 39 அடி) வரை இருக்கும்.",
                mango_fu:"மா மரங்கள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                mango_id:"மா மரங்கள் வளரும் பருவத்தில், குறிப்பாக பூக்கும் மற்றும் பழம் வளரும் நிலைகளில் சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது மைக்ரோ-ஸ்பிரிங்க்லர் அமைப்புகள் பொதுவாக மா தோட்டங்களுக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                mango_pd:"மா மரங்கள் பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகின்றன, மாம்பழத் தழும்பு, மாவுப்பூச்சி பூச்சி, பூஞ்சை காளான் மற்றும் ஆந்த்ராக்னோஸ் உட்பட. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                mango_hm:"மாம்பழங்கள் பொதுவாக கைகளால் அறுவடை செய்யப்படுகின்றன, சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி அல்லது மரக்கிளைகளை அசைப்பதன் மூலம். பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் முக்கியமானது. அறுவடை செய்யப்பட்ட மாம்பழங்கள் அவற்றின் அடுக்கு ஆயுளை நீட்டிக்கவும், அறுவடைக்குப் பிந்தைய நோய்களைத் தடுக்கவும் பெரும்பாலும் சூடான நீர் அல்லது பூஞ்சைக் கொல்லிகளால் சிகிச்சையளிக்கப்படுகின்றன.",
                mango_temp:"15-25° C",
                mango_rain:"60-150 mm",
                mango_stemp:"22-25° C",
                mango_htemp:"28-30° C",

                moongbeans:"மூங் பீன்ஸ்",
                moongbeans_wr:"மூங் பீன்ஸ் ஒரு சூடான பருவ பயிர் ஆகும், இது வெப்பமான மற்றும் ஈரப்பதமான காலநிலை நிலைகளில் செழித்து வளரும். மூங் பீன் சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 25 ° C முதல் 35 ° C (77 ° F முதல் 95 ° F வரை) வரை இருக்கும். மூங் பீன்களுக்கு 600 முதல் 800 மிமீ (24 முதல் 31 அங்குலம்) ஆண்டு மழை தேவைப்படுகிறது, வளரும் பருவத்தில் நன்கு விநியோகிக்கப்படுகிறது.",
                moongbeans_sd:"மூங் பீன்ஸ் பல்வேறு மண் வகைகளில் வளர்க்கப்படலாம், ஆனால் அவை 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணை விரும்புகின்றன. நல்ல நீரைத் தாங்கும் திறன் கொண்ட மணல் கலந்த செம்மண் அல்லது களிமண் நிலம் சந்திரன் பயிருக்கு ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                moongbeans_cv:"சில பிரபலமான மூங் பீன் வகைகளில் பூசா விஷால், பூசா ரத்னா, பந்த் மூங் 2 மற்றும் பிடிஎம் 139 ஆகியவை அடங்கும். காலநிலை, முதிர்வு காலம் மற்றும் நோய் எதிர்ப்புத் தன்மை போன்ற காரணிகளைப் பொறுத்து வகையின் தேர்வு அமைகிறது.",
                moongbeans_lp:"சந்திரன் பீன் சாகுபடிக்கு நிலம் தயாரிப்பதில் ஆழமாக உழுதல் அல்லது பயிரிடுதல் ஆகியவை அடங்கும், இது கடினமான நிலத்தை உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்கிறது. ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும்.",
                moongbeans_si:"உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற நிலவேம்பு விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் 15 முதல் 20 கிலோ/எக்டர் (13 முதல் 18 பவுண்ட்/ஏக்கர்) வரை மாறுபடும், இது வகை மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும்.",
                moongbeans_soi:"மண்ணின் வெப்பநிலை 20 டிகிரி செல்சியஸ் (68 டிகிரி பாரன்ஹீட்) அல்லது அதற்கும் அதிகமாக இருக்கும் போது சந்திரன் பீன்ஸ் வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் விதைக்கலாம். விதைகளை 3-5 செமீ (1.2-2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 30-45 செமீ (12-18 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                moongbeans_fu:"மூங் பீன்ஸில் மிதமான ஊட்டச்சத்து தேவை உள்ளது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை தேவையான முதன்மை ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும்.",
                moongbeans_id:"மூங் பீன்ஸ் வளரும் பருவத்தில், குறிப்பாக பூக்கும் மற்றும் காய்களை நிரப்பும் நிலைகளில் மிதமான ஈரப்பதம் தேவைப்படுகிறது. போதுமான அல்லது ஒழுங்கற்ற மழைப்பொழிவு உள்ள பகுதிகளில் நீர்ப்பாசனம் தேவைப்படலாம். நிலவேம்பு சாகுபடிக்கு சால் அல்லது தெளிப்பு நீர்ப்பாசன முறைகளைப் பயன்படுத்தலாம்.",
                moongbeans_pd:"அஃபிட்ஸ், காய் துளைப்பான்கள், மஞ்சள் மொசைக் வைரஸ் மற்றும் நுண்துகள் பூஞ்சை காளான் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு மூங் பீன்ஸ் எளிதில் பாதிக்கப்படுகிறது. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                moongbeans_hm:"காய்கள் பழுப்பு நிறமாகி, இலைகள் காய்ந்து போகத் தொடங்கும் போது மூங் பீன்ஸ் பொதுவாக அறுவடை செய்யப்படுகிறது. தாவரங்கள் கைமுறையாகவோ அல்லது இயந்திரமாகவோ ஒருங்கிணைந்த அறுவடை இயந்திரத்தைப் பயன்படுத்தி வெட்டப்படுகின்றன. அறுவடை செய்யப்பட்ட பயிரை சரியான முறையில் உலர்த்துதல் மற்றும் கதிரடித்தல் ஆகியவை சந்திரன் பீன்ஸின் தரத்தை பராமரிக்க முக்கியம்.",
                moongbeans_temp:"15-25° C",
                moongbeans_rain:"60-150 mm",
                moongbeans_stemp:"22-25° C",
                moongbeans_htemp:"28-30° C",


                mothbeans:"அந்துப்பூச்சி",
                mothbeans_wr:"மோத்பீன்ஸ் வறட்சியைத் தாங்கும் பயிர் ஆகும், இது வறண்ட மற்றும் அரை வறண்ட பகுதிகளில் பயிரிடப்படுகிறது. அவை 25°C முதல் 40°C (77°F முதல் 104°F வரை) வரை வெப்பமான மற்றும் வறண்ட காலநிலையில் செழித்து வளரும். அந்துப்பூச்சிகளுக்கு ஆண்டுதோறும் 300 முதல் 500 மிமீ (12 முதல் 20 அங்குலம்) மழை தேவைப்படுகிறது.",
                mothbeans_sd:"அந்துப்பூச்சிகளை மணல், களிமண் மற்றும் மிதமான உப்புத்தன்மை கொண்ட மண் உள்ளிட்ட பல்வேறு வகையான மண் வகைகளில் வளர்க்கலாம். அவர்கள் 7.0 முதல் 8.5 வரை pH வரம்பில் நன்கு வடிகட்டிய மண்ணை விரும்புகிறார்கள். அந்துப்பூச்சிகள் குறைந்த மண் வளத்தை பொறுத்துக்கொள்ளும் ஆனால் நல்ல கரிமப் பொருட்கள் உள்ள மண்ணில் சிறப்பாக செயல்படும்.",
                mothbeans_cv:"சில பிரபலமான அந்துப்பூச்சி வகைகளில் ஆர்எம்ஓ 40, ஆர்எம்ஓ 225, ஆர்எம்ஓ 257 மற்றும் ஆர்எம்ஓ 435 ஆகியவை அடங்கும். இந்த வகைகள் வறட்சியைத் தாங்கும் தன்மை, பூச்சி பூச்சிகள் மற்றும் நோய்களுக்கு எதிர்ப்பு மற்றும் அதிக மகசூல் திறனுக்காக அறியப்படுகின்றன.",
                mothbeans_lp:"அந்துப்பூச்சி சாகுபடிக்கு சரியான நிலத்தை தயார் செய்வது அவசியம். வயலை ஆழமாக உழவு செய்து, எந்த ஒரு கடினத்தையும் உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். ஒரு மென்மையான விதைப்பாதையை உருவாக்க டிஸ்கிங் மற்றும் ஹாரோயிங் செய்ய வேண்டும்.",
                mothbeans_si:"உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற அந்துப்பூச்சி விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் எக்டருக்கு 15 முதல் 20 கிலோ (13 முதல் 18 பவுண்ட்/ஏக்கருக்கு), பல்வேறு மற்றும் நடவு முறையைப் பொறுத்து.",
                mothbeans_soi:"அந்துப்பூச்சிகள் பொதுவாக வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் மண்ணின் வெப்பநிலை 25 ° C (77 ° F) அல்லது அதற்கும் அதிகமாக இருக்கும் போது விதைக்கப்படுகிறது. விதைகளை 3-5 செமீ (1.2-2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 30-45 செமீ (12-18 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                mothbeans_fu:"அந்துப்பூச்சிகளுக்கு மிதமான ஊட்டச்சத்து தேவை. நைட்ரஜன் மற்றும் பாஸ்பரஸ் ஆகியவை முதன்மையான ஊட்டச்சத்துக்கள். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும்.",
                mothbeans_id:"அந்துப்பூச்சிகள் வறட்சியைத் தாங்கும் தன்மை கொண்டவை மற்றும் குறைந்த மழைப்பொழிவு உள்ள பகுதிகளில் மானாவாரி நிலங்களில் பயிரிடலாம். இருப்பினும், நீண்ட கால வறட்சியின் போது, ​​குறிப்பாக பூக்கும் மற்றும் காய்கள் நிரப்பும் நிலைகளில் கூடுதல் நீர்ப்பாசனம் தேவைப்படலாம்.",
                mothbeans_pd:"மொத்பீன்ஸ் மற்ற பயறு வகை பயிர்களுடன் ஒப்பிடும்போது பூச்சிகள் மற்றும் நோய்களுக்கு ஒப்பீட்டளவில் எதிர்ப்புத் திறன் கொண்டது. இருப்பினும், அவை அசுவினி, காய் துளைப்பான் போன்ற பூச்சிகளாலும், நுண்துகள் பூஞ்சை காளான் மற்றும் துரு போன்ற நோய்களாலும் பாதிக்கப்படலாம். பயிர்களைப் பாதுகாக்க கலாச்சார, உயிரியல் மற்றும் இரசாயன கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                mothbeans_hm:"அந்துப்பூச்சிகள் பொதுவாக காய்கள் பழுப்பு நிறமாகி இலைகள் உலரத் தொடங்கும் போது அறுவடை செய்யப்படுகின்றன. தாவரங்கள் கைமுறையாகவோ அல்லது இயந்திரமாகவோ ஒருங்கிணைந்த அறுவடை இயந்திரத்தைப் பயன்படுத்தி வெட்டப்படுகின்றன. அந்துப்பூச்சிகளின் தரத்தை பராமரிக்க அறுவடை செய்யப்பட்ட பயிரை சரியான முறையில் உலர்த்துதல் மற்றும் கதிரடித்தல் ஆகியவை முக்கியமானவை.",
               mothbeans_temp:"15-25° C",
                mothbeans_rain:"60-150 mm",
                mothbeans_stemp:"22-25° C",
                mothbeans_htemp:"28-30° C",

                muskmelon:"முலாம்பழம்",
                muskmelon_wr:"அந்துப்பூச்சிகள் பொதுவாக காய்கள் பழுப்பு நிறமாகி இலைகள் உலரத் தொடங்கும் போது அறுவடை செய்யப்படுகின்றன. தாவரங்கள் கைமுறையாகவோ அல்லது இயந்திரமாகவோ ஒருங்கிணைந்த அறுவடை இயந்திரத்தைப் பயன்படுத்தி வெட்டப்படுகின்றன. அந்துப்பூச்சிகளின் தரத்தை பராமரிக்க அறுவடை செய்யப்பட்ட பயிரை சரியான முறையில் உலர்த்துதல் மற்றும் கதிரடித்தல் ஆகியவை முக்கியமானவை.",
                muskmelon_sd:"முலாம்பழங்கள் 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணில் சிறப்பாக வளரும். நல்ல கரிமப் பொருட்கள் கொண்ட மணல் கலந்த களிமண் அல்லது களிமண் மண் கஸ்தூரி சாகுபடிக்கு ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                muskmelon_cv:"சில பிரபலமான கஸ்தூரி வகைகளில் கான்டாலூப், ஹாமி மெலன், கேலியா, சரெண்டாய்ஸ் மற்றும் கசாபா ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, நோய் எதிர்ப்பு மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                muskmelon_lp:"முலாம்பழம் பயிரிடுவதற்கு நிலம் தயாரிப்பதில் ஆழமான உழுதல் அல்லது பயிரிடுதல் ஆகியவை கடினப் பகுதியை உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். வடிகால் வசதியை மேம்படுத்தவும், சால் நீர்ப்பாசனத்தை எளிதாக்கவும் உயர்த்தப்பட்ட படுக்கைகள் அல்லது முகடுகளை உருவாக்கலாம்.",
                muskmelon_si:"உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோய் இல்லாத முலாம்பழம் விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் 1.5 முதல் 2.5 கிலோ/ஹெக்டர் (1.3 முதல் 2.2 பவுண்ட்/ஏக்கர்) வரை மாறுபடும், இது வகை மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும்.",
                muskmelon_soi:"மண்ணின் வெப்பநிலை 20 டிகிரி செல்சியஸ் (68 டிகிரி பாரன்ஹீட்) அல்லது அதற்கும் அதிகமாக இருக்கும்போது கஸ்தூரி பொதுவாக வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் விதைக்கப்படுகிறது. விதைகளை 2-3 செமீ (0.8-1.2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 60-90 செமீ (24-36 அங்குலம்) இடைவெளியிலும், செடிகளுக்கு இடையே 30-45 செமீ (12-18 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                muskmelon_fu:"முலாம்பழங்களில் மிதமான ஊட்டச்சத்து தேவை உள்ளது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை போரான் மற்றும் துத்தநாகம் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும்.",
                muskmelon_id:"முலாம்பழங்களுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது சால் நீர்ப்பாசன முறைகள் பொதுவாக கஸ்தூரி சாகுபடிக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                muskmelon_pd:"அஃபிட்ஸ், சிலந்திப் பூச்சிகள், நுண்துகள் பூஞ்சை காளான் மற்றும் பூஞ்சை காளான் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு முலாம்பழங்கள் எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                muskmelon_hm:"பழம் கொடியிலிருந்து எளிதில் நழுவி, பழத்திலிருந்து தண்டு சுத்தமாக பிரியும் போது, ​​கஸ்தூரி பொதுவாக கையால் அறுவடை செய்யப்படுகிறது. பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் மிகவும் முக்கியமானது. அறுவடை செய்யப்பட்ட முலாம்பழங்களை சிராய்ப்பு மற்றும் சேதம் தவிர்க்க கவனமாக கையாள வேண்டும்.",
                               muskmelon_temp:"15-25° C",
                muskmelon_rain:"60-150 mm",
                muskmelon_stemp:"22-25° C",
                muskmelon_htemp:"28-30° C",


                orange:"ஆரஞ்சு",
                orange_wr:"ஆரஞ்சுகள் ஆண்டு முழுவதும் வெப்பமான வெப்பநிலை மற்றும் ஏராளமான சூரிய ஒளியுடன் துணை வெப்பமண்டலத்தில் இருந்து வெப்பமண்டல காலநிலையில் செழித்து வளரும். ஆரஞ்சு சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 30°C (68°F முதல் 86°F வரை) வரை இருக்கும். ஆரஞ்சு பழங்களுக்கு குளிர்ச்சியான குளிர்காலம் மற்றும் உறைபனிக்கு மேல் வெப்பநிலை மற்றும் சூடான, வறண்ட வசந்தம் மற்றும் கோடைக்காலம் ஆகியவை உகந்த பழ வளர்ச்சிக்கு தேவைப்படுகிறது.",
                orange_sd:"ஆரஞ்சுகளை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, ஆழமான மற்றும் வளமான மண்ணை விரும்புகின்றன. ஆரஞ்சு சாகுபடிக்கு நல்ல கரிமப் பொருட்கள் உள்ள மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                orange_cv:"சில பிரபலமான ஆரஞ்சு வகைகளில் வலென்சியா, நேவல், ஹாம்லின், பேரா மற்றும் ஜாஃபா ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, நோய் எதிர்ப்பு மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                orange_lp:"ஆரஞ்சு பழத்தோட்டங்களுக்கு நிலம் தயாரிப்பது, தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது ஓடு வடிகால் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம்.",
                orange_si:"ஆரஞ்சுகள் பொதுவாக வேர் தண்டுகளில் ஒட்டுதல் அல்லது துளிர்த்தல் மூலம் பரப்பப்படுகின்றன. பூச்சிகள், நோய்கள் அல்லது மண்ணின் நிலைகளுக்கு அதன் எதிர்ப்பிற்காக ஆணிவேர் தேர்ந்தெடுக்கப்படுகிறது. உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற நடவுப் பொருட்களைப் பயன்படுத்த வேண்டும்.",
                orange_soi:"நடவு பொதுவாக வசந்த காலத்தின் துவக்கத்தில் அல்லது கடைசி உறைபனிக்குப் பிறகு செய்யப்படுகிறது. ஆரஞ்சு மரங்களுக்கிடையேயான இடைவெளி பல்வேறு மற்றும் வேர்த்தண்டுகளைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக சதுர அல்லது செவ்வக வடிவங்களில் 6 முதல் 8 மீட்டர்கள் (20 முதல் 26 அடி) வரை இருக்கும்.",
                orange_fu:"ஆரஞ்சு மரங்கள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                orange_id:"ஆரஞ்சு மரங்களுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது மைக்ரோ-ஸ்பிரிங்க்லர் அமைப்புகள் பொதுவாக ஆரஞ்சு பழத்தோட்டங்களுக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                orange_pd:"ஆரஞ்சு மரங்கள் சிட்ரஸ் லீஃப்மைனர், சிட்ரஸ் டிரிஸ்டெசா வைரஸ், சிட்ரஸ் கிரீனிங் நோய் மற்றும் சிட்ரஸ் புற்றுநோய் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                orange_hm:"ஆரஞ்சு பொதுவாக கையால் அறுவடை செய்யப்படுகிறது, சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி அல்லது மரக்கிளைகளை அசைப்பதன் மூலம். பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் முக்கியமானது. அறுவடை செய்யப்பட்ட ஆரஞ்சுகள் அவற்றின் அடுக்கு ஆயுளை நீட்டிக்கவும், தோற்றத்தை அதிகரிக்கவும் பெரும்பாலும் பூஞ்சைக் கொல்லிகள் அல்லது மெழுகுகளால் சிகிச்சையளிக்கப்படுகின்றன.",
                orange_temp:"15-25° C",
                orange_rain:"60-150 mm",
                orange_stemp:"22-25° C",
                orange_htemp:"28-30° C",


                papaya:"ரபலமா",
                papaya_wr:"ஆரஞ்சுகள் ஆண்டு முழுவதும் வெப்பமான வெப்பநிலை மற்றும் ஏராளமான சூரிய ஒளியுடன் துணை வெப்பமண்டலத்தில் இருந்து வெப்பமண்டல காலநிலையில் செழித்து வளரும். ஆரஞ்சு சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 30°C (68°F முதல் 86°F வரை) வரை இருக்கும். ஆரஞ்சு பழங்களுக்கு குளிர்ச்சியான குளிர்காலம் மற்றும் உறைபனிக்கு மேல் வெப்பநிலை மற்றும் சூடான, வறண்ட வசந்தம் மற்றும் கோடைக்காலம் ஆகியவை உகந்த பழ வளர்ச்சிக்கு தேவைப்படுகிறது.",
                papaya_sd:"ஆரஞ்சுகளை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, ஆழமான மற்றும் வளமான மண்ணை விரும்புகின்றன. ஆரஞ்சு சாகுபடிக்கு நல்ல கரிமப் பொருட்கள் உள்ள மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                papaya_cv:"சில பிரபலமான ஆரஞ்சு வகைகளில் வலென்சியா, நேவல், ஹாம்லின், பேரா மற்றும் ஜாஃபா ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, நோய் எதிர்ப்பு மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                papaya_lp:"ஆரஞ்சு பழத்தோட்டங்களுக்கு நிலம் தயாரிப்பது, தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது ஓடு வடிகால் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம்.",
                papaya_si:"ஆரஞ்சுகள் பொதுவாக வேர் தண்டுகளில் ஒட்டுதல் அல்லது துளிர்த்தல் மூலம் பரப்பப்படுகின்றன. பூச்சிகள், நோய்கள் அல்லது மண்ணின் நிலைகளுக்கு அதன் எதிர்ப்பிற்காக ஆணிவேர் தேர்ந்தெடுக்கப்படுகிறது. உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற நடவுப் பொருட்களைப் பயன்படுத்த வேண்டும்.",
                papaya_soi:"நடவு பொதுவாக வசந்த காலத்தின் துவக்கத்தில் அல்லது கடைசி உறைபனிக்குப் பிறகு செய்யப்படுகிறது. ஆரஞ்சு மரங்களுக்கிடையேயான இடைவெளி பல்வேறு மற்றும் வேர்த்தண்டுகளைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக சதுர அல்லது செவ்வக வடிவங்களில் 6 முதல் 8 மீட்டர்கள் (20 முதல் 26 அடி) வரை இருக்கும்.",
                papaya_fu:"ஆரஞ்சு மரங்கள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                papaya_id:"ஆரஞ்சு மரங்களுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது மைக்ரோ-ஸ்பிரிங்க்லர் அமைப்புகள் பொதுவாக ஆரஞ்சு பழத்தோட்டங்களுக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                papaya_pd:"ஆரஞ்சு மரங்கள் சிட்ரஸ் லீஃப்மைனர், சிட்ரஸ் டிரிஸ்டெசா வைரஸ், சிட்ரஸ் கிரீனிங் நோய் மற்றும் சிட்ரஸ் புற்றுநோய் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                papaya_hm:"ஆரஞ்சு பொதுவாக கையால் அறுவடை செய்யப்படுகிறது, சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி அல்லது மரக்கிளைகளை அசைப்பதன் மூலம். பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் முக்கியமானது. அறுவடை செய்யப்பட்ட ஆரஞ்சுகள் அவற்றின் அடுக்கு ஆயுளை நீட்டிக்கவும், தோற்றத்தை அதிகரிக்கவும் பெரும்பாலும் பூஞ்சைக் கொல்லிகள் அல்லது மெழுகுகளால் சிகிச்சையளிக்கப்படுகின்றன.",
                papaya_temp:"15-25° C",
                papaya_rain:"60-150 mm",
                papaya_stemp:"22-25° C",
                papaya_htemp:"28-30° C",


                pigeonpeas:"சான்றளிக்கப்ப",
                pigeonpeas_wr:"ஆரஞ்சுகள் ஆண்டு முழுவதும் வெப்பமான வெப்பநிலை மற்றும் ஏராளமான சூரிய ஒளியுடன் துணை வெப்பமண்டலத்தில் இருந்து வெப்பமண்டல காலநிலையில் செழித்து வளரும். ஆரஞ்சு சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 30°C (68°F முதல் 86°F வரை) வரை இருக்கும். ஆரஞ்சு பழங்களுக்கு குளிர்ச்சியான குளிர்காலம் மற்றும் உறைபனிக்கு மேல் வெப்பநிலை மற்றும் சூடான, வறண்ட வசந்தம் மற்றும் கோடைக்காலம் ஆகியவை உகந்த பழ வளர்ச்சிக்கு தேவைப்படுகிறது.",
                pigeonpeas_sd:"ஆரஞ்சுகளை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 6.0 முதல் 7.5 pH வரம்பில் நன்கு வடிகட்டிய, ஆழமான மற்றும் வளமான மண்ணை விரும்புகின்றன. ஆரஞ்சு சாகுபடிக்கு நல்ல கரிமப் பொருட்கள் உள்ள மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. மோசமான வடிகால் அல்லது அதிக உப்புத்தன்மை கொண்ட மண்ணைத் தவிர்க்க வேண்டும்.",
                pigeonpeas_cv:"சில பிரபலமான ஆரஞ்சு வகைகளில் வலென்சியா, நேவல், ஹாம்லின், பேரா மற்றும் ஜாஃபா ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, நோய் எதிர்ப்பு மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                pigeonpeas_lp:"ஆரஞ்சு பழத்தோட்டங்களுக்கு நிலம் தயாரிப்பது, தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது ஓடு வடிகால் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம்.",
                pigeonpeas_si:"ஆரஞ்சுகள் பொதுவாக வேர் தண்டுகளில் ஒட்டுதல் அல்லது துளிர்த்தல் மூலம் பரப்பப்படுகின்றன. பூச்சிகள், நோய்கள் அல்லது மண்ணின் நிலைகளுக்கு அதன் எதிர்ப்பிற்காக ஆணிவேர் தேர்ந்தெடுக்கப்படுகிறது. உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற நடவுப் பொருட்களைப் பயன்படுத்த வேண்டும்.",
                pigeonpeas_soi:"நடவு பொதுவாக வசந்த காலத்தின் துவக்கத்தில் அல்லது கடைசி உறைபனிக்குப் பிறகு செய்யப்படுகிறது. ஆரஞ்சு மரங்களுக்கிடையேயான இடைவெளி பல்வேறு மற்றும் வேர்த்தண்டுகளைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக சதுர அல்லது செவ்வக வடிவங்களில் 6 முதல் 8 மீட்டர்கள் (20 முதல் 26 அடி) வரை இருக்கும்.",
                pigeonpeas_fu:"ஆரஞ்சு மரங்கள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                pigeonpeas_id:"ஆரஞ்சு மரங்களுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது மைக்ரோ-ஸ்பிரிங்க்லர் அமைப்புகள் பொதுவாக ஆரஞ்சு பழத்தோட்டங்களுக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                pigeonpeas_pd:"ஆரஞ்சு மரங்கள் சிட்ரஸ் லீஃப்மைனர், சிட்ரஸ் டிரிஸ்டெசா வைரஸ், சிட்ரஸ் கிரீனிங் நோய் மற்றும் சிட்ரஸ் புற்றுநோய் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு ஆளாகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                pigeonpeas_hm:"ஆரஞ்சு பொதுவாக கையால் அறுவடை செய்யப்படுகிறது, சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி அல்லது மரக்கிளைகளை அசைப்பதன் மூலம். பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் முக்கியமானது. அறுவடை செய்யப்பட்ட ஆரஞ்சுகள் அவற்றின் அடுக்கு ஆயுளை நீட்டிக்கவும், தோற்றத்தை அதிகரிக்கவும் பெரும்பாலும் பூஞ்சைக் கொல்லிகள் அல்லது மெழுகுகளால் சிகிச்சையளிக்கப்படுகின்றன.",
                pigeonpeas_temp:"15-25° C",
                pigeonpeas_rain:"60-150 mm",
                pigeonpeas_stemp:"22-25° C",
                pigeonpeas_htemp:"28-30° C",

                pomegranate:"மாதுளை",
                pomegranate_wr:"மாதுளை அரை வறண்ட காலநிலை முதல் மிதவெப்ப மண்டல காலநிலை வரை வெப்பமான, வறண்ட கோடை மற்றும் மிதமான குளிர்காலத்தில் செழித்து வளரும். வளரும் பருவத்தில் மாதுளை சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 20°C முதல் 35°C (68°F முதல் 95°F) வரை இருக்கும். மாதுளைகள் குளிர்காலத்தில் 7°C (45°F) க்குக் கீழே 100 முதல் 200 மணிநேரம் வரை குளிர்ச்சியான காலம் தேவைப்படுகிறது.",
                pomegranate_sd:"மாதுளையை பல்வேறு மண் வகைகளில் வளர்க்கலாம், ஆனால் அவை 5.5 முதல் 7.5 வரை pH வரம்பைக் கொண்ட நடுநிலை மண்ணிலிருந்து நன்கு வடிகட்டிய, சற்று அமிலத்தன்மை கொண்ட மண்ணை விரும்புகின்றன. மாதுளை சாகுபடிக்கு நல்ல கரிமப் பொருட்கள் உள்ள மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. மாதுளை உப்பு மண்ணை மிதமாக பொறுத்துக்கொள்ளும்.",
                pomegranate_cv:"சில பிரபலமான மாதுளை வகைகளில் வொண்டர்ஃபுல், பக்வா, மிருதுளா, கணேஷ் மற்றும் ரூபி ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு, காலநிலை, பழ அளவு, நிறம் மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                pomegranate_lp:"மாதுளைத் தோட்டங்களுக்கு நிலம் தயாரிப்பது, தற்போதுள்ள தாவரங்களின் பரப்பளவை அகற்றுவது, ஸ்டம்புகள் மற்றும் பாறைகளை அகற்றுவது மற்றும் தேவைப்பட்டால் நிலத்தை சமன் செய்வது ஆகியவை அடங்கும். நீர் தேங்குவதைத் தடுக்க பள்ளங்கள் அல்லது உயர்த்தப்பட்ட படுக்கைகள் போன்ற முறையான வடிகால் அமைப்புகளை நிறுவலாம்.",
                pomegranate_si:"மாதுளை பொதுவாக வெட்டல் அல்லது வேர் தண்டுகளில் ஒட்டுதல் மூலம் பரப்பப்படுகிறது. உண்மையான வகை தாவரங்கள் மற்றும் நோயற்ற இருப்பை உறுதி செய்ய உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற நடவுப் பொருட்களைப் பயன்படுத்த வேண்டும்.",
                pomegranate_soi:"நடவு பொதுவாக வசந்த காலத்தின் துவக்கத்தில் அல்லது கடைசி உறைபனிக்குப் பிறகு செய்யப்படுகிறது. மாதுளை மரங்களுக்கிடையேயான இடைவெளி பல்வேறு மற்றும் வேர்த்தண்டுகளைப் பொறுத்து மாறுபடும் ஆனால் பொதுவாக சதுர அல்லது செவ்வக வடிவங்களில் 4 முதல் 6 மீட்டர்கள் (13 முதல் 20 அடி) வரை இருக்கும்.",
                pomegranate_fu:"மாதுளை மரங்கள் உகந்த வளர்ச்சி மற்றும் மகசூலை பராமரிக்க சீரான உரமிடுதல் தேவைப்படுகிறது. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை துத்தநாகம் மற்றும் போரான் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகளைத் தீர்மானிக்க மண் பரிசோதனை நடத்தப்பட வேண்டும்.",
                pomegranate_id:"மாதுளை மரங்களுக்கு வளரும் பருவம் முழுவதும், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. சொட்டு நீர் பாசனம் அல்லது நுண்ணிய தெளிப்பான் முறைகள் பொதுவாக மாதுளை தோட்டங்களுக்கு பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குகின்றன மற்றும் நீர் விரயத்தை குறைக்கின்றன.",
                pomegranate_pd:"பழம் துளைப்பான்கள், இலை மற்றும் காய்ப்புள்ளி நோய்கள், வாடல் மற்றும் பாக்டீரியா கருகல் நோய் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு மாதுளை மரங்கள் எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                pomegranate_hm:"மாதுளை பொதுவாக கைகளால் அறுவடை செய்யப்படுகிறது, சிறப்பு அறுவடை கருவிகளைப் பயன்படுத்தி அல்லது மரக்கிளைகளை அசைப்பதன் மூலம். பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் மிகவும் முக்கியமானது. அறுவடை செய்யப்பட்ட மாதுளைகளை சிராய்ப்பு மற்றும் சேதம் தவிர்க்க கவனமாக கையாள வேண்டும்.",
                pomegranate_temp:"15-25° C",
                pomegranate_rain:"60-150 mm",
                pomegranate_stemp:"22-25° C",
                pomegranate_htemp:"28-30° C",


                watermelon:"தர்பூசணி",
                watermelon_wr:"தர்பூசணிகள் சூடான பருவ பயிர்கள் ஆகும், அவை வளரும் பருவத்தில் சூடான வெப்பநிலை மற்றும் ஏராளமான சூரிய ஒளி தேவைப்படும். தர்பூசணி சாகுபடிக்கு உகந்த வெப்பநிலை வரம்பு 25°C முதல் 35°C (77°F முதல் 95°F வரை) வரை இருக்கும். தர்பூசணிகளுக்கு குறைந்தபட்சம் 90 முதல் 120 நாட்கள் வரை நீண்ட, சூடான வளரும் பருவம் தேவைப்படுகிறது.",
                watermelon_sd:"தர்பூசணிகள் 6.0 முதல் 7.0 pH வரம்பில் நன்கு வடிகட்டிய, வளமான மண்ணில் சிறப்பாக வளரும். தர்பூசணி சாகுபடிக்கு நல்ல கரிமப் பொருட்கள் உள்ள மணல் கலந்த களிமண் அல்லது களிமண் மண் ஏற்றது. தர்பூசணிகள் கனமான களிமண் அல்லது உப்பு மண்ணை பொறுத்துக்கொள்ளாது.",
                watermelon_cv:"சில பிரபலமான தர்பூசணி வகைகளில் கிரிம்சன் ஸ்வீட், சுகர் பேபி, சார்லஸ்டன் கிரே, சங்ரியா மற்றும் மஞ்சள் பொம்மை ஆகியவை அடங்கும். பல்வேறு வகைகளின் தேர்வு காலநிலை, நோய் எதிர்ப்பு, பழத்தின் அளவு மற்றும் நோக்கம் கொண்ட பயன்பாடு (புதிய நுகர்வு அல்லது செயலாக்கம்) போன்ற காரணிகளைப் பொறுத்தது.",
                watermelon_lp:"தர்பூசணி பயிரிடுவதற்கு நிலம் தயாரிப்பதில் ஆழமாக உழுதல் அல்லது பயிரிடுதல் ஆகியவை கடினப் பகுதியை உடைத்து நல்ல மண் காற்றோட்டத்தை உறுதி செய்ய வேண்டும். வடிகால் வசதியை மேம்படுத்தவும், சால் நீர்ப்பாசனத்தை எளிதாக்கவும் உயர்த்தப்பட்ட படுக்கைகள் அல்லது முகடுகளை உருவாக்கலாம்.",
                watermelon_si:"உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயற்ற தர்பூசணி விதைகளை விதைப்பதற்கு பயன்படுத்த வேண்டும். பரிந்துரைக்கப்பட்ட விதைப்பு விகிதம் 1.5 முதல் 2.5 கிலோ/ஹெக்டர் (1.3 முதல் 2.2 பவுண்ட்/ஏக்கர்) வரை மாறுபடும், இது வகை மற்றும் நடவு முறையைப் பொறுத்து மாறுபடும்",
                watermelon_soi:"தர்பூசணிகள் பொதுவாக வசந்த காலத்தின் பிற்பகுதியில் அல்லது கோடையின் தொடக்கத்தில் மண்ணின் வெப்பநிலை 20 ° C (68 ° F) அல்லது அதற்கு அதிகமாக இருக்கும் போது விதைக்கப்படுகிறது. விதைகளை 2-3 செமீ (0.8-1.2 அங்குலம்) ஆழத்திலும், வரிசைகளுக்கு இடையே 1.5-2 மீட்டர் (5-6.5 அடி) இடைவெளியிலும், மலைகள் அல்லது செடிகளுக்கு இடையே 60-90 செமீ (24-36 அங்குலம்) இடைவெளியிலும் விதைக்க வேண்டும்.",
                watermelon_fu:"தர்பூசணிகளுக்கு மிதமான ஊட்டச்சத்து தேவை. நைட்ரஜன், பாஸ்பரஸ் மற்றும் பொட்டாசியம் ஆகியவை போரான் மற்றும் துத்தநாகம் போன்ற நுண்ணூட்டச்சத்துக்களுடன் தேவையான முதன்மை ஊட்டச்சத்து ஆகும். குறிப்பிட்ட உரத் தேவைகள் மண் வளத்தின் அளவைப் பொறுத்தது மற்றும் மண் பரிசோதனை மூலம் தீர்மானிக்கப்பட வேண்டும்.",
                watermelon_id:"தர்பூசணிகள் வளரும் பருவத்தில், குறிப்பாக பூக்கும் மற்றும் பழ வளர்ச்சியின் போது சீரான ஈரப்பதம் தேவைப்படுகிறது. தர்பூசணி சாகுபடிக்கு சால் அல்லது சொட்டு நீர் பாசன முறைகள் பொதுவாகப் பயன்படுத்தப்படுகின்றன, ஏனெனில் அவை துல்லியமான நீர் விநியோகத்தை வழங்குவதோடு நீர் விரயத்தைக் குறைக்கும்.",
                watermelon_pd:"அஃபிட்ஸ், வெள்ளரி வண்டுகள், ஃபுசேரியம் வில்ட் மற்றும் ஆந்த்ராக்னோஸ் உள்ளிட்ட பல்வேறு பூச்சிகள் மற்றும் நோய்களுக்கு தர்பூசணிகள் எளிதில் பாதிக்கப்படுகின்றன. பயிர்களைப் பாதுகாக்கவும், நிலையான உற்பத்தியை உறுதிப்படுத்தவும் கலாச்சார, உயிரியல் மற்றும் இரசாயனக் கட்டுப்பாட்டு முறைகளை உள்ளடக்கிய ஒருங்கிணைந்த பூச்சி மேலாண்மை உத்திகள் செயல்படுத்தப்பட வேண்டும்.",
                watermelon_hm:"தர்பூசணிகள் பொதுவாக பழத்தின் அடிப்பகுதி (தொப்பை) மஞ்சள்-வெள்ளை நிறமாக மாறும்போதும், பழத்தின் தண்டுக்கு அருகில் உள்ள தண்டு பழுப்பு நிறமாகவும் உலர்ந்ததாகவும் மாறும் போது கையால் அறுவடை செய்யப்படுகிறது. பழங்களின் தரம் மற்றும் சுவையை உறுதி செய்ய சரியான அறுவடை நேரம் மிகவும் முக்கியமானது. அறுவடை செய்யப்பட்ட தர்பூசணிகள் சிராய்ப்பு மற்றும் சேதத்தைத் தவிர்க்க கவனமாக கையாள வேண்டும்.",
                watermelon_temp:"15-25° C",
                watermelon_rain:"60-150 mm",
                watermelon_stemp:"22-25° C",
                watermelon_htemp:"28-30° C",
                crop_info:"பயிர் தகவல்",

rd:"🌾 நாடுகளுக்கு உணவளிக்கும் எளிய தானியம். பசுமையான நெற்பயிர்கள் ஊட்டச்சத்தின் தங்கப் பொக்கிஷங்களுக்கு வழிவகுக்கின்றன.",
md:"🌽 உயர்தர, சான்றளிக்கப்பட்ட மற்றும் நோயண்டுகள் சத்தான, இனிப்பு கர்னல்கள் நிறைந்த சின்னமான கோப்களைக் கொண்டுள்ளன.",
wd:"🌾 ஒரு பழங்கால வாழ்க்கை ஊழியர். அசையும் ஆம்பர் வயல்களால் உலகம் முழுவதும் ரொட்டிகள் கிடைக்கும்.",
kbd:"🫘 இயற்கையின் மாணிக்கங்களைத் தரும் வீரியமுள்ள கொடிகள். ஒவ்வொரு நெற்றும் புரதம் நிரம்பிய ரத்தினங்களின் தொகுப்பை அடைக்கலம் தருகிறது.",
mmd:"🍈 அமுத ஆனந்தத்தின் நறுமண உருண்டைகள். நெட்டட் ரைண்ட்ஸ் உள்ளே உள்ள ரம்மியமான, தேன்-இனிமையான இதயத்தைத் தழுவுகிறது.",
jd:"🌿 பல்துறை இழைகளின் தொகுப்பு. மிருதுவான இலைகள் உறுதியான துணி மற்றும் அலங்காரப் பொருட்களாக சுழல்கின்றன.",
cofd:"☕ அஃபிட்ஸ், வெள்ளரி வண்டுகள், ஃபுசேரியம் வில்ட் மற்றும் ம். இந்த சிறிய பெர்ரி ஒரு அமுத அமுதத்தை அளிக்கிறது, இது உலகை சலசலக்க வைக்கிறது.",
cpd:"🌰 தாழ்மையான பூமி நகங்கள். இந்த தங்க விதைகள் பல நூற்றாண்டுகளாக பசியைப் போக்கிய ஊட்டச்சத்தை பதுக்கி வைத்துள்ளன.",
bad:"🍌 இயற்கையின் இனிய பரிசு. முதுகை உரிப்பது அனைத்து வயதினருக்கும் சுவை மொட்டுகளை மகிழ்விக்கும் சத்தான மஞ்சள் நிறத்தை வெளிப்படுத்துகிறது.",
bgd:"🌱 தாழ்மையான விதைகள் ஒரு வலிமையான பஞ்சைக் கட்டுகின்றன. புரதம் மற்றும் முக்கிய ஊட்டச்சத்துக்கள் நிறைந்த சிறிய கருப்பு முத்துக்கள்.",
cod:"🥥 தீவின் மந்திர பிரசாதம். பழங்கள், இலைகள், மண் - ஒவ்வொரு பகுதியும் இந்த தாராள மரத்தின் பரிசு.",
ad:"🍎 தர்பூசணிகள் பொதுவாக பழத்தின் அடிப்பகுதி (தொப்பைடத்தின் பொக்கிஷம். ஒரு ரோஸி அல்லது மரகத ப்ளஷ் இந்த  இது எந்த உணவையும் உயர்த்துகிறது.",
cotd:"💐 பட்டு நூல்களின் பயிர். வெள்ளைப் பூக்கள் துணித் தொழிலின் அடித்தளத்தை உருவாக்கும் இழைகளை உருவாக்குகின்றன.",
od:"🍊 ஒரு கசப்பான வைட்டமின் ட்ரோவ். இந்த சூரியன்-முத்த கோலங்கள் ஆரோக்கியமான நன்மையுடன் ஆர்வத்தை திருமணம் செய்கின்றன.",
ld:"🥜 சிறிய புரதம் நிறைந்த முத்துக்கள். இந்த அடக்கமற்ற பருப்பு விதைகள் வீரியத்தையும் உயிர்ச்சக்தியையும் சேமிக்கின்றன.",
mad:"🥭 கோடைக்கால அரசமரமான வரவேற்பு. இந்த சதைப்பற்றுள்ள உருண்டையிலிருந்து ஜூசி நறுமணம் வீசுகிறது - பருவத்தின் முதல் மகிழ்ச்சி.",
mbd:"🥜 பச்சை ஓடுகளால் மூடப்பட்ட ஊட்டச்சத்து. இந்த பசுமையான பருப்பு வகைகள் உணவுகளை சுவையாகவும் வலுவூட்டுவதாகவும் இருக்கும்.",
mtbd:"🌰 உணவுக்கும் இயற்கைக்கும் நேரடி இணைப்பு. இந்த பழமையான விதைகள் வளமான வயல்களின் உழைப்பை பிரதிபலிக்கின்றன.",
gd:"🍇 திராட்சைத் தோட்டங்களின் ராணி. பச்சை முத்துக்களின் கொத்துகள் மெதுவாக பழுத்து, ஜூசி இனிப்புடன் வெடிக்கும் ரூபி ரத்தினங்களாக மாறும்.",
pd:"🍈 நடவு பொதுவாக வசந் பழங்களுக்குள் வீரியமிக்க விதைகளின் வெடிப்பு கூடுகட்டுகிறது.",
ppd:"🌰 ஒரு தாழ்மையான துடிப்பு பொக்கிஷம். இந்த சிறிய தங்கக் கட்டிகள் பண்டைய சமையல் மரபுகளை உயிருடன் வைத்திருக்கின்றன.",
pgd:"🍇 மாணிக்க இனிப்பின் வெடிப்பு. நூற்றுக்கணக்கான பளபளக்கும் அரில்கள் வைட்டமின் மற்றும் ஃபைபர் பஞ்சைக் கொண்டுள்ளன.",
wmd:"🍉 கோடையின் தாகம் தீர்க்கும் ரத்தினம். ஒரு பச்சை நிற தோலை உள்ளே ஒரு கண்கவர் இளஞ்சிவப்பு நீர்-பெட்டகத்தை மறைக்கிறது."
            }
        }
    },
})