import React from 'react'
import Team from '../components/crops_info/teamComp'
import WelcomeText from '../components/crops_info/welcomeText'
// import ContactForm from '../components//contactForm'


const Crop = () => {
  return (
    <div>
        <WelcomeText/>
        <Team/>
        {/* <ContactForm/> */}
    </div>
  )
}

export default Crop